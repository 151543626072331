import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  overflow-y: auto;
`;

export const NoContentWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
`;
