import { FadedContainer } from 'components/FadedContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDashboardQuery } from 'store/slices/apiSlice/endpoints/dashboard';
import SimpleInfoCard from './components/SimpleInfoCard';
import { Container } from './styles';

export function HomePage() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { data: dashboardData } = useDashboardQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    skip: pathname !== '/home',
  });

  return (
    <FadedContainer>
      <Container>
        <SimpleInfoCard
          title={t('dashboard.clients')}
          infoDesc={dashboardData?.Clients?.toString()}
        />
        <SimpleInfoCard
          title={t('dashboard.business')}
          infoDesc={dashboardData?.Business?.toString()}
        />
        <SimpleInfoCard
          title={t('dashboard.approvedTransactions')}
          infoDesc={dashboardData?.ApprovedTransactions?.toString()}
        />
        <SimpleInfoCard
          title={t('dashboard.rejectedTransactions')}
          infoDesc={dashboardData?.RejectedTransactions?.toString()}
        />
      </Container>
    </FadedContainer>
  );
}
