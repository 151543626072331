import { number, oneOfType, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { AvatarFallback, AvatarImage, AvatarRoot } from './styles';

export function Avatar({ size, imageUrl, fullName, nameInitials }) {
  const { userData } = useSelector(({ auth }) => auth);
  return (
    <AvatarRoot size={typeof size === 'number' ? `${size}px` : size}>
      <AvatarImage
        src={
          imageUrl?.startsWith('blob')
            ? imageUrl
            : `${imageUrl}?access_token=${userData?.Token}`
        }
        alt={fullName}
      />
      <AvatarFallback
        size={typeof size === 'number' ? `${size}px` : size}
        delayMs={600}
      >
        {nameInitials}
      </AvatarFallback>
    </AvatarRoot>
  );
}

Avatar.propTypes = {
  size: oneOfType([string, number]),
  imageUrl: string,
  fullName: string,
  nameInitials: string,
};

Avatar.defaultProps = {
  size: '45px',
  imageUrl: '',
  fullName: '',
  nameInitials: '',
};
