import { Root } from '@radix-ui/react-popover';
import useWindowDimension from 'hooks/useWindowDimension';
import { node } from 'prop-types';
import React, { forwardRef } from 'react';
import {
  PopoverArrow,
  PopoverContent as PopoverPrimitiveContent,
  PopoverTrigger as PopoverPrimitiveTrigger,
} from './styles';

export const Popover = Root;
export const PopoverTrigger = PopoverPrimitiveTrigger;

export const PopoverContent = forwardRef(({ children, ...props }, ref) => {
  const { dimensions } = useWindowDimension();
  return (
    <PopoverPrimitiveContent
      sideOffset={5}
      side={
        dimensions.width > 950 || dimensions.width < 500 ? 'bottom' : 'right'
      }
      ref={ref}
      {...props}
    >
      {children}
      <PopoverArrow />
    </PopoverPrimitiveContent>
  );
});

PopoverContent.propTypes = {
  children: node.isRequired,
};
