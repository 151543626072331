import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './rootReducer';

const persistConfig = {
  key: 'dindim-admin',
  version: 1,
  storage,
  whitelist: ['auth', 'recharge', 'appOptions'],
  blacklist: ['api'],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
