import { motion } from 'framer-motion';
import { node, string } from 'prop-types';
import React, { forwardRef } from 'react';

export const Button = forwardRef(({ children, type, ...props }, ref) => (
  <motion.button
    type={type}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    ref={ref}
    {...props}
  >
    {children}
  </motion.button>
));

Button.propTypes = {
  children: node,
  type: string,
};

Button.defaultProps = {
  children: null,
  type: 'button',
};
