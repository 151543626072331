export const phoneNumberType = (currentPhoneTypeId) =>
  [
    {
      id: 1,
      name: 'Angolan',
      placeholder: '123 456 789',
      regex: /^[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}/gm,
    },
    {
      id: 2,
      name: 'Portugal',
      placeholder: '123 456 789',
      regex: /^[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}/gm,
    },
    {
      id: 3,
      name: 'Brazil',
      placeholder: '(99) 91234-5678',
      regex: /^\([1-9]{2}\)\s?(?:[0-9]|9[0-9])[0-9]{3}-?[0-9]{4}$/gm,
      // regex: /^(?:\+)[0-9]{1,3}\s?[0-9]{4,15}$/gm,
    },
  ].find((type) => type.id === currentPhoneTypeId);
