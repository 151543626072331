import { bool, func, node, number, oneOfType, string } from 'prop-types';
import React, { forwardRef } from 'react';
import { CloseButton, Header, Overlay, Popup, Title } from './styles';

export const Dialog = forwardRef(
  (
    {
      children,
      onClose,
      showCloseButton,
      closeOnOutsideClick,
      title,
      width,
      height,
      ...props
    },
    ref,
  ) => (
    <>
      <Overlay onClick={closeOnOutsideClick ? onClose : () => {}} />
      <Popup
        ref={ref}
        $width={typeof width === 'number' ? `${width}px` : width}
        $height={typeof height === 'number' ? `${height}px` : height}
        {...props}
      >
        {(title || showCloseButton) && (
          <Header>
            {title && (
              <Title>
                <span>{title}</span>
              </Title>
            )}
            {showCloseButton && (
              <CloseButton type="button" onClick={onClose}>
                <i className="fas fa-xmark" />
              </CloseButton>
            )}
          </Header>
        )}
        {children}
      </Popup>
    </>
  ),
);

Dialog.propTypes = {
  children: node.isRequired,
  onClose: func,
  showCloseButton: bool,
  closeOnOutsideClick: bool,
  title: string,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
};

Dialog.defaultProps = {
  onClose: () => {},
  showCloseButton: false,
  closeOnOutsideClick: false,
  title: '',
  width: 'content-fit',
  height: 'content-fit',
};
