import * as TabsPrimitive from '@radix-ui/react-tabs';
import { Button } from 'components/Button';
import { Input as MotionInput } from 'components/Input';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;

  @media (max-width: 800px), (max-height: 540px) {
    gap: 15px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-self: flex-start;
  padding: 10px 5px;
  text-align: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${({ theme }) => theme.colors.text};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 95%;
  height: 100%;
  background: inherit;

  @media (max-width: 800px), (max-height: 540px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    width: 95%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px;
  }
`;

export const Input = styled(MotionInput)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 8px;
  border: ${({ $readOnly, error, theme }) =>
    `2px ${$readOnly ? 'dotted' : 'solid'} ${
      error ? theme.colors.error : theme.colors.text
    }`};
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'all')};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  gap: 30px;

  @media (max-width: 800px), (max-height: 540px) {
    width: 95%;
  }
`;

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
`;

export const Tabs = styled(TabsPrimitive.Root)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TabsList = styled(TabsPrimitive.List)`
  flex-shrink: 0;
  display: flex;
`;

export const TabTrigger = styled(TabsPrimitive.Trigger)`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.text};
  user-select: none;
  border: none;

  &:first-child {
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.disabled};
  }
  &[data-state='active'] {
    color: ${({ theme }) => theme.colors.accent};
    border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
  }
`;

export const TabContent = styled(TabsPrimitive.Content)`
  flex-grow: 1;
  padding: 5;
  background-color: ${({ theme }) => theme.colors.background};
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  outline: none;
`;
