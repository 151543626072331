import appConfig from 'appConfig';
import { AppContainer } from 'components/AppContainer';
import TestIndicatorBar from 'components/TesteIndicatorBar';
import { AnimatePresence } from 'framer-motion';
import { AuditPage } from 'pages/AuditPage';
import { AuditProvider } from 'pages/AuditPage/contexts/AuditContext';
import { BackOfficeUsersPage } from 'pages/BackOfficeUsersPage';
import { BackOfficeUserProvider } from 'pages/BackOfficeUsersPage/context/BackOfficeUserContext';
import { BusinessPage } from 'pages/BusinessPage';
import { BusinessProvider } from 'pages/BusinessPage/contexts/BusinessContext';
import { ClientsPage } from 'pages/ClientsPage';
import { ClientProvider } from 'pages/ClientsPage/context/ClientContext';
import { HomePage } from 'pages/HomePage';
import { LoginPage } from 'pages/LoginPage';
import { PermissionsPage } from 'pages/PermissionsPage';
import { PermissionsPageProvider } from 'pages/PermissionsPage/contexts/PermissionsPageContext';
import { RechargePage } from 'pages/RechargePage';
import { RechargeProvider } from 'pages/RechargePage/context/RechargeContext';
import { RecoveryPage } from 'pages/RecoveryPage';
import { TransactionsPage } from 'pages/TransactionsPage';
import { TransactionProvider } from 'pages/TransactionsPage/contexts/TransactionContext';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes as BrowserRoutes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from 'theme';
import GlobalStyle from 'theme/global';
import { PrivateRoute } from './PrivateRoute';

export const Routes = () => {
  const location = useLocation();
  const { theme } = useSelector(({ appOptions }) => appOptions);
  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <GlobalStyle />
      {appConfig.isDev && <TestIndicatorBar />}
      <AppContainer>
        <AnimatePresence exitBeforeEnter initial={false}>
          <BrowserRoutes location={location} key={location.key}>
            <Route path="/*" element={<LoginPage />} />
            <Route path="recuperacao/*" element={<RecoveryPage />} />
            <Route
              path="utilizadores/*"
              element={
                <BackOfficeUserProvider>
                  <PrivateRoute>
                    <BackOfficeUsersPage />
                  </PrivateRoute>
                </BackOfficeUserProvider>
              }
            />
            <Route
              path="clientes/*"
              element={
                <ClientProvider>
                  <PrivateRoute>
                    <ClientsPage />
                  </PrivateRoute>
                </ClientProvider>
              }
            />
            <Route
              path="home/*"
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              path="recargas/*"
              element={
                <RechargeProvider>
                  <PrivateRoute>
                    <RechargePage />
                  </PrivateRoute>
                </RechargeProvider>
              }
            />
            <Route
              path="empresas/*"
              element={
                <BusinessProvider>
                  <PrivateRoute>
                    <BusinessPage />
                  </PrivateRoute>
                </BusinessProvider>
              }
            />
            <Route
              path="transacoes/*"
              element={
                <TransactionProvider>
                  <PrivateRoute>
                    <TransactionsPage />
                  </PrivateRoute>
                </TransactionProvider>
              }
            />
            <Route
              path="auditoria/*"
              element={
                <AuditProvider>
                  <PrivateRoute>
                    <AuditPage />
                  </PrivateRoute>
                </AuditProvider>
              }
            />
            <Route
              path="permissoes/*"
              element={
                <PermissionsPageProvider>
                  <PrivateRoute>
                    <PermissionsPage />
                  </PrivateRoute>
                </PermissionsPageProvider>
              }
            />
          </BrowserRoutes>
        </AnimatePresence>
      </AppContainer>
    </ThemeProvider>
  );
};
