import { node } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  useAccountStatusListQuery,
  useGetAccountTypeListQuery,
} from 'store/slices/apiSlice/endpoints/account';
import {
  useGetAuditActionListQuery,
  useGetAuditTopicListQuery,
} from 'store/slices/apiSlice/endpoints/audit';
import {
  useTransactionStatusListQuery,
  useTransactionTypeListQuery,
} from 'store/slices/apiSlice/endpoints/transaction';
import { useGetUserTypeListQuery } from 'store/slices/apiSlice/endpoints/user';

const ConstantsHandle = ({ children }) => {
  const { userIsSigned } = useSelector(({ auth }) => auth);
  useTransactionStatusListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnReconnect: false,
    skip: !userIsSigned,
  });
  useTransactionTypeListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnReconnect: false,
    skip: !userIsSigned,
  });
  useAccountStatusListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    skip: !userIsSigned,
  });
  useGetAuditActionListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    skip: !userIsSigned,
  });
  useGetAuditTopicListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    skip: !userIsSigned,
  });
  useGetUserTypeListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    skip: !userIsSigned,
  });
  useGetAccountTypeListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    skip: !userIsSigned,
  });
  return children;
};

ConstantsHandle.propType = {
  children: node.isRequired,
};

export default ConstantsHandle;
