import { XMLParser } from 'fast-xml-parser';
import { apiSlice } from 'store/slices/apiSlice';

const parser = new XMLParser({
  ignoreAttributes: false,
  attributeNamePrefix: '',
});

const parseXmlToJson = (xmlString) => {
  if (!xmlString) {
    return xmlString;
  }
  const jsonObj = parser.parse(xmlString);

  if (jsonObj) {
    return jsonObj.row;
  }

  return null;
};

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAuditList: builder.query({
      query: ({ id, topicDesc }) => ({
        url: `/sysAudit/List/${topicDesc}/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      transformResponse: (response) =>
        response.map((audit) => ({
          ...audit,
          JSONBefore: parseXmlToJson(audit.XMLBefore),
          JSONAfter: parseXmlToJson(audit.XMLAfter),
        })),
      providesTags: (_result, _error, { topicDesc }) => [
        { type: 'Audit', id: topicDesc },
      ],
    }),
    getAuditListByTopic: builder.query({
      query: (topicDesc) => ({
        url: `/sysAudit/ListByTopic/${topicDesc}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      transformResponse: (response) =>
        response.map((audit) => ({
          ...audit,
          JSONBefore: parseXmlToJson(audit.XMLBefore),
          JSONAfter: parseXmlToJson(audit.XMLAfter),
        })),
      providesTags: (_result, _error, topicDesc) => [
        { type: 'Audit', id: topicDesc },
      ],
    }),
    getAuditListByRecordId: builder.query({
      query: (recordId) => ({
        url: `/sysAudit/ListById/${recordId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      transformResponse: (response) =>
        response.map((audit) => ({
          ...audit,
          JSONBefore: parseXmlToJson(audit.XMLBefore),
          JSONAfter: parseXmlToJson(audit.XMLAfter),
        })),
      providesTags: (_result, _error, recordId) => [
        { type: 'Audit', id: recordId },
      ],
    }),
    getAuditTopicList: builder.query({
      query: () => ({
        url: '/sysTopic',
        method: 'GET',
      }),
      keepUnusedDataFor: 1800,
      transformResponse: (topicList) =>
        [
          {
            TopicId: 0,
            TopicDesc: 'Any',
          },
          ...topicList,
        ].filter(
          (item) =>
            !item?.TopicDesc?.toLowerCase().includes('chat') &&
            !item?.TopicDesc?.toLowerCase().includes('favorite') &&
            !item?.TopicDesc?.toLowerCase().includes('notification'),
        ),
    }),
    getAuditActionList: builder.query({
      query: () => ({
        url: '/sysAction',
        method: 'GET',
      }),
      keepUnusedDataFor: 1800,
      transformResponse: (actionList) => [
        {
          ActionId: 0,
          ActionDesc: 'Any',
        },
        ...actionList,
      ],
    }),
    getAuditListFiltered: builder.query({
      query: ({ TopicId, ActionId, StartDate, EndDate }) => ({
        url: `/sysAudit/List/${TopicId}/${ActionId}/${StartDate}/${EndDate}`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        response.map((audit) => ({
          ...audit,
          JSONBefore: parseXmlToJson(audit.XMLBefore),
          JSONAfter: parseXmlToJson(audit.XMLAfter),
        })),
      providesTags: [{ type: 'Audit' }],
    }),
  }),
});

export const {
  useGetAuditListByTopicQuery,
  useGetAuditListQuery,
  useGetAuditListByRecordIdQuery,
  useGetAuditTopicListQuery,
  useGetAuditActionListQuery,
  useGetAuditListFilteredQuery,
} = extendApi;
