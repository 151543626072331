import { Tooltip } from 'components/Tooltip';
import { motion } from 'framer-motion';
import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseInput,
  Container,
  ErrorInfo,
  FieldContainer,
  PasswordToggle,
} from './styles';

export const Input = forwardRef(
  (
    {
      name,
      label,
      error,
      isActive,
      isPasswordField,
      type,
      onBlur,
      onChange,
      width,
      // mask,
      ...props
    },
    ref,
  ) => {
    const [isCurrentActive, setIsCurrentActive] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      setIsCurrentActive(isActive);
    }, [isActive]);

    const labelVariants = {
      asPlaceholder: {
        x: 10,
        y: 28,
        fontSize: '0.9rem',
      },
      asNormalLabel: {
        x: 10,
        y: -3,
        fontSize: '0.75rem',
        marginBottom: '0.15rem',
      },
    };

    const handleAnimate = () =>
      isCurrentActive ? 'asNormalLabel' : 'asPlaceholder';

    const handleOnBlur = (e) => {
      onBlur(e);
      setIsCurrentActive(!!e.target.value);
    };

    const handleOnChange = (e) => {
      onChange(e);
      setIsCurrentActive(!!e.target.value);
    };

    return (
      <Container $width={width}>
        <FieldContainer error={error}>
          {label && (
            <motion.label
              htmlFor={name}
              variants={labelVariants}
              animate={handleAnimate}
              initial={false}
            >
              {label}
            </motion.label>
          )}
          <BaseInput
            autoComplete={isPasswordField ? 'off' : 'on'}
            ref={ref}
            id={name}
            name={name}
            onFocus={() => setIsCurrentActive(true)}
            type={isPasswordField ? (showPassword ? 'text' : 'password') : type}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            // onKeyUp={handleKeyUp}
            {...props}
          />
          {!!error && (
            <Tooltip content={error.message}>
              <ErrorInfo type="button" tabIndex="-1">
                <i className="fas fa-times-circle" />
              </ErrorInfo>
            </Tooltip>
          )}
          {isPasswordField && (
            <Tooltip
              content={
                showPassword
                  ? t('loginScreen.hidePassword')
                  : t('loginScreen.showPassword')
              }
            >
              <PasswordToggle
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                tabIndex="-1"
              >
                <i
                  className={showPassword ? 'far fa-eye' : 'far fa-eye-slash'}
                />
              </PasswordToggle>
            </Tooltip>
          )}
        </FieldContainer>
      </Container>
    );
  },
);

Input.propTypes = {
  name: string,
  type: string,
  label: string,
  error: shape({ message: string }),
  isPasswordField: bool,
  isActive: bool,
  onChange: func.isRequired,
  onBlur: func,
  width: oneOfType([string, number]),
  // mask: oneOf(['currency', 'simpleCurrency']),
};

Input.defaultProps = {
  name: '',
  label: '',
  error: null,
  type: 'text',
  isPasswordField: false,
  onBlur: () => {},
  isActive: false,
  width: '100%',
  // mask: '',
};
