/* eslint-disable import/no-cycle */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithRefreshToken } from './baseQueryWithRefreshToken';

export const apiSlice = createApi({
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: [
    'Transaction',
    'Business',
    'Account',
    'Audit',
    'BackOfficeUser',
    'Group',
    'Menu',
  ],
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (credentials) => ({
        url: '/sysUser/BackOfficeAuthenticate',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const {
  middleware: apiMiddleware,
  reducer: apiReducer,
  reducerPath: apiReducerPath,
} = apiSlice;
