import { bool, oneOfType, string } from 'prop-types';
import React from 'react';
import { Container, Indicator } from './styles';

function SelectBoxCellRender({ value, valueFormatted }) {
  return (
    <Container>
      <Indicator
        $isAllow={value}
        className={value ? 'fas fa-check' : 'fas fa-xmark'}
      />
      {valueFormatted}
    </Container>
  );
}

SelectBoxCellRender.propTypes = {
  value: oneOfType([string, bool]),
  valueFormatted: string.isRequired,
};

SelectBoxCellRender.defaultProps = {
  value: undefined,
};

export default SelectBoxCellRender;
