/* eslint-disable import/no-cycle */
import { authLogout, authUpdateToken } from '../authSlice';
import { baseQuery } from './baseQuery';

export const baseQueryWithRefreshToken = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const refreshResult = await baseQuery(
      '/sysUser/RefreshAuthentication',
      api,
      extraOptions,
    );
    if (refreshResult.data) {
      api.dispatch(authUpdateToken(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(authLogout());
    }
  }
  return result;
};
