import { selectIcon } from 'assets';
import AuditButton from 'components/AuditButton';
import AuditLogPopup from 'components/AuditLogPopup';
import { FadedContainer } from 'components/FadedContainer';
import { ProfileProvider } from 'components/ProfileButton/context/ProfileContext';
import { ProfileForm } from 'components/ProfileForm';
import { SearchInput } from 'components/SearchInput';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetAuditListByTopicQuery,
  useGetAuditListQuery,
} from 'store/slices/apiSlice/endpoints/audit';
import { useBackOfficeUserListQuery } from 'store/slices/apiSlice/endpoints/backOfficeUser';
import BackOfficeUsersList from './components/BackOfficeUsersList';
import { useBackOfficeUser } from './context/BackOfficeUserContext';
import {
  AddBackOfficeUserButton,
  Container,
  Header,
  WrapperContent,
  WrapperList,
} from './styles';

export function BackOfficeUsersPage() {
  const ACL = usePermissions();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [showAuditData, setShowAuditData] = useState(false);
  const {
    currentBackOfficeUserData,
    setCurrentBackOfficeUserData,
    filteredUsers,
    setFilteredUsers,
  } = useBackOfficeUser();
  const { data: auditDataByUserId, isSuccess: isSuccessByUserId } =
    useGetAuditListQuery(
      {
        id: currentBackOfficeUserData?.backOfficeUserData?.UserId,
        topicDesc: 'BackOfficeUser',
      },
      {
        skip: !currentBackOfficeUserData?.backOfficeUserIsSelected,
        refetchOnMountOrArgChange: true,
      },
    );
  const { data: auditDataByTopic, isSuccess: isSuccessByTopic } =
    useGetAuditListByTopicQuery('BackOfficeUser');
  const { dimensions } = useWindowDimension();
  const { data: usersList } = useBackOfficeUserListQuery();
  const { userData } = useSelector(({ auth }) => auth);

  useEffect(() => {
    setIsFilterActive(!!filteredUsers.length);
  }, [filteredUsers]);

  const handleSetEmptyUser = () => {
    setCurrentBackOfficeUserData({
      backOfficeUserIsSelected: true,
      backOfficeUserData: {
        ImageUrl: '',
        FullName: '',
        Email: '',
        AuthorizePIN: '',
        Password: '',
      },
    });
  };

  return (
    <FadedContainer>
      {(isSuccessByUserId || isSuccessByTopic) &&
        showAuditData &&
        ((currentBackOfficeUserData?.backOfficeUserIsSelected &&
          auditDataByUserId?.length) ||
          auditDataByTopic?.length) && (
          <AuditLogPopup
            data={
              currentBackOfficeUserData?.backOfficeUserIsSelected
                ? auditDataByUserId
                : auditDataByTopic
            }
            onClose={() => setShowAuditData(false)}
            title={
              currentBackOfficeUserData?.backOfficeUserIsSelected
                ? `Logs de alteração - Registro: Utilizador BackOffice [${currentBackOfficeUserData?.backOfficeUserData?.FullName}]`
                : 'Logs de alteração - Tabela: Utilizadores BackOffice'
            }
          />
        )}
      <AuditButton onClick={() => setShowAuditData(true)} />
      <Container>
        <WrapperList
          $isMobile={dimensions.width < 1000}
          $isExpanded={!currentBackOfficeUserData?.backOfficeUserIsSelected}
        >
          <Header>
            <SearchInput
              searchFn={({ searchTerm }) => {
                setFilteredUsers(
                  !searchTerm
                    ? []
                    : usersList.filter(
                        (user) =>
                          user?.FullName?.toLowerCase()?.includes(
                            searchTerm?.toLowerCase(),
                          ) ||
                          user?.Email?.toLowerCase()?.includes(
                            searchTerm?.toLowerCase(),
                          ),
                      ),
                );
              }}
              clearFn={() => setFilteredUsers([])}
              filterActive={isFilterActive}
              disabled={
                currentBackOfficeUserData?.backOfficeUserIsSelected &&
                !currentBackOfficeUserData?.backOfficeUserData?.UserId
              }
            />
            {ACL.Add && (
              <AddBackOfficeUserButton
                onClick={handleSetEmptyUser}
                disabled={
                  currentBackOfficeUserData?.backOfficeUserIsSelected &&
                  !currentBackOfficeUserData?.backOfficeUserData?.UserId
                }
              >
                <i className="fa-solid fa-plus" />
              </AddBackOfficeUserButton>
            )}
          </Header>
          <BackOfficeUsersList
            disabled={
              currentBackOfficeUserData?.backOfficeUserIsSelected &&
              !currentBackOfficeUserData?.backOfficeUserData?.UserId
            }
          />
        </WrapperList>
        <WrapperContent
          $isMobile={dimensions.width < 1000}
          $isExpanded={currentBackOfficeUserData?.backOfficeUserIsSelected}
        >
          {currentBackOfficeUserData?.backOfficeUserIsSelected ? (
            <ProfileProvider>
              <ProfileForm
                onClose={() =>
                  setCurrentBackOfficeUserData({
                    backOfficeUserIsSelected: false,
                    backOfficeUserData: {
                      UserId: '',
                      ImageUrl: '',
                      FullName: '',
                      Initials: '',
                      Email: '',
                    },
                  })
                }
                currentUser={{
                  ...currentBackOfficeUserData?.backOfficeUserData,
                  AuthorizePIN: '',
                  Password: '',
                }}
                readOnly={
                  currentBackOfficeUserData?.backOfficeUserData?.UserId &&
                  userData?.UserId !==
                    currentBackOfficeUserData?.backOfficeUserData?.UserId
                }
              />
            </ProfileProvider>
          ) : (
            <img src={selectIcon} alt="Selecione um utilizador" />
          )}
        </WrapperContent>
      </Container>
    </FadedContainer>
  );
}
