/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
// import { apiSlice } from './apiSlice';

const rechargeSlice = createSlice({
  name: '@recharge',
  initialState: {
    rechargeList: {},
    currentDate: null,
  },
  reducers: {
    rechargeInsertOnList: (draft, { payload }) => {
      if (draft.rechargeList[payload.loggedUserId]) {
        draft.rechargeList[payload.loggedUserId].unshift(payload.rechargeData);
        return;
      }
      draft.rechargeList = {
        ...draft.rechargeList,
        [payload.loggedUserId]: [payload.rechargeData],
      };
    },
    rechargeResetList: (draft) => {
      draft.rechargeList = {};
      draft.currentDate = format(new Date(), 'yyyy-MM-dd');
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addMatcher(
  //       apiSlice.endpoints.userLogin.matchFulfilled,
  //       (draft, { payload }) => {
  //         draft.userData = payload;
  //         draft.userIsSigned = true;
  //       },
  //     )
  //     .addMatcher(apiSlice.endpoints.userLogin.matchRejected, (draft) => {
  //       draft.userIsSigned = false;
  //     });
  // },
});

export const { rechargeInsertOnList, rechargeResetList } =
  rechargeSlice.actions;
export const rechargeReducer = rechargeSlice.reducer;
