import styled from 'styled-components';

export const DrawerRoot = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-start;
  gap: 5px;

  width: 200px;
  height: 100vh;

  padding: 10px 0;

  background-color: ${({ theme, $isDev }) =>
    $isDev ? theme.colors.devDrawerBkg : theme.colors.boxBackground};

  transition: 0.3s;

  @media (max-width: 800px) {
    width: 50px;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  width: 65%;
  height: 70px;
  align-items: center;
  justify-content: center;
  align-self: center;

  @media (max-width: 800px) {
    align-items: flex-start;
    height: 40px;
    margin: 4px 0 10px 0;
  }
`;

export const Title = styled.span`
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  align-self: right;
`;

export const LogoImage = styled.img`
  width: 80%;
  align-self: center;
`;
