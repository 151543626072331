import styled from 'styled-components';

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 5px;
  border-top: 1px solid ${({ theme }) => theme.colors.lineColor};
  padding: 10px 0;
`;

export const Title = styled.span`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
`;
