import { Arrow, Close, Content, Trigger } from '@radix-ui/react-popover';
import styled from 'styled-components';

export const PopoverContent = styled(Content)`
  min-width: 150px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 5px;
  padding: 10px;
`;

export const PopoverTrigger = styled(Trigger)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 10px;
  background-color: inherit;
  color: ${({ theme }) => theme.colors.text};
  border: ${({ theme, $readOnly }) =>
    `2px ${$readOnly ? 'dotted' : 'solid'} ${theme.colors.text}`};
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'all')};
  border-radius: 50px;
  width: 200px;
  font-size: 0.8rem;
  transition: background-color 300ms;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const PopoverArrow = styled(Arrow)`
  fill: ${({ theme }) => theme.colors.background};
`;

export const PopoverClose = styled(Close)`
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25;
  width: 25;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.boxBackground};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.buttonText};
  }
`;
