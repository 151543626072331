import { Button } from 'components/Button';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
`;

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;
