import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const RechargeContext = createContext();

function RechargeProvider({ children }) {
  const [currentRechargeData, setCurrentRechargeData] = useState({
    recipientUser: {
      FirstName: '',
      LastName: '',
      Mobile: '',
    },
    rechargeValue: 0.0,
    recipientUserIsSelected: false,
    rechargeIsValid: false,
  });

  const rechargeContextValues = useMemo(
    () => ({ currentRechargeData, setCurrentRechargeData }),
    [currentRechargeData],
  );

  return (
    <RechargeContext.Provider value={rechargeContextValues}>
      {children}
    </RechargeContext.Provider>
  );
}

RechargeProvider.propTypes = {
  children: node.isRequired,
};

const useRecharge = () => useContext(RechargeContext);

export { RechargeProvider, useRecharge };
