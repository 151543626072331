import AuditResult from 'components/AuditResult';
import { Dialog } from 'components/Dialog';
import useWindowDimension from 'hooks/useWindowDimension';
import { array, func, string } from 'prop-types';
import React from 'react';

function AuditLogPopup({ data, onClose, title }) {
  const { dimensions } = useWindowDimension();
  return (
    <Dialog
      width={dimensions.width > 950 ? '80%' : '100%'}
      height={dimensions.width > 950 ? '80%' : '100%'}
      title={title}
      onClose={onClose}
      closeOnOutsideClick
      showCloseButton
    >
      <AuditResult data={data} />
    </Dialog>
  );
}

AuditLogPopup.propTypes = {
  data: array.isRequired,
  onClose: func.isRequired,
  title: string,
};

AuditLogPopup.defaultProps = {
  title: 'Logs de auditoria',
};

export default AuditLogPopup;
