import { format } from 'date-fns';
import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const TransactionContext = createContext();

function TransactionProvider({ children }) {
  const [transactionFilter, setTransactionFilter] = useState({
    TransactionTypeId: 0,
    TransactionStatusId: 0,
    StartDate: format(new Date(), 'yyyy-MM-dd'),
    EndDate: format(new Date(), 'yyyy-MM-dd'),
    Mobile: '',
  });
  const [gridRegisterAuditParams, setGridRegisterAuditParams] = useState(null);

  const [clientTransactionData, setClientTransactionData] = useState({
    client: {
      PersonId: '',
      FirstName: '',
      LastName: '',
      Initials: '',
      Mobile: '',
    },
    transactions: [],
  });

  const transactionContextValues = useMemo(
    () => ({
      transactionFilter,
      setTransactionFilter,
      clientTransactionData,
      setClientTransactionData,
      gridRegisterAuditParams,
      setGridRegisterAuditParams,
    }),
    [transactionFilter, clientTransactionData, gridRegisterAuditParams],
  );

  return (
    <TransactionContext.Provider value={transactionContextValues}>
      {children}
    </TransactionContext.Provider>
  );
}

TransactionProvider.propTypes = {
  children: node.isRequired,
};

const useTransaction = () => useContext(TransactionContext);

export { TransactionProvider, useTransaction };
