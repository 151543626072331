import { bool, func, string } from 'prop-types';
import React, { forwardRef } from 'react';
import { CheckBoxBase, Container, Indicator, Label } from './styles';

export const CheckBox = forwardRef(
  ({ label, value, onChange, readOnly, ...props }, ref) => (
    <Container>
      <CheckBoxBase
        ref={ref}
        checked={value}
        onCheckedChange={onChange}
        $readOnly={readOnly}
        {...props}
      >
        <Indicator>
          <i className="fas fa-check" />
        </Indicator>
      </CheckBoxBase>
      {label && <Label>{label}</Label>}
    </Container>
  ),
);

CheckBox.propTypes = {
  label: string,
  value: bool,
  onChange: func,
  readOnly: bool,
};

CheckBox.defaultProps = {
  label: '',
  value: false,
  onChange: () => {},
  readOnly: false,
};
