import { yupResolver } from '@hookform/resolvers/yup';
import { AvatarEditor } from 'components/AvatarEditor';
import { BackButton } from 'components/BackButton';
import { FadedContainer } from 'components/FadedContainer';
import { LoadIndicator } from 'components/LoadIndicator';
import { useProfile } from 'components/ProfileButton/context/ProfileContext';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  useBackOfficeImageDeleteMutation,
  useBackOfficeImageSaveMutation,
  useBackOfficeUserSaveMutation,
  useUpdateReportNotificationMutation,
} from 'store/slices/apiSlice/endpoints/backOfficeUser';
import { authUserLastAvatarUpdate } from 'store/slices/authSlice';
import * as yup from 'yup';
import ChangePopup from './components/ChangePopup';
import {
  Body,
  ButtonsContainer,
  CenterButtonsContainer,
  CheckBox,
  Container,
  FormButton,
  Header,
  Input,
} from './styles';

export function ProfileForm({ currentUser, onClose }) {
  const ACL = usePermissions();
  const { userData } = useSelector(({ auth }) => auth);
  const readOnly =
    (!ACL.Update && currentUser.UserId !== userData.UserId) ||
    (!currentUser.UserId && !ACL.Add);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const [changeType, setChangeType] = useState('');
  const [backOfficeUserSave] = useBackOfficeUserSaveMutation();
  const [backOfficeImageSave] = useBackOfficeImageSaveMutation();
  const [backOfficeImageDelete] = useBackOfficeImageDeleteMutation();
  const [updateReportNotification] = useUpdateReportNotificationMutation();
  const {
    imageHasChanged,
    setImageHasChanged,
    avatarPreview,
    setAvatarPreview,
  } = useProfile();
  const splittedName = currentUser?.FullName.split(' ');
  const initials = `${splittedName[0]?.charAt(0)}${splittedName[1]?.charAt(0)}`;

  const profileSchema = useMemo(
    () =>
      yup.object({
        FullName: yup.string().required(t('profileForm.fullNameRequired')),
        ReportNotification: yup.bool(),
        Email: yup
          .string()
          .email(t('loginScreen.emailInvalid'))
          .required(t('loginScreen.emailRequired')),
        Password: !currentUser?.UserId
          ? yup
              .string()
              .min(6, t('recoveryPage.passwordMinSize'))
              .required(t('loginScreen.passwordRequired'))
          : undefined,
        PasswordConfirmation: !currentUser?.UserId
          ? yup
              .string()
              .default('')
              .oneOf(
                [yup.ref('Password'), null],
                t('recoveryPage.passwordsMustMatch'),
              )
          : undefined,
        AuthorizePIN: currentUser.UserId
          ? yup.string().default('')
          : yup
              .string()
              .min(4, t('subAccountsForm.pinSizeMustBeFour'))
              .max(4, t('subAccountsForm.pinSizeMustBeFour'))
              .required(t('subAccountsForm.pinRequired')),
        PINConfirmation: yup
          .string()
          .default('')
          .oneOf(
            [yup.ref('AuthorizePIN'), null],
            t('subAccountsForm.pinMustMatch'),
          ),
      }),
    [readOnly, currentUser],
  );

  const { control, setValue, handleSubmit, formState } = useForm({
    mode: 'all',
    resolver: readOnly ? undefined : yupResolver(profileSchema),
  });
  const { errors, isSubmitting } = formState;

  const loadFields = (data) => {
    Object.keys(data).forEach((keyName) => {
      setValue(keyName, data[keyName]);
    });
  };

  useEffect(() => {
    if (currentUser) {
      loadFields(currentUser);
    }
  }, []);

  const getControlledComponent = (
    name,
    label,
    error,
    isActive = false,
    isReadOnly = false,
    isPasswordField = false,
  ) => (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { ...props } }) => (
        <Input
          label={label}
          $readOnly={isReadOnly}
          isPasswordField={isPasswordField}
          disabled={readOnly}
          isActive={isActive}
          error={error}
          {...props}
        />
      )}
    />
  );

  const getControlledCheckBox = (
    name,
    label,
    size = '100%',
    readOnly = false,
  ) => (
    <Controller
      control={control}
      name={name}
      defaultChecked={false}
      render={({ field: { ...fieldProps } }) => (
        <CheckBox
          id={name}
          label={label}
          $readOnly={readOnly}
          disabled={readOnly}
          $width={size}
          {...fieldProps}
        />
      )}
    />
  );

  const submitData = async (userDataToSave) => {
    if (imageHasChanged) {
      try {
        if (!avatarPreview.file) {
          await backOfficeImageDelete(currentUser.UserId).unwrap();
          dispatch(authUserLastAvatarUpdate());
        } else {
          const imageData = new FormData();
          imageData.append('UserId', currentUser.UserId);
          imageData.append('File', avatarPreview.file);
          await backOfficeImageSave(imageData).unwrap();
          dispatch(authUserLastAvatarUpdate());
        }
        setAvatarPreview({ file: null, url: '' });
        setImageHasChanged(false);
        window.location.reload();
      } catch {
        toast.error(t('profileForm.errorSavingImageChanges'));
      }
    }

    try {
      if (
        !userDataToSave.Password &&
        !userDataToSave.AuthorizePIN &&
        currentUser?.FullName === userDataToSave?.FullName &&
        currentUser?.ReportNotification === userDataToSave?.ReportNotification
      ) {
        onClose();
        return;
      }
      if (!currentUser?.UserId) {
        const res = await backOfficeUserSave({
          ...userDataToSave,
          Password: userDataToSave.Password || undefined,
          AuthorizePIN: userDataToSave.AuthorizePIN || undefined,
        }).unwrap();

        if (res?.ErrorMsg) {
          toast.error(res.ErrorMsg);
          loadFields(userDataToSave);
          return;
        }
      } else {
        const res = await updateReportNotification({
          UserId: currentUser?.UserId,
          ReportNotification: userDataToSave?.ReportNotification,
        }).unwrap();
        if (res?.ErrorMsg) {
          toast.error(res.ErrorMsg);
          loadFields(userDataToSave);
          return;
        }
      }
      toast.success(t('profileForm.successSavingProfileData'));
    } catch {
      toast.error(t('profileForm.errorSavingProfileData'));
      return;
    }
    onClose();
  };

  return (
    <FadedContainer>
      {changeType && (
        <ChangePopup
          title={
            changeType === 'pin'
              ? t('generalWords.changePIN')
              : t('generalWords.changePassword')
          }
          changeType={changeType}
          onClose={() => setChangeType('')}
        />
      )}
      <Container onSubmit={handleSubmit(submitData)}>
        <LoadIndicator visible={isSubmitting} />
        {readOnly && (
          <Header>
            <BackButton type="button" onClick={onClose} />
          </Header>
        )}
        {!currentUser?.UserId && <Header>{t('profileForm.newUser')}</Header>}
        {currentUser?.UserId && (
          <AvatarEditor
            imageSize={dimensions.width < 800 ? 50 : 90}
            readOnly={readOnly}
            imageUrl={currentUser?.ImageUrl}
            nameInitials={`${currentUser?.Initials || initials}`}
            fullName={`${currentUser?.FullName}`}
          />
        )}
        {!readOnly && currentUser?.UserId && (
          <CenterButtonsContainer>
            <FormButton type="button" onClick={() => setChangeType('password')}>
              <i className="fas fa-key" />
              {t('generalWords.changePassword')}
            </FormButton>
            <FormButton type="button" onClick={() => setChangeType('pin')}>
              <i className="fas fa-unlock-keyhole" />
              {t('generalWords.changePIN')}
            </FormButton>
          </CenterButtonsContainer>
        )}
        <Body>
          {getControlledComponent(
            'FullName',
            t('profileForm.fullName'),
            errors?.FullName,
            !!currentUser?.FullName,
            !!currentUser?.UserId || readOnly,
          )}
          {getControlledComponent(
            'Email',
            t('generalWords.email'),
            errors?.Email,
            !!currentUser?.Email,
            !!currentUser?.UserId || readOnly,
          )}
          {getControlledCheckBox(
            'ReportNotification',
            t('profileForm.reportNotification'),
            errors?.ReportNotification,
            readOnly,
          )}
          <div />
          {!currentUser?.UserId &&
            !readOnly &&
            getControlledComponent(
              'Password',
              t('generalWords.password'),
              errors?.Password,
              false,
              readOnly,
              true,
            )}
          {!currentUser?.UserId &&
            !readOnly &&
            getControlledComponent(
              'PasswordConfirmation',
              t('profileForm.confirmPassword'),
              errors.PasswordConfirmation,
              false,
              readOnly,
              true,
            )}
          {!currentUser?.UserId &&
            !readOnly &&
            getControlledComponent(
              'AuthorizePIN',
              t('profileForm.pin'),
              errors?.AuthorizePIN,
              false,
              readOnly,
              true,
            )}
          {!currentUser?.UserId &&
            !readOnly &&
            getControlledComponent(
              'PINConfirmation',
              t('profileForm.confirmPIN'),
              errors?.PINConfirmation,
              false,
              readOnly,
              true,
            )}
        </Body>
        <ButtonsContainer>
          {!readOnly && (
            <FormButton type="submit">{t('generalWords.ok')}</FormButton>
          )}
          {!readOnly && (
            <FormButton type="button" $isCancel onClick={onClose}>
              {t('generalWords.cancel')}
            </FormButton>
          )}
        </ButtonsContainer>
      </Container>
    </FadedContainer>
  );
}

ProfileForm.propTypes = {
  onClose: func.isRequired,
  currentUser: shape({
    UserId: string,
    FullName: string,
    Mobile: string,
    Password: string,
    PIN: string,
    ImageUrl: string,
  }).isRequired,
};
