import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { bool, func, node, string } from 'prop-types';
import { TooltipArrow, TooltipContent } from './styles';

export function Tooltip({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  ...props
}) {
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipContent side="top" align="center" {...props}>
        {content}
        <TooltipArrow offset={5} width={11} height={5} />
      </TooltipContent>
    </TooltipPrimitive.Root>
  );
}

Tooltip.propTypes = {
  children: node.isRequired,
  content: string.isRequired,
  open: bool,
  defaultOpen: bool,
  onOpenChange: func,
};

Tooltip.defaultProps = {
  open: undefined,
  defaultOpen: undefined,
  onOpenChange: undefined,
};
