const convertDateToUtc = (date) => {
  const dateObj = new Date(
    `${date} ${new Date().getHours()}:${new Date()
      .getMinutes()
      .toString()
      .padStart(2, '0')}`,
  );

  return `${dateObj.getUTCFullYear()}-${(dateObj.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dateObj.getUTCDate().toString().padStart(2, '0')}`;
};

export { convertDateToUtc };
