import AuditButton from 'components/AuditButton';
import AuditLogPopup from 'components/AuditLogPopup';
import { FadedContainer } from 'components/FadedContainer';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useGetAuditListQuery } from 'store/slices/apiSlice/endpoints/audit';
import { ClientRechargeSection } from './components/ClientRechargeSection';
import PhoneSearch from './components/PhoneSearch';
import { useRecharge } from './context/RechargeContext';
import { Container } from './styles';

export function RechargePage() {
  const { currentRechargeData } = useRecharge();
  const [showAuditData, setShowAuditData] = useState(false);
  const { data: auditData, isSuccess } = useGetAuditListQuery(
    {
      id: currentRechargeData?.recipientUser?.AccountId,
      topicDesc: 'Account',
    },
    { skip: !currentRechargeData?.recipientUserIsSelected },
  );
  return (
    <FadedContainer>
      {isSuccess && showAuditData && auditData?.length && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setShowAuditData(false)}
          title={`Logs de alteração - Registro: Conta [${currentRechargeData?.recipientUser?.AccountName}]`}
        />
      )}
      {currentRechargeData?.recipientUserIsSelected && (
        <AuditButton onClick={() => setShowAuditData(true)} />
      )}
      <Container>
        <AnimatePresence initial={false}>
          {currentRechargeData?.recipientUserIsSelected ? (
            <ClientRechargeSection />
          ) : (
            <PhoneSearch />
          )}
        </AnimatePresence>
      </Container>
    </FadedContainer>
  );
}
