import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export const MenuContent = styled(DropdownMenu.Content)`
  min-width: 150px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 5px;
  padding: 10px;
`;

export const MenuArrow = styled(DropdownMenu.Arrow)`
  fill: ${({ theme }) => theme.colors.background};
`;

export const RadioGroup = styled(DropdownMenu.RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 40vh;
  overflow-y: auto;
`;

export const RadioItem = styled(DropdownMenu.Item)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 30px;
  width: 100%;
  user-select: none;
  padding: 10px;
  font-size: 0.9rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 8px;
  transition: background-color 0.3s;

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.boxBackground};
  }
`;

export const CurrentDisplayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 10px;
  background-color: inherit;
  color: ${({ theme }) => theme.colors.text};
  border: ${({ theme, $readOnly }) =>
    `2px ${$readOnly ? 'dotted' : 'solid'} ${theme.colors.text}`};
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'all')};
  border-radius: 50px;
  width: 200px;
  font-size: 0.8rem;
  transition: background-color 300ms;
  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;
