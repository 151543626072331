import { node } from 'prop-types';
import React, { forwardRef } from 'react';
import {
  StyledAccordionContent,
  StyledAccordionHeader,
  StyledAccordionItem,
  StyledAccordionRoot,
  StyledAccordionTrigger,
} from './styles';

export const Accordion = StyledAccordionRoot;
export const AccordionItem = StyledAccordionItem;
export const AccordionTrigger = forwardRef(({ children, ...props }, ref) => (
  <StyledAccordionHeader>
    <StyledAccordionTrigger {...props} ref={ref}>
      {children}
    </StyledAccordionTrigger>
  </StyledAccordionHeader>
));
AccordionTrigger.propTypes = {
  children: node.isRequired,
};
export const AccordionContent = forwardRef(({ children, ...props }, ref) => (
  <StyledAccordionContent {...props} ref={ref}>
    {children}
  </StyledAccordionContent>
));
AccordionContent.propTypes = {
  children: node.isRequired,
};
