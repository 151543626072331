import {
  DropdownMenuTrigger,
  Root as DropdownMenuRoot,
} from '@radix-ui/react-dropdown-menu';
import { useTransaction } from 'pages/TransactionsPage/contexts/TransactionContext';
import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransactionTypeListQuery } from 'store/slices/apiSlice/endpoints/transaction';
import {
  Container,
  ImageVisibilityButton,
  MenuArrow,
  MenuContent,
  RadioGroup,
  RadioItem,
} from './styles';

export function TransactionTypeDropdownMenu({ readOnly }) {
  const { t } = useTranslation();
  const { data: transactionTypeList, isSuccess } = useTransactionTypeListQuery(
    undefined,
    {
      refetchOnFocus: false,
      refetchOnReconnect: false,
    },
  );
  const { transactionFilter, setTransactionFilter } = useTransaction();
  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    setMenuOptions(
      transactionTypeList?.map((transactionType) => ({
        id: transactionType.TransactionTypeId,
        itemDisplay: transactionType.TransactionTypeDesc,
        icon: transactionType.TransactionTypeIcon,
        currentDisplay: (
          <ImageVisibilityButton $readOnly={readOnly}>
            <i className={transactionType.TransactionTypeIcon} />
            <span>{transactionType.TransactionTypeDesc}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </ImageVisibilityButton>
        ),
      })),
    );
  }, [transactionTypeList, readOnly]);

  const handleOptionClick = (optionId) => {
    setTransactionFilter((prevState) => ({
      ...prevState,
      TransactionTypeId: optionId,
    }));
  };

  return (
    isSuccess && (
      <Container>
        <span>{t('transactionsPage.type')}</span>
        <DropdownMenuRoot>
          <DropdownMenuTrigger asChild>
            {
              menuOptions?.find(
                (transactionType) =>
                  transactionType.id === transactionFilter.TransactionTypeId,
              )?.currentDisplay
            }
          </DropdownMenuTrigger>
          <MenuContent sideOffset={5}>
            <RadioGroup>
              {menuOptions?.map((option) => (
                <RadioItem
                  key={option.id}
                  onClick={() => handleOptionClick(option.id)}
                >
                  <i className={option.icon} />
                  <span>{option.itemDisplay}</span>
                </RadioItem>
              ))}
            </RadioGroup>
            <MenuArrow />
          </MenuContent>
        </DropdownMenuRoot>
      </Container>
    )
  );
}

TransactionTypeDropdownMenu.propTypes = {
  readOnly: bool,
};

TransactionTypeDropdownMenu.defaultProps = {
  readOnly: false,
};
