import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const AccessWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;
