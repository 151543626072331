import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.boxBackground};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 16px;
  padding: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    height: 90%;
  }
`;
