const currentEnvironment = 'DEV';

const appConfig = {
  DEV: {
    apiUrl: 'https://dindim-dev-api.azurewebsites.net/api',
    isDev: true,
  },
  PRD: {
    apiUrl: 'https://dindim-api.azurewebsites.net/api',
    isDev: false,
  },
};

export default appConfig[currentEnvironment];
