import AuditLogPopup from 'components/AuditLogPopup';
import TransactionStatusCellRender from 'components/cellRenderers/TransactionStatusCellRender';
import { DataGrid } from 'components/DataGrid';
import useWindowDimension from 'hooks/useWindowDimension';
import { useTransaction } from 'pages/TransactionsPage/contexts/TransactionContext';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetAuditListQuery } from 'store/slices/apiSlice/endpoints/audit';
import { currencyFormatter, dateTimeFormatter } from 'utils/formatters';
import { AuditButtonRenderer } from '../AuditButtonRenderer';
import { GridContainer, Title } from './styles';

export function LastTransactionsGrid() {
  const { t } = useTranslation();
  const {
    clientTransactionData,
    gridRegisterAuditParams,
    setGridRegisterAuditParams,
  } = useTransaction();
  const { data: auditData, isFetching: auditIsFetching } = useGetAuditListQuery(
    gridRegisterAuditParams,
    {
      refetchOnMountOrArgChange: true,
      skip: !gridRegisterAuditParams,
    },
  );
  const { dimensions } = useWindowDimension();
  const { timeZone } = useSelector(({ appOptions }) => appOptions);

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Data',
        field: 'TransactionDate',
        flex: dimensions.width > 800 ? 2 : undefined,
        valueFormatter: (data) => dateTimeFormatter(data, timeZone),
      },
      {
        headerName: 'Tipo',
        field: 'TransactionTypeDesc',
        flex: dimensions.width > 800 ? 2 : undefined,
      },
      {
        headerName: 'Beneficiário',
        field: 'CounterPartAccountName',
        flex: dimensions.width > 800 ? 3 : undefined,
      },
      {
        headerName: 'Valor',
        field: 'Amount',
        flex: dimensions.width > 800 ? 2 : undefined,
        valueFormatter: currencyFormatter,
      },
      {
        headerName: 'Status',
        field: 'TransactionStatusId',
        flex: dimensions.width > 800 ? 2 : undefined,
        cellRenderer: TransactionStatusCellRender,
      },
      {
        flex: dimensions.width > 800 ? 1 : undefined,
        cellRenderer: AuditButtonRenderer,
        cellRendererParams: {
          topicDesc: 'Transaction',
        },
      },
    ],
    [],
  );
  return (
    <GridContainer>
      {!auditIsFetching && gridRegisterAuditParams && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setGridRegisterAuditParams(null)}
          title="Logs de alteração - Registro: Transação"
        />
      )}
      <Title>{t('transactionsPage.filteredTransactions')}</Title>
      <DataGrid
        rowData={clientTransactionData.transactions}
        columnDefs={columnDefs}
      />
    </GridContainer>
  );
}
