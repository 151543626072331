import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import appConfig from 'appConfig';
import { angolaFlag, brazilFlag, portugalFlag } from 'assets';
import { func, number } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateCountryId } from 'store/slices/appOptionsSlice';
import {
  CurrentDisplayButton,
  FlagImage,
  MenuArrow,
  MenuContent,
  MenuTrigger,
  RadioGroup,
  RadioItem,
} from './styles';

export function FlagDropdownMenu({ value, setValue }) {
  const readOnly = !appConfig.isDev;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!appConfig.isDev) {
      // Set's default country Angola in Production environment
      dispatch(updateCountryId(1));
    }
  }, []);
  const menuOptions = useMemo(
    () => [
      {
        id: 1,
        name: 'Angola',
        image: angolaFlag,
        value: '244',
        currentDisplay: (
          <CurrentDisplayButton $flagImg={angolaFlag} $readOnly={readOnly} />
        ),
      },
      {
        id: 2,
        name: 'Portugal',
        image: portugalFlag,
        value: '351',
        currentDisplay: (
          <CurrentDisplayButton $flagImg={portugalFlag} $readOnly={readOnly} />
        ),
      },
      {
        id: 3,
        name: 'Brasil',
        image: brazilFlag,
        value: '55',
        currentDisplay: (
          <CurrentDisplayButton $flagImg={brazilFlag} $readOnly={readOnly} />
        ),
      },
    ],
    [],
  );

  const handleOptionChange = (id) => {
    setValue(id);
  };

  return (
    <DropdownMenu.Root>
      <MenuTrigger asChild>
        {menuOptions?.find((option) => option.id === value)?.currentDisplay}
      </MenuTrigger>
      <MenuContent sideOffset={5}>
        <RadioGroup>
          {menuOptions.map((option) => (
            <RadioItem
              key={option.id}
              onClick={() => handleOptionChange(option.id)}
            >
              <FlagImage $bkgImg={option.image} />
              <span>{option.name}</span>
            </RadioItem>
          ))}
        </RadioGroup>
        <MenuArrow />
      </MenuContent>
    </DropdownMenu.Root>
  );
}

FlagDropdownMenu.propTypes = {
  value: number,
  setValue: func.isRequired,
};

FlagDropdownMenu.defaultProps = {
  value: 1,
};
