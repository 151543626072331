import AuditResult from 'components/AuditResult';
import { BackButton } from 'components/BackButton';
import { FadedContainer } from 'components/FadedContainer';
import { LoadIndicator } from 'components/LoadIndicator';
import { useAudit } from 'pages/AuditPage/contexts/AuditContext';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetAuditActionListQuery,
  useGetAuditListFilteredQuery,
  useGetAuditTopicListQuery,
} from 'store/slices/apiSlice/endpoints/audit';
import { Container, Header, MainContent, Title } from './styles';

function AuditResultSection() {
  const { t } = useTranslation();
  const { setAuditFilter, auditFilter } = useAudit();
  const { data: topicList } = useGetAuditTopicListQuery(undefined);
  const { data: actionList } = useGetAuditActionListQuery();
  const { data: auditList, isLoading } = useGetAuditListFilteredQuery(
    auditFilter,
    {
      refetchOnMountOrArgChange: true,
      skip: !auditFilter?.IsActive,
    },
  );

  const getTitle = useMemo(
    () =>
      `Tabela: [${t(
        `audit.${
          topicList.find((topic) => topic.TopicId === auditFilter.TopicId)
            ?.TopicDesc
        }`,
      )}] / Operação: [${t(
        `audit.${
          actionList.find((action) => action.ActionId === auditFilter.ActionId)
            ?.ActionDesc
        }`,
      )}]`,
    [auditFilter],
  );

  return (
    <FadedContainer>
      <LoadIndicator visible={isLoading} />
      <Container>
        <Header>
          <BackButton
            onClick={() => {
              setAuditFilter((prevState) => ({
                ...prevState,
                IsActive: false,
              }));
            }}
          />
          <Title>{getTitle}</Title>
        </Header>
        <MainContent>
          <AuditResult data={auditList} />
        </MainContent>
      </Container>
    </FadedContainer>
  );
}

export default AuditResultSection;
