import { Drawer } from 'components/Drawer';
import Header from 'components/Header';
import { differenceInDays, parseISO } from 'date-fns';
import { node } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { appOptionsSetTimezone } from 'store/slices/appOptionsSlice';
import { rechargeResetList } from 'store/slices/rechargeSlice';
import { ContentContainer, MainContainer } from './styles';

export function AppContainer({ children }) {
  const { pathname } = useLocation();
  const { currentDate } = useSelector(({ recharge }) => recharge);
  const dispatch = useDispatch();
  useEffect(() => {
    if (differenceInDays(parseISO(currentDate), new Date()) !== 0) {
      dispatch(rechargeResetList());
    }
    dispatch(appOptionsSetTimezone());
  }, []);

  const pageWithHeader = () =>
    pathname !== '/' && !pathname.includes('/recuperacao');

  return (
    <MainContainer>
      {pageWithHeader() && <Drawer />}
      <ContentContainer $isLoginPage={pathname === '/'}>
        {pageWithHeader() && <Header />}
        {children}
      </ContentContainer>
    </MainContainer>
  );
}

AppContainer.propTypes = {
  children: node.isRequired,
};
