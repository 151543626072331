import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: transparent;
  gap: 20px;
  padding: 0 5px;

  @media (max-width: 1000px) {
    gap: 10px;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1rem;

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
`;
