import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const PermissionsPageContext = createContext();

const PermissionsPageProvider = ({ children }) => {
  const [groupSelected, setGroupSelected] = useState(null);

  const permissionsPageContextValues = useMemo(
    () => ({
      groupSelected,
      setGroupSelected,
    }),
    [groupSelected],
  );

  return (
    <PermissionsPageContext.Provider value={permissionsPageContextValues}>
      {children}
    </PermissionsPageContext.Provider>
  );
};

PermissionsPageProvider.propTypes = {
  children: node.isRequired,
};

const usePermissionsPage = () => useContext(PermissionsPageContext);

export { usePermissionsPage, PermissionsPageProvider };
