import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useGetMenuListByUserQuery } from 'store/slices/apiSlice/endpoints/permission';
import { Button } from './styled';

const AuditButton = forwardRef((props, ref) => {
  const { userData } = useSelector(({ auth }) => auth);

  const { data: menuList } = useGetMenuListByUserQuery(undefined, {
    skip: !userData,
  });

  if (
    !menuList?.length ||
    menuList?.find((menu) => menu.Url === 'auditoria' && !menu.AccessControl)
  ) {
    return <div />;
  }

  return (
    <Button type="button" {...props} ref={ref}>
      <i className="fas fa-clock-rotate-left" />
    </Button>
  );
});

export default AuditButton;
