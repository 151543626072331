import { bool, element, string } from 'prop-types';
import React, { cloneElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

export function ActiveLink({ children, shouldMatchExactHref, to, ...props }) {
  const theme = useTheme();
  let isActive = false;
  const { pathname } = useLocation();

  if (
    (shouldMatchExactHref && pathname === to) ||
    pathname.startsWith(String(to))
  ) {
    isActive = true;
  }

  return (
    <Link {...props} to={to} style={{ width: '100%' }}>
      {cloneElement(children, {
        bg: isActive ? theme.colors.background : 'transparent',
      })}
    </Link>
  );
}

ActiveLink.propTypes = {
  children: element.isRequired,
  shouldMatchExactHref: bool,
  to: string.isRequired,
};

ActiveLink.defaultProps = {
  shouldMatchExactHref: false,
};
