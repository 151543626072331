import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadIndicator } from 'components/LoadIndicator';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useChangeForgottenPasswordMutation,
  useForgottenPasswordMutation,
} from 'store/slices/apiSlice/endpoints/sysUser';
import { authClearRecoveryEmail } from 'store/slices/authSlice';
import * as yup from 'yup';
import {
  ButtonsContainer,
  CodeInput,
  FieldsContainer,
  GhostButton,
  HeaderContainer,
  Input,
  LoginButton,
  MainForm,
  Title,
} from './styles';

export const RecoveryForm = () => {
  const [changeForgottenPassword] = useChangeForgottenPasswordMutation();
  const defaultResendTimeoutInSeconds = 30;
  const [resendTimeout, setResendTimeout] = useState(
    defaultResendTimeoutInSeconds,
  );
  const [resendButtonActive, setResendButtonActive] = useState(false);
  const [forgottenPassword] = useForgottenPasswordMutation();
  const { recoveryEmail: email } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginFormSchema = yup.object({
    RecoveryCode: yup
      .string()
      .required(t('recoveryPage.verificationCodeRequired')),
    NewPassword: yup.string().required(t('recoveryPage.newPasswordRequired')),
    ConfirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('NewPassword'), null],
        t('recoveryPage.passwordsMustMatch'),
      ),
  });

  const { register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(loginFormSchema),
  });

  const { errors, isSubmitting } = formState;

  useEffect(() => {
    let timeout;
    if (resendTimeout > 0) {
      setResendButtonActive(false);
      timeout = setTimeout(() => setResendTimeout((prev) => prev - 1), 1000);
    } else {
      setResendButtonActive(true);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [resendTimeout]);

  const handleSendNewPassword = async ({ RecoveryCode, NewPassword }) => {
    try {
      const res = await changeForgottenPassword({
        RecoveryCode,
        NewPassword,
        Email: email,
      }).unwrap();
      if (res?.data?.ErrorMsg) {
        toast(res?.data?.ErrorMsg || 'Erro ao atualizar a senha', {
          type: 'error',
        });
        return;
      }
      dispatch(authClearRecoveryEmail());
      toast(t('recoveryPage.passwordChangedSuccess'), { type: 'success' });
      navigate('/');
    } catch (error) {
      toast(error?.data?.ErrorMsg || 'Erro ao atualizar a senha', {
        type: 'error',
      });
    }
  };

  const codeRegister = register('RecoveryCode');
  const passwordRegister = register('NewPassword');
  const confirmPasswordRegister = register('ConfirmPassword');

  return (
    <MainForm onSubmit={handleSubmit(handleSendNewPassword)}>
      <LoadIndicator visible={isSubmitting} />
      <HeaderContainer>
        <Title>{t('recoveryPage.informVerificationCode')}</Title>
      </HeaderContainer>
      <FieldsContainer>
        <CodeInput
          // label={t('recoveryPage.verificationCode')}
          placeholder="000000"
          error={errors.RecoveryCode}
          disabled={isSubmitting}
          maxLength={6}
          {...codeRegister}
        />
        <Input
          label={t('generalWords.password')}
          type="password"
          isPasswordField
          error={errors.NewPassword}
          disabled={isSubmitting}
          {...passwordRegister}
        />
        <Input
          label={t('generalWords.confirmPassword')}
          type="password"
          isPasswordField
          error={errors.ConfirmPassword}
          disabled={isSubmitting}
          {...confirmPasswordRegister}
        />
      </FieldsContainer>
      <ButtonsContainer>
        <LoginButton
          type="submit"
          disabled={
            !!errors?.NewPassword ||
            !!errors?.RecoveryCode ||
            !!errors?.ConfirmPassword ||
            isSubmitting
          }
        >
          {isSubmitting ? t('generalWords.wait') : t('generalWords.confirm')}
        </LoginButton>
        <GhostButton
          disabled={!resendButtonActive}
          type="button"
          onClick={async () => {
            setResendTimeout(30);
            await forgottenPassword(email).unwrap();
          }}
        >
          <span>
            {resendTimeout
              ? `${resendTimeout
                  .toString()
                  .padStart(2, 0)} segundos para novo código`
              : 'Solicitar novo código'}
          </span>
        </GhostButton>
        <GhostButton type="button" onClick={() => navigate('/')}>
          <i className="fas fa-chevron-left" />
          {t('recoveryPage.passwordRemembered')}
        </GhostButton>
      </ButtonsContainer>
    </MainForm>
  );
};
