import { DataGrid } from 'components/DataGrid';
import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import useWindowDimension from 'hooks/useWindowDimension';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import { PasswordInput } from 'pages/RechargePage/components/ClientRechargeSection/components/ConfirmRechargeDialog/styles';
import { func } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetAccountQuery } from 'store/slices/apiSlice/endpoints/account';
import { useLazyBusinessEmployeeListQuery } from 'store/slices/apiSlice/endpoints/business';
import { useBenefitSendMutation } from 'store/slices/apiSlice/endpoints/transaction';
import { currencyFormatter } from 'utils/formatters';
import { currencyMask } from 'utils/masks';
import { v4 as getUuid } from 'uuid';
import { CellCurrencyInput } from './components/CellCurrencyInput';
import { ValueInput } from './components/ValueInput';
import {
  BalanceLabel,
  BalanceSpan,
  BalanceWrapper,
  ButtonsContainer,
  Container,
  FieldSet,
  FooterWrapper,
  FormButton,
  GridWrapper,
  InputContainer,
  SummaryWrapper,
} from './styles';

const EmployeeBenefitsPopup = ({ onClose, ...props }) => {
  const { t } = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [currentSelectedRowsIndex, setCurrentSelectedRowsIndex] = useState([]);
  const [totals, setTotals] = useState({
    benefitTotal: 0.0,
    // transactionTax: 0.0,
    // totalWithTax: 0.0,
  });
  const { control, setValue, getValues } = useForm();
  const { currentBusinessSelected } = useBusiness();
  const { dimensions } = useWindowDimension();
  const [employeeList] = useLazyBusinessEmployeeListQuery();
  const [benefitSend, { isLoading }] = useBenefitSendMutation();
  const { data: businessAccount } = useGetAccountQuery(
    currentBusinessSelected?.MainAccountId,
    {
      skip: !currentBusinessSelected?.MainAccountId,
      refetchOnMountOrArgChange: true,
    },
  );

  const benefitDataGridRef = useRef();

  useEffect(() => {
    const fetchGridData = async () => {
      const basicList = await employeeList(
        currentBusinessSelected.BusinessId,
      ).unwrap();
      return basicList.map((data) => ({
        ...data,
        BenefitValue: 0,
      }));
    };

    let isSubscribed = true;
    if (currentBusinessSelected?.BusinessId) {
      fetchGridData().then((data) => {
        if (isSubscribed) {
          setGridData(data);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [currentBusinessSelected]);

  const calculateTotals = () => {
    const selectedRows = benefitDataGridRef?.current?.api?.getSelectedRows();
    setCanSave(selectedRows && selectedRows.length > 0);
    if (selectedRows) {
      const benefitTotal = selectedRows.reduce(
        (acc, curr) => acc + parseFloat(curr.BenefitValue),
        0,
      );
      if (benefitTotal === 0) {
        setTotals({
          benefitTotal: 0,
          // transactionTax: 0,
          // totalWithTax: 0,
        });
        return;
      }

      setTotals({
        benefitTotal,
        // transactionTax: (benefitTotal * 10) / 100,
        // totalWithTax: benefitTotal * 1.1,
      });
    }
  };

  useEffect(() => {
    if (currentSelectedRowsIndex.length) {
      benefitDataGridRef?.current?.api?.forEachNode((node, index) => {
        node.setSelected(currentSelectedRowsIndex.includes(index));
      });
    }
    calculateTotals();
  }, [gridData]);

  const defaultColumnDefs = useMemo(
    () => ({
      filter: 'agTextColumnFilter',
    }),
    [],
  );

  const columnDefs = useMemo(
    () => [
      {
        headerName: t('businessPage.account'),
        field: 'AccountNumber',
        flex: dimensions.width > 800 ? 1 : undefined,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        headerName: t('businessPage.employee'),
        field: 'AccountName',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
      {
        headerName: t('businessPage.benefitValue'),
        field: 'BenefitValue',
        flex: dimensions.width > 800 ? 1 : undefined,
        valueFormatter: currencyFormatter,
        editable: true,
        cellEditor: CellCurrencyInput,
      },
    ],
    [],
  );

  const applyToAll = () => {
    if (benefitDataGridRef?.current?.api) {
      setCurrentSelectedRowsIndex(
        benefitDataGridRef?.current?.api
          .getSelectedNodes()
          ?.map((node) => node.rowIndex),
      );
    }

    const benefitValue = getValues('benefitValue');
    if (!benefitValue) {
      setGridData((prevState) =>
        prevState.map((employee) => ({
          ...employee,
          BenefitValue: 0,
        })),
      );
      return;
    }

    const convertedBenefit = benefitValue.replaceAll('.', '').replace(',', '.');

    setGridData((prevState) =>
      prevState.map((employee) => ({
        ...employee,
        BenefitValue: convertedBenefit,
      })),
    );
  };

  const handleBenefitSend = async () => {
    const confirmationPin = getValues('confirmationPin');
    if (!confirmationPin) {
      toast.error('Informe o PIN');
      return;
    }
    if (confirmationPin && confirmationPin.length !== 4) {
      toast.error('PIN inválido');
      return;
    }

    const selectedRows = benefitDataGridRef?.current?.api?.getSelectedRows();

    const benefitTransaction = {
      BusinessAccountId: currentBusinessSelected?.MainAccountId,
      PIN: confirmationPin,
      // AmountWithTax: totals.totalWithTax,
      IsBenefit: true,
      BenefitList: selectedRows.map((benefit) => ({
        TransactionId: getUuid(),
        Amount: parseFloat(benefit.BenefitValue),
        AccountId: currentBusinessSelected.MainAccountId,
        CounterPartAccountId: benefit.AccountId,
        Notes: `Benefício concedido por ${currentBusinessSelected.BusinessName}`,
        PIN: confirmationPin,
      })),
    };

    try {
      await benefitSend(benefitTransaction).unwrap();
      toast.success('Benefícios enviados para processamento');
      onClose();
    } catch (e) {
      toast.error(e?.data?.ErrorMsg || 'Erro ao enviar benefícios');
    }
  };

  return (
    <Dialog
      title={t('businessPage.benefit')}
      width={dimensions.width < 900 ? '100%' : '900px'}
      height="95%"
      onClose={onClose}
      {...props}
    >
      <Container>
        <LoadIndicator visible={isLoading} />
        <BalanceWrapper>
          <BalanceLabel>Saldo atual: </BalanceLabel>
          <BalanceSpan>
            {currencyFormatter({ value: businessAccount?.Balance })}
          </BalanceSpan>
        </BalanceWrapper>
        <FieldSet>
          <Controller
            control={control}
            name="benefitValue"
            defaultValue=""
            render={({ field: { onChange, ...props } }) => (
              <ValueInput
                placeholder="0,00"
                onChange={(e) =>
                  onChange(
                    currencyMask({
                      value: e.target.value,
                      withCurrencySymbol: false,
                    }),
                  )
                }
                clearField={() => setValue('benefitValue', '')}
                {...props}
              />
            )}
          />
          <FormButton onClick={applyToAll}>
            {t('businessPage.applyToAll')}
          </FormButton>
        </FieldSet>
        <GridWrapper>
          <DataGrid
            ref={benefitDataGridRef}
            rowData={gridData}
            columnDefs={columnDefs}
            defaultColDef={defaultColumnDefs}
            rowSelection="multiple"
            suppressRowClickSelection
            onSelectionChanged={calculateTotals}
            onCellValueChanged={calculateTotals}
          />
        </GridWrapper>
        <FooterWrapper>
          <SummaryWrapper>
            <BalanceWrapper>
              <BalanceLabel>Total dos benefícios: </BalanceLabel>
              <BalanceSpan
                $isNegative={businessAccount?.Balance < totals?.benefitTotal}
              >
                {currencyFormatter({ value: totals.benefitTotal })}
              </BalanceSpan>
            </BalanceWrapper>
            {/* <BalanceWrapper>
              <BalanceLabel>Taxa de transação: </BalanceLabel>
              <BalanceLabel>
                {currencyFormatter({ value: totals.transactionTax })}
              </BalanceLabel>
            </BalanceWrapper>
            <BalanceWrapper>
              <BalanceLabel>Total (com taxas): </BalanceLabel>
              <BalanceSpan
                $isNegative={businessAccount?.Balance < totals?.totalWithTax}
              >
                {currencyFormatter({ value: totals.totalWithTax })}
              </BalanceSpan>
            </BalanceWrapper> */}
          </SummaryWrapper>
          <InputContainer>
            <Controller
              control={control}
              name="confirmationPin"
              defaultValue=""
              render={({ field: { ...props } }) => (
                <PasswordInput
                  label={t('generalWords.pin')}
                  isPasswordField
                  {...props}
                />
              )}
            />
          </InputContainer>
        </FooterWrapper>
      </Container>
      <ButtonsContainer>
        <FormButton
          disabled={
            (totals.benefitTotal > 0 &&
              businessAccount?.Balance < totals?.benefitTotal) ||
            !canSave ||
            totals.benefitTotal === 0
          }
          onClick={handleBenefitSend}
        >
          {t('businessPage.makeBenefit')}
        </FormButton>
        <FormButton $isCancel onClick={onClose}>
          {t('generalWords.cancel')}
        </FormButton>
      </ButtonsContainer>
    </Dialog>
  );
};

EmployeeBenefitsPopup.propTypes = {
  onClose: func.isRequired,
};
export default EmployeeBenefitsPopup;
