import { UserCard } from 'components/UserCard';
import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useBackOfficeUserListQuery } from 'store/slices/apiSlice/endpoints/backOfficeUser';
import { useBackOfficeUser } from '../../context/BackOfficeUserContext';
import { BackOfficeUserButton, ListContainer } from './styles';

export default function BackOfficeUsersList({ disabled }) {
  const [listToRender, setListToRender] = useState([]);
  const { data: usersList } = useBackOfficeUserListQuery();
  const {
    currentBackOfficeUserData,
    setCurrentBackOfficeUserData,
    filteredUsers,
  } = useBackOfficeUser();

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setListToRender(filteredUsers?.length ? filteredUsers : usersList);
    }

    return () => {
      isSubscribed = false;
    };
  }, [filteredUsers, usersList]);

  function handlePersonSelected(person) {
    if (
      person.UserId === currentBackOfficeUserData?.backOfficeUserData?.UserId
    ) {
      setCurrentBackOfficeUserData({
        backOfficeUserData: null,
        backOfficeUserIsSelected: false,
      });
      return;
    }
    setCurrentBackOfficeUserData({
      backOfficeUserData: { ...person },
      backOfficeUserIsSelected: true,
    });
  }
  return (
    <ListContainer>
      {listToRender?.map((backOfficeUser) => (
        <BackOfficeUserButton
          key={backOfficeUser.UserId}
          onClick={() => handlePersonSelected(backOfficeUser)}
          $isSelected={
            backOfficeUser.UserId ===
            currentBackOfficeUserData?.backOfficeUserData?.UserId
          }
          disabled={disabled}
        >
          <UserCard
            avatarSize={40}
            usernameSize="0.9rem"
            mobileSize="0.7rem"
            user={backOfficeUser}
          />
        </BackOfficeUserButton>
      ))}
    </ListContainer>
  );
}

BackOfficeUsersList.propTypes = {
  disabled: bool,
};

BackOfficeUsersList.defaultProps = {
  disabled: false,
};
