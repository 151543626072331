import * as Avatar from '@radix-ui/react-avatar';
import styled from 'styled-components';

export const AvatarRoot = styled(Avatar.Root)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.boxBackground};
`;

export const AvatarImage = styled(Avatar.Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

export const AvatarFallback = styled(Avatar.Fallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.avatarBackground};
  color: ${({ theme }) => theme.colors.accent};
  font-size: calc((${({ size }) => size || '45px'} * 40) / 100);
  line-height: 1;
  font-weight: 500;
`;
