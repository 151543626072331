import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

export const AppLogo = () => {
  const theme = useTheme();
  const iconColoredVariant = {
    hidden: {
      pathLength: 0,
      fill: 'rgba(129, 194, 66, 0)',
    },
    visible: {
      pathLength: 1,
      fill: 'rgba(129, 194, 66, 1)',
    },
  };
  const iconLogoVariant = useMemo(
    () => ({
      hidden: {
        pathLength: 0,
        fill: theme?.colors?.iconLogoTransparent,
      },
      visible: {
        pathLength: 1,
        fill: theme?.colors?.iconLogoVisible,
      },
    }),
    [theme],
  );
  const transition = {
    default: { duration: 2, ease: 'easeInOut' },
    fill: { duration: 3, ease: [1, 0, 0.8, 1] },
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="212"
      height="51"
      viewBox="0 0 212 51"
    >
      <g id="Group_13" data-name="Group 13" transform="translate(-132 -170)">
        <text
          id="dindim"
          transform="translate(189 210)"
          fill={theme?.colors?.iconLogoVisible}
          fontSize="42"
          fontFamily="Poppins"
          fontWeight="500"
        >
          <tspan x="0" y="0">
            dindim
          </tspan>
        </text>
        <g id="Logo-Icon" transform="translate(20812.076 19996.01)">
          <motion.rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="14"
            height="51"
            transform="translate(-20651.076 -19826.01)"
            variants={iconColoredVariant}
            initial="hidden"
            animate="visible"
            transition={transition}
          />
          <motion.path
            id="Subtraction_1"
            data-name="Subtraction 1"
            d="M21,43h0a20.719,20.719,0,0,1-8.18-1.732,21.076,21.076,0,0,1-6.674-4.616,21.454,21.454,0,0,1-4.5-6.817,21.9,21.9,0,0,1,0-16.67,21.454,21.454,0,0,1,4.5-6.817A21.076,21.076,0,0,1,12.82,1.732,20.724,20.724,0,0,1,21,0Z"
            transform="translate(-20680.076 -19822.01)"
            variants={iconLogoVariant}
            initial="hidden"
            animate="visible"
            transition={transition}
          />
        </g>
      </g>
    </svg>
  );
};
