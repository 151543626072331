import { format } from 'date-fns';
import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const AuditContext = createContext();

function AuditProvider({ children }) {
  const [auditFilter, setAuditFilter] = useState({
    TopicId: 0,
    ActionId: 0,
    StartDate: format(new Date(), 'yyyy-MM-dd'),
    EndDate: format(new Date(), 'yyyy-MM-dd'),
    IsActive: false,
  });

  const auditContextValues = useMemo(
    () => ({ auditFilter, setAuditFilter }),
    [auditFilter],
  );

  return (
    <AuditContext.Provider value={auditContextValues}>
      {children}
    </AuditContext.Provider>
  );
}

AuditProvider.propTypes = {
  children: node.isRequired,
};

const useAudit = () => useContext(AuditContext);

export { AuditProvider, useAudit };
