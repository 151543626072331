import {
  CurrentOptionButton,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from 'components/DropdownMenu';
import { useAudit } from 'pages/AuditPage/contexts/AuditContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAuditTopicListQuery } from 'store/slices/apiSlice/endpoints/audit';
import { Container } from './styles';

function TopicDropdownMenu() {
  const { t } = useTranslation();
  const { auditFilter, setAuditFilter } = useAudit();
  const [menuOptions, setMenuOptions] = useState([]);
  const { data: auditTopicList, isSuccess } = useGetAuditTopicListQuery(
    undefined,
    {
      refetchOnFocus: false,
      refetchOnReconnect: false,
    },
  );

  const handleOptionClick = (optionId) => {
    setAuditFilter((prevState) => ({
      ...prevState,
      TopicId: optionId,
    }));
  };

  useEffect(() => {
    setMenuOptions(
      auditTopicList?.map((topic) => ({
        id: topic.TopicId,
        itemDisplay: t(`audit.${topic.TopicDesc}`),
        currentDisplay: (
          <CurrentOptionButton $readOnly={false}>
            <span>{t(`audit.${topic.TopicDesc}`)}</span>
            <i className="fas fa-chevron-down" />
          </CurrentOptionButton>
        ),
      })),
    );
  }, [auditTopicList]);

  return (
    isSuccess && (
      <Container>
        <span>{t('audit.auditTopic')}</span>
        <DropdownMenuRoot>
          <DropdownMenuTrigger asChild>
            {
              menuOptions?.find(
                (auditTopic) => auditTopic.id === auditFilter.TopicId,
              )?.currentDisplay
            }
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuRadioGroup>
              {menuOptions?.map((option) => (
                <DropdownMenuRadioItem
                  key={option.id}
                  onClick={() => handleOptionClick(option.id)}
                >
                  <span>{option.itemDisplay}</span>
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenuRoot>
      </Container>
    )
  );
}

export default TopicDropdownMenu;
