import { Input } from 'components/Input';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SearchInputComponent = styled(Input)`
  display: flex;
  flex: 1;
  background-color: transparent;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  margin: 2.5px 0 2.5px 10px;

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const FieldContainer = styled.form`
  display: flex;
  background-color: ${({ theme }) => theme.colors.searchInputBackground};
  border-radius: 25px;
  height: 36px;
  gap: 30px;
`;

export const SearchButton = styled(motion.button)`
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  border: none;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  width: 80px;
  height: 36px;
  margin-right: -2px;

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;
