import styled from 'styled-components';

export const WrapperField = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 16px;

  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const FieldText = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
`;

export const DataText = styled.div`
  color: ${({ theme }) => theme.colors.text};
`;
