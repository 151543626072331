import styled, { keyframes } from 'styled-components';

const motionShow = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}`;

const spinnerRotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  background-color: ${({ theme }) => theme.colors.overlay};
  animation: ${motionShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
  flex: 1;
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 40px;
  border-radius: 8px;

  background-color: ${({ theme }) => theme.colors.background};
  z-index: 11;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Spinner = styled.i`
  animation: ${spinnerRotation} 1s linear infinite;
  color: ${({ theme }) => theme.colors.accent};
  font-size: 2.5rem;
`;
