import { ProfileButton } from 'components/ProfileButton';
import { ProfileProvider } from 'components/ProfileButton/context/ProfileContext';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HeaderContainer, PageName } from './styles';

export default function Header() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const pageTitle = useMemo(() => {
    switch (pathname) {
      case '/home':
        return t('pages.home');
      case '/clientes':
        return t('pages.clients');
      case '/recargas':
        return t('pages.recharges');
      case '/empresas':
        return t('pages.business');
      case '/transacoes':
        return t('pages.transactions');
      case '/utentes':
        return t('pages.users');
      default:
        return '';
    }
  }, [pathname]);

  return (
    <HeaderContainer>
      <PageName>{pageTitle}</PageName>
      <ProfileProvider>
        <ProfileButton />
      </ProfileProvider>
    </HeaderContainer>
  );
}
