import { Button } from 'components/Button';
import { Input as MotionInput } from 'components/Input';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  gap: 5px;
  padding: 10px 5px;
`;

export const FieldsGroup = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ $colCount }) => `repeat(${$colCount || 1}, 1fr)`};
  gap: 7px;
  width: ${({ $width }) => $width || '100%'};
  background: inherit;
  padding: 0 10px 10px;
`;

export const Input = styled(MotionInput)`
  background-color: transparent;
  color: ${({ theme, label }) =>
    label === 'Saldo' ? theme.colors.accent : theme.colors.text};
  border-radius: 8px;
  border: ${({ $readOnly, error, theme }) =>
    `2px ${$readOnly ? 'dotted' : 'solid'} ${
      error ? theme.colors.error : theme.colors.text
    }`};
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'all')};
  width: ${({ $width }) => $width || '100%'} !important;
`;

export const MobileInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  width: 95%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 30px;
  padding: 10px 15px;
  flex: 1;
`;

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
`;
