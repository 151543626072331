import { Button } from 'components/Button';
import styled, { keyframes } from 'styled-components';

const motionShow = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  background-color: ${({ theme }) => theme.colors.overlay};
  animation: ${motionShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 15px;
  border-radius: 8px;
  z-index: 15;

  width: ${({ $width }) => $width} !important;
  height: ${({ $height }) => $height} !important;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: ${motionShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @media (max-width: 800px), (max-height: 540px) {
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    width: 100% !important;
    height: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px 10px;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 10px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const CloseButton = styled(Button)`
  background-color: inherit;
  border: none;
  color: ${({ theme }) => theme.colors.text};
`;
