import { BackButton } from 'components/BackButton';
import { FadedContainer } from 'components/FadedContainer';
import { UserCard } from 'components/UserCard';
import useWindowDimension from 'hooks/useWindowDimension';
import { useTransaction } from 'pages/TransactionsPage/contexts/TransactionContext';
import React from 'react';
import { useGetAccountQuery } from 'store/slices/apiSlice/endpoints/account';
import { currencyFormatter } from 'utils/formatters';
import { LastTransactionsGrid } from './components/LastTransactionsGrid';
import {
  BalanceLabel,
  BalanceSpan,
  BalanceWrapper,
  Container,
  Header,
  MainContent,
} from './styles';

export function TransactionsSection() {
  const { clientTransactionData, setClientTransactionData } = useTransaction();
  const { data: accountData } = useGetAccountQuery(
    clientTransactionData.client.AccountId,
    {
      refetchOnMountOrArgChange: true,
      skip: !clientTransactionData.client,
    },
  );
  const { dimensions } = useWindowDimension();
  return (
    <FadedContainer>
      <Container>
        <Header>
          <BackButton
            onClick={() => {
              setClientTransactionData({
                client: {
                  ClientId: '',
                  FirstName: '',
                  LastName: '',
                  Initials: '',
                  Mobile: '',
                },
                transactions: [],
              });
            }}
          />
        </Header>
        <MainContent>
          <UserCard
            user={{ ...clientTransactionData.client }}
            avatarSize={dimensions.width < 1000 ? 50 : 90}
            usernameSize={dimensions.width < 1000 ? '0.9rem' : '1.2rem'}
            mobileSize={dimensions.width < 1000 ? '0.75rem' : '1rem'}
          />
          <BalanceWrapper>
            <BalanceLabel>Saldo atual: </BalanceLabel>
            <BalanceSpan>
              {currencyFormatter({ value: accountData?.Balance })}
            </BalanceSpan>
          </BalanceWrapper>
        </MainContent>
        <LastTransactionsGrid />
      </Container>
    </FadedContainer>
  );
}
