import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
`;

export const Indicator = styled.i`
  color: ${({ theme, $isAllow }) =>
    $isAllow ? theme.colors.accent : theme.colors.error};
`;
