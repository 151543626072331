import AuditButton from 'components/AuditButton';
import AuditLogPopup from 'components/AuditLogPopup';
import { FadedContainer } from 'components/FadedContainer';
import React, { useState } from 'react';
import { useGetAuditListByTopicQuery } from 'store/slices/apiSlice/endpoints/audit';
import BusinessDataGrid from './components/BusinessDataGrid';
import BusinessDetail from './components/BusinessDetail';
import { useBusiness } from './contexts/BusinessContext';
import { Container } from './styles';

export function BusinessPage() {
  const { currentBusinessSelected } = useBusiness();
  const [showAuditData, setShowAuditData] = useState(false);
  const { data: auditData, isSuccess } = useGetAuditListByTopicQuery(
    'Business',
    { refetchOnMountOrArgChange: true, skip: !!currentBusinessSelected },
  );
  return (
    <FadedContainer>
      {isSuccess && showAuditData && auditData?.length && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setShowAuditData(false)}
          title="Logs de alteração - Registro: Tabela [Empresas]"
        />
      )}
      {auditData?.length && !currentBusinessSelected && (
        <AuditButton onClick={() => setShowAuditData(true)} />
      )}
      <Container>
        {currentBusinessSelected ? <BusinessDetail /> : <BusinessDataGrid />}
      </Container>
    </FadedContainer>
  );
}
