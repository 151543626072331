import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Tooltip } from 'components/Tooltip';
import { func } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateCountryId } from 'store/slices/appOptionsSlice';
import { phoneMask } from 'utils/masks';
import * as yup from 'yup';
import { phoneNumberType } from '../../utils/phoneNumberType';
import { FlagDropdownMenu } from './FlagDropdownMenu';
import { FieldContainer, SearchButton, SearchInput } from './styles';

export function PhoneSearchBox({ phoneSearchFn }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countryId } = useSelector(({ appOptions }) => appOptions);
  const phoneRegex = phoneNumberType(countryId).regex;

  const phoneSearchSchema = useMemo(
    () =>
      yup.object({
        phoneNumber: yup
          .string()
          .required('Informe um telemóvel')
          .matches(phoneRegex, {
            message: 'Telemóvel fora do padrão',
          }),
      }),
    [countryId],
  );
  const { register, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(phoneSearchSchema),
  });
  const { errors } = formState;

  const onSubmit = useCallback(
    ({ phoneNumber }) => {
      let editedPhoneNumber;
      switch (countryId) {
        case 1:
        case 2:
          editedPhoneNumber = `${phoneNumber.replaceAll(' ', '')}`;
          break;
        case 3:
          editedPhoneNumber = `${phoneNumber
            .replaceAll(' ', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('-', '')}`;
          break;

        default:
          editedPhoneNumber = `${phoneNumber.replaceAll(' ', '')}`;
          break;
      }
      phoneSearchFn(editedPhoneNumber);
    },
    [countryId],
  );

  return (
    <FieldContainer onSubmit={handleSubmit(onSubmit)}>
      <FlagDropdownMenu
        value={countryId}
        setValue={(id) => dispatch(updateCountryId(id))}
      />
      <Tooltip content="Digite apenas números">
        <SearchInput
          type="tel"
          width="160px"
          placeholder={phoneNumberType(countryId).placeholder}
          error={errors.phoneNumber}
          {...register('phoneNumber', {
            onChange: (e) => {
              e.target.value = phoneMask({
                value: e.target.value,
                countryCode: countryId,
              });
              return e;
            },
          })}
        />
      </Tooltip>
      <Tooltip content={t('rechargePage.find')}>
        <SearchButton whileTap={{ scale: 0.95 }} type="submit">
          <i className="fas fa-search" />
        </SearchButton>
      </Tooltip>
    </FieldContainer>
  );
}

PhoneSearchBox.propTypes = {
  phoneSearchFn: func.isRequired,
};
