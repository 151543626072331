import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  width: 100%;
  padding: 5px;
  gap: 40px;

  label {
    cursor: pointer;
  }

  input {
    display: none;
  }
`;

export const EditionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: inherit;
`;

export const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.error};
  border: 2px solid ${({ theme }) => theme.colors.error};
  gap: 10px;
  font-size: 0.9rem;
  background-color: transparent;

  padding: 5px 10px;
  width: 150px;
`;
