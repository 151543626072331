export const pt = {
  translations: {
    generalWords: {
      password: 'Senha',
      confirmPassword: 'Confirme a senha',
      exit: 'Sair',
      clientNotFound: 'Cliente não encontrado',
      employeeNotFound: 'Funcionário não encontrado',
      loading: 'Carregando',
      noDataToShow: 'Não ha dados para exibir',
      informPin: 'Informe o PIN',
      ok: 'OK',
      cancel: 'Cancelar',
      mobile: 'Telemóvel',
      email: 'Email',
      confirm: 'Confirmar',
      wait: 'Aguarde',
      pin: 'PIN',
      pinConfirmation: 'Confirme o PIN',
      balance: 'Saldo',
      close: 'Fechar',
      changePassword: 'Alterar a senha',
      changePIN: 'Alterar o PIN',
      loadingPermissions: 'Carregando permissões...',
      noAccessPermissions: 'Você não tem permissão para acessar este recurso',
    },
    pages: {
      home: 'Home',
      recharges: 'Recargas',
      clients: 'Clientes',
      business: 'Empresas',
      transactions: 'Transações',
      employees: 'Funcionários',
      users: 'Utilizadores',
    },
    dashboard: {
      clients: 'Clientes',
      business: 'Empresas',
      approvedTransactions: 'Transações aprovadas',
      rejectedTransactions: 'Transações reprovadas',
    },
    loginScreen: {
      username: 'Nome',
      emailRequired: 'O email é obrigatório',
      passwordRequired: 'A senha é obrigatória',
      passwordMinLength: 'A senha deve ter pelo menos 6 caracteres',
      emailInvalid: 'Email inválido',
      showPassword: 'Mostrar senha',
      hidePassword: 'Esconder senha',
      access: 'Acessar',
      accessing: 'Acessando...',
      forgotPassword: 'Esqueceu a senha?',
    },
    menu: {
      home: 'Home',
      recharges: 'Recargas',
      clients: 'Clientes',
      business: 'Empresas',
      transactions: 'Transações',
      darkMode: 'Modo escuro',
      myProfile: 'Meu Perfil',
      employees: 'Funcionários',
      backOfficeUsers: 'Utilizadores',
      audit: 'Auditoria',
      permissions: 'Permissões',
    },
    rechargePage: {
      findPhone: 'Busque por um telemóvel',
      find: 'Buscar',
      history: 'Histórico',
      cleanField: 'Limpar valor',
      back: 'Voltar',
      recharge: 'Recarregar',
      lastRecharges: 'Últimas recargas',
      noRechargeYet: 'Nenhuma recarga feita hoje',
      rechargeDate: 'Data',
      recipient: 'Beneficiário',
      value: 'Valor',
      selfRechargeWarning: 'Você não pode fazer uma recarga para si mesmo',
    },
    avatarEditor: {
      deletePhoto: 'Excluir foto',
      public: 'Público',
      private: 'Privado',
    },
    clientForm: {
      model: 'Modelo',
      manufacturer: 'Fabricante',
      osVersion: 'Versão do S.O.',
      appVersion: 'Versão do App',
      updateOn: 'Atualizado em',
      createdOn: 'Criado em',
      accountNumber: 'Conta',
      status: 'Status',
      blockedUntil: 'Bloqueado até',
      errorTimes: 'Erros de login',
      accountData: 'Dados da conta',
      mobileData: 'Dados do aparelho telemóvel',
      bockUntil: 'Bloqueado até',
      for: 'por',
      wrongAttempts: 'tentativas erradas de usar o PIN',
      deviceError: 'Esse usuário não possui device',
    },
    profileForm: {
      status: 'Estado',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      fullName: 'Nome Completo',
      mobile: 'Telemóvel',
      business: 'Empresa',
      confirmPassword: 'Confirme a senha',
      pin: 'PIN',
      confirmPIN: 'Confirme o PIN',
      errorSavingImageChanges: 'Erro ao gravar alterações do avatar',
      imageOutOfStandard: 'A imagem escolhida não atende aos padrões',
      clickToEditAvatar:
        'Clique para alterar o avatar. (Tamanho máximo da imagem: 50KB)',
      successSavingProfileData: 'Perfil salvo com sucesso',
      errorSavingProfileData: 'Erro ao gravar o perfil',
      newUser: 'Novo Utilizador',
      fullNameRequired: 'Nome completo é obrigatório',
      reportNotification: 'Recebe e-mail de denúncias',
    },
    subAccountsForm: {
      accountName: 'Nome',
      newAccount: 'Nova Conta',
      editAccount: 'Editar Conta',
      changePin: 'Alterar PIN',
      oldPin: 'PIN antigo',
      newPin: 'Novo PIN',
      mobileRequired: 'Telemóvel obrigatório',
      accountNameRequired: 'Nome da conta obrigatório',
      pinMustMatch: 'Os PIN são diferentes',
      pinRequired: 'PIN obrigatório',
      pinSizeMustBeFour: 'O tamanho do PIN deve ser exatamente 4 dígitos',
      notOlderPin: 'PIN antigo incorreto',
    },
    transactionsPage: {
      today: 'Hoje',
      yesterday: 'Ontem',
      thisWeek: 'Esta semana',
      lastFifteenDays: 'Últimos 15 dias',
      thisMonth: 'Este mês',
      recharge: 'Recarga',
      payment: 'Pagamento',
      approved: 'Aprovada',
      rejected: 'Rejeitada',
      processing: 'Processando',
      filterOptions: 'Filtros',
      filterError: 'Erro ao buscar transações',
      phoneNotFind: 'Telemóvel não encontrado',
      filterNoResult: 'Nenhuma transação encontrada para o filtro informado',
      informPeriod: 'Informe um período',
      periodRange: 'Personalizado',
      status: 'Status',
      type: 'Tipo',
      period: 'Período',
      lastTransactions: 'Últimas transações',
      filteredTransactions: 'Transações filtradas',
    },
    businessPage: {
      addBusiness: 'Nova Empresa',
      manageBusiness: 'Gerir Empresa',
      viewBusiness: 'Ver Empresa',
      inactivateBusiness: 'Desativar Empresa',
      registeredBusiness: 'Empresas Cadastradas',
      mainAccountData: 'Dados da Conta Principal',
      fantasyName: 'Nome Fantasia',
      legalName: 'Nome',
      legalCode: 'CNPJ',
      owner: 'Dono',
      city: 'Cidade',
      province: 'Província',
      employees: 'Funcionários',
      position: 'Cargo',
      noChangesToSave: 'Não há alterações para gravar',
      savingSuccess: 'Alterações salvas com sucesso',
      savingError: 'Erro ao gravar os dados da empresa',
      noEmployees: 'Não há funcionários cadastrados',
      name: 'Nome',
      lastName: 'Sobrenome',
      mobile: 'Telemóvel',
      mobileVerified: 'Telemóvel verificado',
      acceptPayment: 'Aceita pagamentos',
      employeeBenefit: 'Benefícios para funcionários',
      offersChange: 'Troco fácil',
      businessNif: 'NIF',
      businessData: 'Dados da empresa',
      accounts: 'Contas',
      noAccounts: 'Não há contas cadastradas',
      newAccount: 'Nova conta',
      employeeBenefits: 'Enviar benefícios para funcionários',
      employeeBenefitsShort: 'Benefícios',
      newEmployee: 'Novo funcionário',
      newEmployeeShort: 'Novo',
      searchEmployee: 'Buscar funcionário',
      importEmployeesWithFile:
        'Importar funcionários a partir de um ficheiro Excel',
      importEmployeesWithFileShort: 'Importar com ficheiro Excel',
      mobileAlreadyRegistered: 'Já uma conta registrada com esse telemóvel',
      nifAlreadyRegistered: 'Já há uma empresa cadastrada com esse NIF',
      businessNoFunction:
        'Informe pelo menos uma função que será usada pela empresa',
      employeeAddedSuccess: 'Funcionário adicionado com sucesso',
      employeeAddedError: 'Erro ao adicionar funcionário',
      employeeImportSuccess: 'Funcionário(s) importado(s) com sucesso',
      employeeImportError: 'Erro ao importar funcionário(s)',
      removeEmployee: 'Remover funcionário',
      nifWrongPattern: 'NIF com formato inválido',
      nifRequired: 'NIF obrigatório',
      nifMaxLength: 'O NIF tem no máximo 10 dígitos',
      businessNameRequired: 'Nome da empresa obrigatório',
      mobileWrongPattern: 'Telemóvel fora do padrão',
      benefit: 'Benefícios',
      applyToAll: 'Aplicar para todos',
      makeBenefit: 'Efetivar',
      account: 'Conta',
      employee: 'Funcionário',
      benefitValue: 'Valor do Benefício',
      employeeRemovedSuccess: 'Funcionário removido com sucesso',
      employeeRemoveError: 'Erro ao tentar remover funcionário',
      selectFile: 'Escolher ficheiro',
      invalidFile: 'Ficheiro inválido',
      importEmployeesResult: 'Resultado da importação',
      valueRequirePIN: 'Valor máximo para transações sem PIN',
      reactivateBusiness: 'Ativar empresa',
      reactivateBusinessSuccess: 'Empresa ativada com sucesso',
      reactivateBusinessError: 'Erro ao tentar ativar a empresa',
      inactivateBusinessSuccess: 'Empresa desativada com sucesso',
      inactivateBusinessError: 'Erro ao tentar desativar a empresa',
    },
    recoveryPage: {
      verificationCode: 'Código de recuperação',
      informVerificationCode:
        'Para continuar, por favor, informe o código de verificação enviado para o seu email',
      verificationCodeRequired: 'O código de verificação é obrigatório',
      newPasswordRequired: 'A nova senha é obrigatória',
      passwordMinSize: 'A senha deve ter pelo menos 6 caracteres',
      passwordsMustMatch: 'As senhas informadas são diferentes',
      passwordChangedSuccess: 'Senha alterada com sucesso',
      passwordRemembered: 'Lembrei a senha, voltar',
    },
    permissions: {
      attendant: 'Atendente',
      permission: 'Permissões',
      recharge: 'Recargas',
      view: 'Visualizar',
      make: 'Efetuar',
      historic: 'Ver Histórico',
      client: 'Clientes',
      edit: 'Editar',
      password: 'Trocar Senha',
      block: 'Bloquear',
      companies: 'Empresas',
      create: 'Criar',
      transactions: 'Transações',
      employees: 'Administrar Funcionários',
    },
    searchInput: {
      informEmailOrName: 'Email ou nome',
      find: 'Buscar',
      clearFilter: 'Limpar filtro',
    },
    businessStatusConfirm: {
      notesRequired: 'É obrigatório informar o motivo',
    },
    audit: {
      Insert: 'Inserção',
      Delete: 'Exclusão',
      Update: 'Alteração',
      'Failed Login': 'Falha no login',
      Login: 'Login',
      'Failed Transaction': 'Falha na transação',
      'Authorized Transaction': 'Transação autorizada',
      'Invalid PIN': 'PIN incorreto',
      Any: 'Qualquer',
      Account: 'Conta',
      BackOfficeUser: 'Utilizador',
      Business: 'Empresa',
      'Business Block': 'Bloqueio Empresa',
      BusinessAccount: 'Conta Empresa',
      BusinessEmployee: 'Funcionários',
      Device: 'Aparelho telemóvel',
      Person: 'Pessoa',
      sysUser: 'Usuário',
      Transaction: 'Transação',
      TransactionSettings: 'Opções de transação',
      auditTopic: 'Tabela',
      auditAction: 'Operação',
    },
    permissionsPage: {
      permissionsRules: 'Permissões - ',
      profiles: 'Grupos',
      profileTitle: 'Grupo - ',
      rules: 'Regras',
      belongingUsers: 'Utilizadores pertencentes',
      noUsers: 'Não possui utilizadores',
      save: 'Gravar',
      cancel: 'Cancelar',
      selectAUser: 'Selecione um utilizador',
      create: 'Criar',
      newProfileType: 'Novo Grupo',
      name: 'Nome',
      noTables: 'Não possui telas',
      errorSaving: 'Erro ao gravar permissões',
      successSaving: 'Permissões salvas com sucesso',
      'System groups cannot be deleted':
        'Grupos do sistema não podem ser excluídos',
      'System Groups cannot be changed':
        'Grupos do sistema não podem ser alterados',
      confirmationDeleteGroup: 'Tem certeza que deseja excluir o grupo',
      successDeleteGroup: 'Grupo excluído com sucesso',
      errorDeleteGroup: 'Erro ao excluir grupo',
      groupNameTooShort: 'O nome do grupo deve ter pelo menos 3 caracteres',
      groupNameRequired: 'O nome do grupo é obrigatório',
      groupCreatedSuccessfully: 'Grupo criado com sucesso',
      groupCreationFailed: 'Erro ao criar grupo',
      addUser: 'Adicionar Novos Utilizadores ao Grupo',
      noUserSelected: 'Nenhum utilizador selecionado',
      successAddUser: 'Utilizador(es) adicionado(s) com sucesso',
      errorAddUser: 'Erro ao adicionar utilizador(es)',
      groupChangesSaveError: 'Erro ao gravar alterações do grupo',
      groupChangesSaveSuccess: 'Alterações do grupo gravadas com sucesso',
    },
  },
};
