import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.userMenuBackground};
  gap: 10px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: ${({ open }) => (open ? '30px' : '50px')};
  border-bottom-right-radius: ${({ open }) => (open ? '30px' : '50px')};
  border: none;
  width: 250px;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s;
  padding-bottom: ${({ open }) => (open ? '8px' : '-8px')};

  :hover {
    cursor: pointer;
  }

  @media (max-width: 800px) {
    width: fit-content;
  }
`;

export const MenuOptions = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  max-width: 210px;
  gap: 10px;
`;

export const MenuButton = styled(motion.button)`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  border: none;
  color: ${({ color, theme }) => color || theme.colors.text};
  font-size: 0.9rem;
  padding: 5px 0;
  gap: 5px;
`;

export const UserButton = styled(motion.div)`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.boxBackground};
  gap: 10px;
  padding: 5px 10px;
  border-radius: 50px;
  border: none;
  width: 250px;

  transition: 0.3s;

  @media (max-width: 800px) {
    width: fit-content;
    padding: 5px 10px;
  }
`;

export const UserDataLabel = styled.div`
  display: inline-flex;
  flex-direction: column;

  transition: 0.3s;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const UsernameSpan = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.text};
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
`;

export const UserOfficeSpan = styled.span`
  font-size: 0.65rem;
  color: ${({ theme }) => theme.colors.accent};
  user-select: none;
`;

export const DownArrow = styled(motion.span)`
  color: ${({ theme }) => theme.colors.text};
  margin-left: 10px;

  @media (max-width: 800px) {
    font-size: 0.75rem;
  }
`;

export const ThemeIndicator = styled.i`
  color: ${({ $themeSelected }) =>
    $themeSelected === 'dark' ? 'yellow' : '#bad1e4'};
  font-size: 1.1rem;
`;

export const DesktopOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  gap: 8px;
  padding: 8px;
  color: ${({ color, theme }) => color || theme.colors.text};
  font-size: 0.9rem;

  @media (max-width: 800px) {
    display: none;
  }
`;
export const MobileOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  user-select: none;
  padding: 8px;
  height: 30px;

  i {
    font-size: 0.7rem;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;
