import { Indicator, Item, Root } from '@radix-ui/react-radio-group';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${({ theme }) => theme.colors.text};
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.background};
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.lineColor};
  border-radius: 8px;
  padding: 5px;

  @media (max-width: 1000px) {
    font-size: 0.7rem;
  }

  &:disabled {
    border: ${({ theme }) => `1px dotted ${theme.colors.lineColor}`};
  }
`;

export const DateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px 5px;
  margin-top: 7.5px;
  gap: 5px;
  border-top: 1px solid ${({ theme }) => theme.colors.lineColor};
`;

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5;

  > label {
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.disabledText : theme.colors.text};
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.9rem;
  user-select: none;

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const RadioGroup = styled(Root)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5px;
  padding: 0 10px;
`;

export const RadioOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RadioIndicator = styled(Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &:after {
    content: '""';
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.buttonText};
  }
`;

export const RadioItem = styled(Item)`
  border: none;
  background-color: ${({ theme }) => theme.colors.text};
  width: 18px;
  height: 18px;
  border-radius: 100%;
  box-shadow: 0 2px 10px ${({ theme }) => theme.colors.background};
  &:hover {
    background-color: ${({ theme }) => theme.colors.lineColor};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.background};
  }
`;
