import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import { func } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useBusinessEmployeeImportFromXlsMutation } from 'store/slices/apiSlice/endpoints/business';
import {
  ButtonsContainer,
  Container,
  FormButton,
  ImportButton,
  WarningText,
} from './styles';

const ImportWarningDialog = ({ onClose }) => {
  const { currentBusinessSelected } = useBusiness();
  const [importResult, setImportResult] = useState(null);
  const [businessEmployeeImportFromXls, { isLoading }] =
    useBusinessEmployeeImportFromXlsMutation();
  const { t } = useTranslation();

  const handleImport = async (e) => {
    const fileFormat = e?.target?.files[0]?.name?.split('.')?.pop();

    if (!fileFormat?.includes('xls')) {
      toast.error(t('businessPage.invalidFile'));
      return;
    }

    const fileData = new FormData();
    fileData.append('BusinessId', currentBusinessSelected.BusinessId);
    fileData.append('File', e.target.files[0]);

    try {
      const importRes = await businessEmployeeImportFromXls({
        businessId: currentBusinessSelected.BusinessId,
        xlsFile: fileData,
      }).unwrap();

      setImportResult(importRes);
    } catch (e) {
      toast.error(e?.data.ErrorMsg || 'Erro no ficheiro de importação');
    }
  };

  const getMobileListText = (mobileList) =>
    mobileList.reduce(
      (prev, curr, index) =>
        index < mobileList.length - 1 ? `${prev}${curr}, ` : `${prev}${curr}.`,
      '',
    );

  const handleClose = () => {
    setImportResult(null);
    onClose();
  };

  return (
    <Dialog
      title={t(
        `businessPage.${
          importResult
            ? 'importEmployeesResult'
            : 'importEmployeesWithFileShort'
        }`,
      )}
    >
      <Container>
        <LoadIndicator visible={isLoading} message="Aguarde..." />
        <WarningText>
          {importResult ? (
            <>
              {importResult?.NotFound?.length ? (
                <p>
                  {`Telemóveis não encontrados: ${getMobileListText(
                    importResult?.NotFound,
                  )}`}
                </p>
              ) : (
                ''
              )}
              {importResult?.AlreadyRegistered?.length ? (
                <p>
                  {`Telemóveis de funcionários já registrados: ${getMobileListText(
                    importResult?.AlreadyRegistered,
                  )}`}
                </p>
              ) : (
                ''
              )}
              <p>
                {!importResult?.AlreadyRegistered?.length &&
                !importResult?.NotFound?.length
                  ? `Telemóveis importados com sucesso.`
                  : 'Os demais telemóveis foram importados com sucesso.'}
              </p>
            </>
          ) : (
            <p>
              Apenas os utentes que já tem conta no dindim serão importados como
              funcionários da empresa.
            </p>
          )}
        </WarningText>
      </Container>
      <ButtonsContainer>
        {!importResult && (
          <label htmlFor="xlsFile">
            <ImportButton
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('businessPage.selectFile')}
            </ImportButton>
            <input
              type="file"
              id="xlsFile"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleImport}
            />
          </label>
        )}
        <FormButton $isCancel={!importResult} onClick={handleClose}>
          {t(`generalWords.${importResult ? 'ok' : 'cancel'}`)}
        </FormButton>
      </ButtonsContainer>
    </Dialog>
  );
};

ImportWarningDialog.propTypes = {
  onClose: func.isRequired,
};

export default ImportWarningDialog;
