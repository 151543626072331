import { FadedContainer } from 'components/FadedContainer';
import React from 'react';
import TransactionFilter from './components/TransactionFilter';
import { TransactionsSection } from './components/TransactionsSection';
import { useTransaction } from './contexts/TransactionContext';
import { Container } from './styles';

export function TransactionsPage() {
  const { clientTransactionData } = useTransaction();
  return (
    <FadedContainer>
      <Container>
        {clientTransactionData?.client.AccountId ? (
          <TransactionsSection />
        ) : (
          <TransactionFilter />
        )}
      </Container>
    </FadedContainer>
  );
}
