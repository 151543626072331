import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-height: 100%;
  gap: 20px;
  transition: 0.3s;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  height: 40px;
  align-items: center;

  @media (max-width: 1000px) {
    justify-content: space-between;
  }
`;

export const AddBackOfficeUserButton = styled(Button)`
  width: 60px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.text};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ theme }) => theme.colors.accent};

  i {
    color: ${({ theme }) => theme.colors.buttonText};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const WrapperList = styled.div`
  gap: 8px;
  flex-direction: column;
  display: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? 'flex' : $isExpanded ? 'flex' : 'none'};
  width: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? '30%' : $isExpanded ? '100%' : '0%'};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.boxBackground};
  border-radius: 15px;
  padding: 12px;
  max-height: 100%;
  transition: 0.3s;
`;

export const WrapperContent = styled.div`
  display: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? 'flex' : $isExpanded ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  flex: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? 1 : $isExpanded ? 1 : 0};
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.boxBackground};
  color: ${({ theme }) => theme.colors.text};
  flex-direction: column;
  padding: 12px;
  transition: 0.3s;
`;
