import styled from 'styled-components';
import { Button } from '../Button';

export const BaseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: none;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 15px;
  font-size: 0.75rem;
  border-radius: 50px;
  height: ${({ height }) => height};
`;
