import * as Accordion from '@radix-ui/react-accordion';
import styled from 'styled-components';

export const StyledAccordionRoot = styled(Accordion.Root)`
  background-color: ${({ theme }) => theme.colors.grid.background};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 4px;
  max-height: 100%;
  width: 100%;
`;

export const StyledAccordionItem = styled(Accordion.Item)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grid.background};
  :first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const StyledAccordionHeader = styled(Accordion.Header)`
  background-color: ${({ theme }) => theme.colors.grid.header};
  padding: 10px 15px;
`;

export const StyledAccordionTrigger = styled(Accordion.Trigger)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  gap: 30px;
  border: none;
  width: 100%;
`;

export const StyledAccordionContent = styled(Accordion.Content)`
  padding: 5px 15px;
`;

export const TriggerIcon = styled.i`
  transition: transform 300ms cubic-bezier(0.87 0, 0.13, 1);
  [data-state='open'] {
    transform: rotate(180deg);
  }
`;
