import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import useWindowDimension from 'hooks/useWindowDimension';
import { t } from 'i18next';
import { func } from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { usePostSysGroupMutation } from 'store/slices/apiSlice/endpoints/permission';
import { FooterButtons, InputDesc, OptionButton } from './styles';

function NewProfileTypeDialog({ onClose }) {
  const [groupName, setGroupName] = useState('');
  const { dimensions } = useWindowDimension();
  const [postSysGroup, { isLoading }] = usePostSysGroupMutation();

  const handleSaveNewGroup = async () => {
    if (!groupName) {
      toast.error(t('permissionsPage.groupNameRequired'));
      return;
    }
    if (groupName.length <= 3) {
      toast.error(t('permissionsPage.groupNameTooShort'));
      return;
    }

    const groupNameFormatted =
      groupName.charAt(0).toUpperCase() + groupName.slice(1);

    try {
      const res = await postSysGroup({ GroupName: groupNameFormatted });
      if (res.ErrorMsg) {
        toast.error(res.ErrorMsg);
        return;
      }
      toast.success(t('permissionsPage.groupCreatedSuccessfully'));
      onClose();
    } catch (e) {
      toast.error(
        e?.data?.ErrorMsg ||
          e?.message ||
          t('permissionsPage.groupCreationFailed'),
      );
    }
  };

  return (
    <Dialog
      width={dimensions.width > 520 ? '500px' : '98%'}
      height="fit-content"
      title={t('permissionsPage.newProfileType')}
      onClose={onClose}
      closeOnOutsideClick
      showCloseButton
    >
      <LoadIndicator visible={isLoading} />
      <InputDesc
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        placeholder={t('permissionsPage.name')}
      />
      <FooterButtons>
        <OptionButton onClick={handleSaveNewGroup} color="green">
          {t('permissionsPage.create')}
        </OptionButton>
        <OptionButton onClick={onClose}>
          {t('permissionsPage.cancel')}
        </OptionButton>
      </FooterButtons>
    </Dialog>
  );
}

NewProfileTypeDialog.propTypes = {
  onClose: func.isRequired,
};

export default NewProfileTypeDialog;
