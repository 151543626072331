import { useJWT } from 'hooks/useJWT';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginForm } from './components/LoginForm';
import { MainContainer } from './styles';

export function LoginPage() {
  const { userIsSigned } = useSelector(({ auth }) => auth);
  const { isJWTExpired } = useJWT();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/' && userIsSigned && !isJWTExpired) {
      navigate('/home');
    }
  }, [pathname]);

  return (
    <MainContainer>
      <LoginForm />
    </MainContainer>
  );
}
