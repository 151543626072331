import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import styled, { keyframes } from 'styled-components';

const contentShow = keyframes`
0% {
  opacity: 0;
  transform: translate(-50%, -48%) scale(.96);
}
100% {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}`;

const overlayShow = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}`;

export const AlertDialogRoot = styled(AlertDialogPrimitive.Root)``;

export const AlertOverlay = styled(AlertDialogPrimitive.Overlay)`
  background-color: ${({ theme }) => theme.colors.overlay};
  position: 'fixed';
  inset: 0;
  width: 100%;
  height: 100%;
  animation: ${overlayShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const AlertContent = styled(AlertDialogPrimitive.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  width: 80vw;
  max-width: 400px;
  max-height: 85vh;
  padding: 15px;
  gap: 15px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  animation: ${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }
`;

export const AlertTitle = styled(AlertDialogPrimitive.Title)`
  margin: 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1rem;
  font-weight: 500;
`;

export const AlertDescription = styled(AlertDialogPrimitive.Description)`
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.9rem;
  line-height: 1.5;
`;

export const AlertConfirm = styled(AlertDialogPrimitive.Action)``;

export const AlertCancel = styled(AlertDialogPrimitive.Cancel)``;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
`;
