import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  padding: 5px;
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.lineColor};
  min-height: 100%;
`;

export const NoDataWarning = styled.span`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineColor};
  margin-bottom: 10px;
`;

export const AttributeDisplay = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
`;

export const AttributeLabel = styled.span`
  width: 100%;
  text-align: start;
  font-weight: 500;
  color: ${({ theme, $isDifferent }) =>
    $isDifferent ? theme.colors.error : theme.colors.text};
  font-size: 0.7rem;
`;

export const AttributeValue = styled.span`
  width: 100%;
  text-align: start;
  color: ${({ theme, $isDifferent }) =>
    $isDifferent ? theme.colors.error : theme.colors.text};
  font-size: 0.7rem;
`;
