import { object } from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  useAccountStatusListQuery,
  useGetAccountTypeListQuery,
} from 'store/slices/apiSlice/endpoints/account';
import {
  useTransactionStatusListQuery,
  useTransactionTypeListQuery,
} from 'store/slices/apiSlice/endpoints/transaction';
import { useGetUserTypeListQuery } from 'store/slices/apiSlice/endpoints/user';
import { currencyFormatter, dateTimeFormatter } from 'utils/formatters';
import {
  AttributeDisplay,
  AttributeLabel,
  AttributeValue,
  Container,
  Detail,
  NoDataWarning,
  Title,
} from './styles';

export function ComparisonRender({ after, before }) {
  const { timeZone } = useSelector(({ appOptions }) => appOptions);
  const { data: transactionStatusList } = useTransactionStatusListQuery(
    undefined,
    {
      refetchOnFocus: false,
      refetchOnReconnect: false,
    },
  );
  const { data: transactionTypeList } = useTransactionTypeListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnReconnect: false,
  });
  const { data: accountStatusList } = useAccountStatusListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });
  const { data: userTypeList } = useGetUserTypeListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });
  const { data: accountTypeList } = useGetAccountTypeListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });

  const getValue = (attributeName, value) => {
    if (!value) {
      return value;
    }

    if (
      attributeName?.toLowerCase() === 'pin' ||
      attributeName?.toLowerCase() === 'authorizepin' ||
      attributeName?.toLowerCase() === 'password'
    ) {
      return '****';
    }

    if (
      attributeName?.toLowerCase().includes('date') ||
      attributeName?.toLowerCase().includes('createdon') ||
      attributeName?.toLowerCase().includes('blockeduntil') ||
      attributeName?.toLowerCase().includes('verifiedon')
    ) {
      return dateTimeFormatter({ value }, timeZone);
    }

    if (
      attributeName?.toLowerCase().includes('amount') ||
      attributeName?.toLowerCase().includes('valuerequiredpin') ||
      attributeName?.toLowerCase().includes('balance')
    ) {
      return currencyFormatter({ value });
    }

    if (
      attributeName?.toLowerCase().includes('acceptpayment') ||
      attributeName?.toLowerCase().includes('acceptchange') ||
      attributeName?.toLowerCase().includes('isblocked') ||
      attributeName?.toLowerCase().includes('active') ||
      attributeName?.toLowerCase().includes('employeebenefit')
    ) {
      return (value === '1').toString();
    }

    if (attributeName?.toLowerCase() === 'accounttypeid') {
      return accountTypeList?.find(
        (type) => type.AccountTypeId === Number(value),
      )?.AccountTypeDesc;
    }

    if (attributeName?.toLowerCase() === 'usertypeid') {
      return userTypeList?.find((type) => type.UserTypeId === Number(value))
        ?.UserTypeDesc;
    }

    if (attributeName?.toLowerCase().includes('transactiontypeid')) {
      return transactionTypeList?.find(
        (type) => type.TransactionTypeId === Number(value),
      )?.TransactionTypeDesc;
    }

    if (attributeName?.toLowerCase().includes('transactionstatusid')) {
      return transactionStatusList?.find(
        (status) => status.TransactionStatusId === Number(value),
      )?.TransactionStatusDesc;
    }

    if (attributeName?.toLowerCase().includes('accountstatusid')) {
      return accountStatusList?.find(
        (status) => status.AccountStatusId === Number(value),
      )?.AccountStatusDesc;
    }

    return value;
  };

  const showAttributesFields = useCallback(
    (object) =>
      Object?.entries(object)?.map((entry) => (
        <AttributeDisplay key={entry[0]}>
          <AttributeLabel
            $isDifferent={
              !after && !!before
                ? true
                : !!after && !before
                ? true
                : after[entry[0]] !== before[entry[0]]
            }
          >
            {entry[0]}
          </AttributeLabel>
          <AttributeValue
            $isDifferent={
              !after && !!before
                ? true
                : !!after && !before
                ? true
                : after[entry[0]] !== before[entry[0]]
            }
          >
            {getValue(entry[0], entry[1])}
          </AttributeValue>
        </AttributeDisplay>
      )),
    [],
  );
  return (
    <Container>
      <Detail>
        <Title>Antes</Title>
        {before ? (
          showAttributesFields(before)
        ) : (
          <NoDataWarning>Sem alteração</NoDataWarning>
        )}
      </Detail>
      <Detail>
        <Title>Depois</Title>
        {after ? (
          showAttributesFields(after)
        ) : (
          <NoDataWarning>Sem alteração</NoDataWarning>
        )}
      </Detail>
    </Container>
  );
}

ComparisonRender.propTypes = {
  after: object,
  before: object,
};

ComparisonRender.defaultProps = {
  after: {},
  before: {},
};
