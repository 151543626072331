import { Button } from 'components/Button';
import { Input } from 'components/Input';
import styled from 'styled-components';

export const DialogButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;

  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  width: 100%;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;

  > span {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  width: 100%;
  gap: 20px;
`;

export const PasswordInput = styled(Input)`
  background-color: transparent;
  border: none;
  text-align: center;
  font-size: 1.2rem !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineColor};

  color: ${({ theme }) => theme.colors.accent};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
`;
