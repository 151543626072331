const darkTheme = {
  colors: {
    accent: '#81c242',
    primary: '#020E45',
    background: '#151718',
    iconLogoTransparent: 'rgba(255,255,255, 0)',
    iconLogoVisible: 'rgba(255, 255, 255, 1)',
    text: '#ffffff',
    buttonText: '#000000',
    boxBackground: '#1e1f23',
    error: '#c24242',
    warning: '#c2ad41',
    disabled: '#ddd',
    disabledText: '#afafaf',
    tooltipBackground: '#0A0A0A',
    tooltipText: '#ddd',
    navigationText: '#ddd',
    avatarBackground: '#000000',
    userMenuBackground: '#000000',
    searchInputBackground: '#8e8e8e',
    lineColor: '#68686e',
    overlay: 'rgba(0, 0, 0, 0.5)',
    fullBlack: '#000000',
    backButton: '#2b2d33',
    grid: {
      header: '#242424',
      background: '#181D1F',
      rowHover: 'rgba(129, 194, 66, 0.06)',
      selectedRow: 'rgba(129, 194, 66, 0.3)',
      highLight: '#161616',
    },
    devDrawerBkg: '#b27300',
  },
};

const lightTheme = {
  colors: {
    accent: '#4e7527',
    primary: '#020E45',
    background: '#FCFCFC',
    iconLogoTransparent: 'rgba(2, 14, 96, 0)',
    iconLogoVisible: 'rgba(2, 14, 69, 1)',
    text: '#575757',
    buttonText: '#ffffff',
    boxBackground: '#D7D9DE',
    error: '#c24242',
    warning: '#c2ad41',
    disabled: '#ddd',
    disabledText: '#575757',
    tooltipBackground: '#F2F3F5',
    tooltipText: '#020E45',
    navigationText: '#020E45',
    avatarBackground: '#F2F3F5',
    userMenuBackground: '#B4B7C1',
    searchInputBackground: '#ededed',
    lineColor: '#AAA',
    overlay: 'rgba(0, 0, 0, 0.5)',
    fullBlack: '#FFFFFF',
    backButton: '#FFFFFF',
    grid: {
      header: '#F2F3F5',
      background: '#FFFFFF',
      rowHover: '#B9BED080',
      selectedRow: '#B9BED0',
      highLight: '#F2F3F5',
    },
    devDrawerBkg: '#ffa500',
  },
};

export { darkTheme, lightTheme };
