/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

const appOptionsSlice = createSlice({
  name: '@appOptions',
  // country: 1 - Angola; 2 - Portugal; 3 - Brazil
  initialState: {
    countryId: 1,
    timeZone: null,
    theme: 'dark',
  },
  reducers: {
    appOptionsSetTimezone: (draft) => {
      draft.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    appOptionsToggleTheme: (draft) => {
      draft.theme = draft.theme === 'dark' ? 'light' : 'dark';
    },
    updateCountryId: (draft, { payload }) => {
      draft.countryId = payload;
    },
  },
});

export const { appOptionsSetTimezone, appOptionsToggleTheme, updateCountryId } =
  appOptionsSlice.actions;
export const appOptionsReducer = appOptionsSlice.reducer;
