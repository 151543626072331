import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;

  @media (max-width: 1000px) {
    gap: 10px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-self: flex-start;
  padding: 5px 5px 3px;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 30px; */
  width: 100%;
  overflow-y: auto;
`;
