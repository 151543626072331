import {
  CurrentOptionButton,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from 'components/DropdownMenu';
import { useAudit } from 'pages/AuditPage/contexts/AuditContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAuditActionListQuery } from 'store/slices/apiSlice/endpoints/audit';
import { Container } from './styles';

function ActionDropdownMenu() {
  const { t } = useTranslation();
  const { auditFilter, setAuditFilter } = useAudit();
  const [menuOptions, setMenuOptions] = useState([]);
  const { data: auditActionList, isSuccess } = useGetAuditActionListQuery(
    undefined,
    {
      refetchOnFocus: false,
      refetchOnReconnect: false,
    },
  );

  const handleOptionClick = (optionId) => {
    setAuditFilter((prevState) => ({
      ...prevState,
      ActionId: optionId,
    }));
  };

  useEffect(() => {
    setMenuOptions(
      auditActionList?.map((action) => ({
        id: action.ActionId,
        itemDisplay: t(`audit.${action.ActionDesc}`),
        currentDisplay: (
          <CurrentOptionButton $readOnly={false}>
            <span>{t(`audit.${action.ActionDesc}`)}</span>
            <i className="fas fa-chevron-down" />
          </CurrentOptionButton>
        ),
      })),
    );
  }, [auditActionList]);

  return (
    isSuccess && (
      <Container>
        <span>{t('audit.auditAction')}</span>
        <DropdownMenuRoot>
          <DropdownMenuTrigger asChild>
            {
              menuOptions?.find(
                (auditAction) => auditAction.id === auditFilter.ActionId,
              )?.currentDisplay
            }
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuRadioGroup>
              {menuOptions?.map((option) => (
                <DropdownMenuRadioItem
                  key={option.id}
                  onClick={() => handleOptionClick(option.id)}
                >
                  <span>{option.itemDisplay}</span>
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenuRoot>
      </Container>
    )
  );
}

export default ActionDropdownMenu;
