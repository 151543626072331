import { node, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ActiveLink } from '../ActiveLink';
import { Button, Title } from './styles';

export function NavButton({
  iconName,
  iconComponent,
  routeName,
  to,
  ...props
}) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <ActiveLink to={to} {...props}>
      <Button>
        {iconName && <i className={iconName} />}
        {iconComponent && !iconName && iconComponent}
        <Title
          whileHover={{ scale: to === pathname ? 1 : 1.05 }}
          whileTap={{ scale: to === pathname ? 1 : 0.95 }}
        >
          {t(routeName)}
        </Title>
      </Button>
    </ActiveLink>
  );
}

NavButton.propTypes = {
  iconName: string,
  iconComponent: node,
  routeName: string.isRequired,
  to: string.isRequired,
};

NavButton.defaultProps = {
  iconName: '',
  iconComponent: null,
};
