import { AlertDialog } from 'components/AlertDialog';
import { func, node, string } from 'prop-types';
import React, { useMemo } from 'react';
import { DialogButton } from './styles';

export function ConfirmationDialog({
  message,
  triggerButton,
  confirmFn,
  cancelFn,
}) {
  const dialogConfirmButton = useMemo(
    () => <DialogButton onClick={confirmFn}>OK</DialogButton>,
    [confirmFn],
  );
  const dialogCancelButton = useMemo(() => (
    <DialogButton onClick={cancelFn} $isCancel>
      Cancelar
    </DialogButton>
  ));
  return (
    <AlertDialog
      title="Atenção!"
      confirmButton={dialogConfirmButton}
      cancelButton={dialogCancelButton}
      description={message}
      triggerButton={triggerButton}
    />
  );
}

ConfirmationDialog.propTypes = {
  message: string.isRequired,
  triggerButton: node.isRequired,
  confirmFn: func,
  cancelFn: func,
};

ConfirmationDialog.defaultProps = {
  confirmFn: () => {},
  cancelFn: () => {},
};
