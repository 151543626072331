import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const ProfileContext = createContext();

function ProfileProvider({ children }) {
  const [profileDialogVisible, setProfileDialogVisible] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState({
    file: null,
    url: '',
  });
  const [imageHasChanged, setImageHasChanged] = useState(false);

  const profileContextValues = useMemo(
    () => ({
      profileDialogVisible,
      setProfileDialogVisible,
      avatarPreview,
      setAvatarPreview,
      imageHasChanged,
      setImageHasChanged,
    }),
    [profileDialogVisible, avatarPreview, imageHasChanged],
  );

  return (
    <ProfileContext.Provider value={profileContextValues}>
      {children}
    </ProfileContext.Provider>
  );
}

ProfileProvider.propTypes = {
  children: node.isRequired,
};

const useProfile = () => useContext(ProfileContext);

export { ProfileProvider, useProfile };
