import appConfig from 'appConfig';
import { dindimIcon, dindimIconDark } from 'assets';
import { AppLogo } from 'components/AppLogo';
import useWindowDimension from 'hooks/useWindowDimension';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetMenuListByUserQuery } from 'store/slices/apiSlice/endpoints/permission';
import { NavButton } from './components/NavButton';
import { DrawerRoot, HeaderSection, LogoImage } from './styles';

export function Drawer() {
  const { dimensions } = useWindowDimension();
  const { theme: currentTheme } = useSelector(({ appOptions }) => appOptions);
  const { userData } = useSelector(({ auth }) => auth);

  const { data: menuList } = useGetMenuListByUserQuery(userData, {
    refetchOnMountOrArgChange: true,
    skip: !userData,
  });

  return (
    <DrawerRoot $isDev={appConfig.isDev}>
      <HeaderSection>
        {dimensions?.width > 800 ? (
          <AppLogo />
        ) : (
          <LogoImage
            src={currentTheme === 'dark' ? dindimIcon : dindimIconDark}
            alt="dindim icon"
          />
        )}
      </HeaderSection>
      {menuList?.length &&
        menuList
          ?.filter((route) => route.AccessControl)
          .map((route) => (
            <NavButton
              key={route.id}
              routeName={route.name}
              iconName={route.Icon}
              to={`/${route?.Url}`}
            />
          ))}
    </DrawerRoot>
  );
}
