import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    forgottenPassword: builder.mutation({
      query: (email) => ({
        url: `/BackOfficeUser/SendRecoveryCode`,
        method: 'POST',
        body: { Email: email },
      }),
      invalidatesTags: [
        { type: 'Audit', id: 'sysUser' },
        { type: 'Audit', id: 'Account' },
      ],
    }),
    changeForgottenPassword: builder.mutation({
      query: (recoveryData) => ({
        url: '/BackOfficeUser/RecoverPassword',
        method: 'POST',
        body: recoveryData,
      }),
      invalidatesTags: [
        { type: 'Audit', id: 'BackOfficeUser' },
        { type: 'Audit', id: 'Account' },
      ],
    }),
    changePassword: builder.mutation({
      query: (passwordData) => ({
        url: '/sysUser/ChangePassword',
        method: 'POST',
        body: passwordData,
      }),
      invalidatesTags: [
        { type: 'Audit', id: 'sysUser' },
        { type: 'Audit', id: 'Account' },
      ],
    }),
  }),
});

export const {
  useForgottenPasswordMutation,
  useChangeForgottenPasswordMutation,
  useChangePasswordMutation,
} = extendApi;
