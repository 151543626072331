import { Button } from 'components/Button';
import styled from 'styled-components';

export const WrapperGrid = styled.div`
  width: 100%;
  height: calc(100% - 92px);
  margin-bottom: 12px;
`;

export const OptionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  padding: 0 22px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.buttonText};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ color, theme }) =>
    color === 'green' ? theme.colors.accent : theme.colors.error};
  font-size: 0.75rem;

  i {
    color: ${({ theme }) => theme.colors.buttonText};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: fit-content;
  gap: 12px;
`;
