import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { node, string } from 'prop-types';
import React, { forwardRef } from 'react';
import {
  AlertCancel,
  AlertConfirm,
  AlertContent,
  AlertDescription,
  AlertDialogRoot,
  AlertOverlay,
  AlertTitle,
  ButtonsContainer,
} from './styles';

export const AlertDialog = forwardRef(
  (
    {
      triggerButton,
      title,
      description,
      confirmButton,
      cancelButton,
      ...props
    },
    ref,
  ) => (
    <AlertDialogRoot ref={ref} {...props}>
      <AlertDialogPrimitive.Trigger asChild>
        {triggerButton}
      </AlertDialogPrimitive.Trigger>
      <AlertDialogPrimitive.Portal>
        <AlertOverlay />
        <AlertContent>
          {title && <AlertTitle>{title}</AlertTitle>}
          <AlertDescription>{description}</AlertDescription>
          <ButtonsContainer>
            {cancelButton && <AlertCancel asChild>{cancelButton}</AlertCancel>}
            {confirmButton && (
              <AlertConfirm asChild>{confirmButton}</AlertConfirm>
            )}
          </ButtonsContainer>
        </AlertContent>
      </AlertDialogPrimitive.Portal>
    </AlertDialogRoot>
  ),
);

AlertDialog.propTypes = {
  triggerButton: node.isRequired,
  title: string,
  description: string.isRequired,
  confirmButton: node,
  cancelButton: node,
};

AlertDialog.defaultProps = {
  title: '',
  confirmButton: null,
  cancelButton: null,
};
