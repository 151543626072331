import * as SwitchPrimitive from '@radix-ui/react-switch';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  /* padding-top: 25px; */
  gap: 15px;
  height: 100%;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  color: white;
  font-size: 15;
  line-height: 1;
  user-select: none;
`;

export const Switch = styled(SwitchPrimitive.Root)`
  all: unset;
  width: 42px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.accent : theme.colors.primary};

  @media (max-width: 800px) {
    width: 32px;
    height: 18px;
  }
`;

export const SwitchThumb = styled(SwitchPrimitive.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px blue;
  transition: transform 100ms;
  transform: ${({ transform }) => transform};
  will-change: transform;
  transform: ${({ checked }) =>
    checked ? 'translateX(19px)' : 'translateX(2px)'};

  @media (max-width: 800px) {
    width: 14px;
    height: 14px;
    transform: ${({ checked }) =>
      checked ? 'translateX(16px)' : 'translateX(2px)'};
  }
`;
