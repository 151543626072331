import { any, string } from 'prop-types';
import React from 'react';

import { DataText, FieldText, WrapperField } from './styles';

function FieldData({ field, data }) {
  return (
    <WrapperField>
      <FieldText>{field}:</FieldText>
      <DataText>{data}</DataText>
    </WrapperField>
  );
}

export default FieldData;

FieldData.propTypes = {
  field: string,
  data: any,
};
FieldData.defaultProps = {
  field: '',
  data: '',
};
