import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const currencyFormatter = ({ value }) =>
  new Intl.NumberFormat('pt', { style: 'currency', currency: 'AON' })
    .format(
      value
        ?.toString()
        ?.replace(',', '.')
        ?.replace(/[A-Za-z!?]/g, ''),
    )
    .replace('AON', 'Kz');

export const currencyToFloat = (value) => {
  if (!value) {
    return 0.0;
  }
  const formattedValue = value
    ?.replace('Kz', '')
    ?.trim()
    ?.replaceAll('.', '')
    ?.replace(',', '.');
  return formattedValue;
};

export const dateTimeFormatter = ({ value }, timeZone) => {
  if (/^[/D]/.test(value)) {
    return value;
  }
  try {
    return timeZone
      ? format(utcToZonedTime(`${value}Z`, timeZone), 'dd/MM/yyyy - HH:mm', {
          timeZone,
        })
      : format(parseISO(value), 'dd/MM/yyyy - HH:mm');
  } catch {
    return value;
  }
};
