import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 20px;
  overflow: hidden;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: ${({ $dataInRow }) => ($dataInRow ? 'row' : 'column')};
  align-items: ${({ $dataInRow }) => ($dataInRow ? 'center' : 'flex-start')};
  justify-content: center;
  height: 100%;
  max-width: 90%;
  min-height: 90px;
  gap: 10px;
  overflow: hidden;
`;

export const UsernameSpan = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 500;
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const UserMobile = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 400;
  max-width: 100%;
  text-overflow: ellipsis;
`;
