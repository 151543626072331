import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserTypeList: builder.query({
      query: () => ({
        url: '/UserType',
        method: 'GET',
      }),
    }),
    userSave: builder.mutation({
      query: (userData) => ({
        url: '/User/Save',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: [
        { type: 'Audit', id: 'sysUser' },
        { type: 'Audit', id: 'Account' },
        { type: 'Audit', id: 'BackOfficeUser' },
      ],
    }),
    userChangePassword: builder.mutation({
      query: (passwordChangeData) => ({
        url: '/sysUser/ChangePassword',
        method: 'POST',
        body: {
          Mobile: passwordChangeData?.Email,
          ...passwordChangeData,
        },
      }),
      invalidatesTags: [
        { type: 'Audit', id: 'sysUser' },
        { type: 'Audit', id: 'Account' },
        { type: 'Audit', id: 'BackOfficeUser' },
      ],
    }),
  }),
});

export const {
  useUserLoginMutation,
  useUserSaveMutation,
  useGetUserTypeListQuery,
  useUserChangePasswordMutation,
} = extendApi;
