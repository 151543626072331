import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background-color: transparent;
  color: white;
  position: relative;

  @media (max-width: 800px) {
    align-items: flex-start;
    height: 40px;
    margin-bottom: 10px;
  }
`;

export const PageName = styled.span`
  font-size: 1.2rem;
  align-self: center;

  @media (min-width: 801px) {
    display: none;
  }
`;
