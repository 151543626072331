import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ bg }) => bg};
  border: none;
  padding: 2px;
  gap: 10px;
  width: 100%;
  margin-left: 10px;
  padding: 10px 30px;
  color: ${({ theme }) => theme.colors.navigationText};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: 0.3s;

  @media (max-width: 800px) {
    padding: 10px;
    margin-left: 5px;
  }
`;

export const Title = styled(motion.span)`
  color: ${({ theme }) => theme.colors.navigationText};
  transition: 0.3s;

  @media (max-width: 800px) {
    display: none;
  }
`;
