import { yupResolver } from '@hookform/resolvers/yup';
import appConfig from 'appConfig';
import AuditButton from 'components/AuditButton';
import AuditLogPopup from 'components/AuditLogPopup';
import { CheckBox } from 'components/CheckBox';
import { LoadIndicator } from 'components/LoadIndicator';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import { bool } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyGetAccountByMobileQuery } from 'store/slices/apiSlice/endpoints/account';
import { useGetAuditListQuery } from 'store/slices/apiSlice/endpoints/audit';
import {
  useBusinessCompleteQuery,
  useBusinessSaveCompleteMutation,
  useBusinessSaveMutation,
  useLazyBusinessByNifQuery,
} from 'store/slices/apiSlice/endpoints/business';
import { currencyFormatter, currencyToFloat } from 'utils/formatters';
import { currencyMask, phoneMask } from 'utils/masks';
import * as yup from 'yup';
import { phoneNumberType } from '../../../../../../utils/phoneNumberType';
import { FlagDropdownMenu } from './components/FlagDropdownMenu';
import {
  ButtonsContainer,
  Container,
  FieldsGroup,
  FormButton,
  Input,
  MobileInputContainer,
} from './styles';

export function BusinessForm() {
  const ACL = usePermissions();
  const [showAuditData, setShowAuditData] = useState(false);
  const [mobileCountry, setMobileCountry] = useState(1);
  const { t } = useTranslation();
  const {
    currentBusinessSelected,
    setCurrentBusinessSelected,
    setShowEmployeeTab,
  } = useBusiness();
  const {
    dimensions: { width },
  } = useWindowDimension();

  const {
    data: businessCompleteData,
    isLoading,
    refetch,
  } = useBusinessCompleteQuery(currentBusinessSelected?.BusinessId, {
    skip: !currentBusinessSelected?.BusinessId,
    refetchOnMountOrArgChange: true,
  });
  const [businessSaveComplete] = useBusinessSaveCompleteMutation();
  const [businessSave] = useBusinessSaveMutation();
  const [businessByNif] = useLazyBusinessByNifQuery();
  const [getAccountByMobile] = useLazyGetAccountByMobileQuery();
  const { data: auditData, isSuccess } = useGetAuditListQuery(
    { id: currentBusinessSelected?.BusinessId, topicDesc: 'Business' },
    {
      refetchOnMountOrArgChange: true,
      skip: !currentBusinessSelected?.BusinessId,
    },
  );
  const businessSchema = useMemo(
    () =>
      yup.object({
        NIF: yup
          .string()
          .required(t('businessPage.nifRequired'))
          .matches(/^\d+$/gm, { message: t('businessPage.nifWrongPattern') })
          .max(10, t('businessPage.nifMaxLength')),
        BusinessName: yup
          .string()
          .required(t('businessPage.businessNameRequired')),
        Mobile: yup
          .string()
          .required(t('subAccountsForm.mobileRequired'))
          .matches(phoneNumberType(mobileCountry), {
            message: t('businessPage.mobileWrongPattern'),
          }),
        ValueRequiredPIN: yup.string().required(),
        PIN: !currentBusinessSelected?.BusinessId
          ? yup
              .string()
              .required(t('subAccountsForm.pinRequired'))
              .length(4, t('subAccountsForm.pinSizeMustBeFour'))
          : undefined,
        PINConfirmation: !currentBusinessSelected?.BusinessId
          ? yup
              .string()
              .oneOf([yup.ref('PIN'), null], t('subAccountsForm.pinMustMatch'))
          : undefined,
      }),
    [currentBusinessSelected],
  );

  const { control, setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(businessSchema),
  });

  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (businessCompleteData?.Business) {
      Object.keys(businessCompleteData?.Business).forEach((keyName) => {
        if (keyName === 'ValueRequiredPIN') {
          setValue(
            keyName,
            currencyFormatter({
              value: businessCompleteData?.Business[keyName],
            }),
          );
          return;
        }
        setValue(keyName, businessCompleteData?.Business[keyName]);
      });
    }

    const masterAccount = businessCompleteData?.Accounts.find(
      (account) =>
        account.AccountId === businessCompleteData?.Business?.MainAccountId,
    );
    if (masterAccount) {
      Object.keys(masterAccount)
        .filter((keyName) => !keyName.toLowerCase().includes('id'))
        .forEach((keyName) => {
          if (keyName === 'Balance') {
            setValue(
              keyName,
              currencyFormatter({ value: masterAccount[keyName] }),
            );
          }
          if (keyName === 'Mobile') {
            setValue(
              keyName,
              `${phoneMask({
                value: masterAccount[keyName],
                countryCode:
                  masterAccount.MobileDDI === '55'
                    ? 3
                    : masterAccount.MobileDDI === '351'
                    ? 2
                    : 1,
              })}`,
            );
            setMobileCountry(
              masterAccount.MobileDDI === '55'
                ? 3
                : masterAccount.MobileDDI === '351'
                ? 2
                : 1,
            );
          }
        });
    }
  }, [businessCompleteData]);

  const handleCheckboxChange = (e, name, onChange) => {
    if (name === 'EmployeeBenefit') {
      setShowEmployeeTab(e);
    }
    onChange(e);
  };

  const getControlledCheckBox = (
    name,
    label,
    size = '100%',
    readOnly = false,
  ) => (
    <Controller
      control={control}
      name={name}
      defaultChecked={false}
      render={({ field: { onChange, ...fieldProps } }) => (
        <CheckBox
          id={name}
          label={label}
          readOnly={readOnly}
          disabled={readOnly}
          $width={size}
          onChange={(e) => {
            handleCheckboxChange(e, name, onChange);
          }}
          {...fieldProps}
        />
      )}
    />
  );

  const handleSaveNewBusiness = async (newBusiness) => {
    if (
      !newBusiness.AcceptPayment &&
      !newBusiness.AcceptChange &&
      !newBusiness.EmployeeBenefit
    ) {
      toast.error(t('businessPage.businessNoFunction'));
      return;
    }
    try {
      const checkMobile = await getAccountByMobile(newBusiness.Mobile).unwrap();
      if (checkMobile?.AccountId) {
        toast.error(t('businessPage.mobileAlreadyRegistered'));
        return;
      }

      const checkNif = await businessByNif(newBusiness.NIF).unwrap();
      if (checkNif?.NIF) {
        toast.error(t('businessPage.nifAlreadyRegistered'));
        return;
      }

      newBusiness.AccountName = 'Principal';
      const businessSaved = await businessSaveComplete(newBusiness).unwrap();

      if (businessSaved.ErrorMsg) {
        throw new Error(businessSaved.ErrorMsg);
      }

      setCurrentBusinessSelected({ ...businessSaved.Business });
      refetch();
      toast(t('businessPage.savingSuccess'), { type: 'success' });
    } catch (e) {
      toast.error(
        e?.message || e?.data?.ErrorMsg || t('businessPage.savingError'),
      );
    }
  };

  const handleSave = async (values) => {
    if (!values.BusinessId) {
      // New Business save
      await handleSaveNewBusiness({
        ...values,
        ValueRequiredPIN: Number(currencyToFloat(values.ValueRequiredPIN)),
        MobileDDI:
          mobileCountry === 3
            ? '55'
            : mobileCountry === 2
            ? '351'
            : mobileCountry === 1
            ? '244'
            : '',
      });
      return;
    }

    const {
      Balance,
      MainAccountId: modifiedAccountId,
      ...modifiedBusiness
    } = values;

    try {
      await businessSave({
        ...modifiedBusiness,
        ValueRequiredPIN: Number(
          currencyToFloat(modifiedBusiness.ValueRequiredPIN),
        ),
      }).unwrap();
      toast(t('businessPage.savingSuccess'), { type: 'success' });
    } catch {
      toast.error(t('businessPage.savingError'));
    }
  };

  const getControlledInput = (
    name,
    label,
    error,
    size = '100%',
    isActive = false,
    readOnly = false,
    isPassword = false,
  ) => (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, name, ...fieldProps } }) => (
        <Input
          id={name}
          label={label}
          error={error}
          $readOnly={readOnly}
          disabled={readOnly}
          isActive={isActive}
          onChange={
            name !== 'Balance' && name !== 'ValueRequiredPIN'
              ? name === 'Mobile'
                ? (e) =>
                    onChange(
                      phoneMask({
                        value: e.target.value,
                        countryCode: mobileCountry,
                      }),
                    )
                : onChange
              : (e) => onChange(currencyMask({ value: e.target.value }))
          }
          width={size}
          isPasswordField={isPassword}
          {...fieldProps}
        />
      )}
    />
  );

  return (
    <Container onSubmit={handleSubmit(handleSave)}>
      {isSuccess && showAuditData && auditData?.length > 0 && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setShowAuditData(false)}
          title={`Logs de alteração - Registro: Empresa [${currentBusinessSelected.BusinessName}]`}
        />
      )}
      {currentBusinessSelected?.BusinessId && auditData?.length > 0 && (
        <AuditButton onClick={() => setShowAuditData(true)} />
      )}
      <LoadIndicator visible={isSubmitting || isLoading} />
      <FieldsGroup $colCount={width > 912 ? 2 : 1}>
        {getControlledInput(
          'NIF',
          t('businessPage.businessNif'),
          errors.NIF,
          '95%',
          !!businessCompleteData?.Business?.NIF,
          !!businessCompleteData?.Business?.BusinessId,
        )}
        {getControlledInput(
          'BusinessName',
          t('businessPage.legalName'),
          errors.BusinessName,
          '95%',
          !!businessCompleteData?.Business?.BusinessName,
          !ACL.Add && !ACL?.Update,
        )}
        <MobileInputContainer>
          <FlagDropdownMenu
            value={mobileCountry}
            setValue={setMobileCountry}
            readOnly={!appConfig.isDev || !!currentBusinessSelected?.BusinessId}
          />
          {getControlledInput(
            'Mobile',
            t('businessPage.mobile'),
            errors.Mobile,
            '95%',

            !!businessCompleteData?.Accounts?.find(
              (account) =>
                account.AccountId ===
                businessCompleteData?.Business?.MainAccountId,
            )?.Mobile,

            !!businessCompleteData?.Business?.BusinessId,
          )}
        </MobileInputContainer>
        {getControlledInput(
          'ValueRequiredPIN',
          t('businessPage.valueRequirePIN'),
          errors.ValueRequiredPIN,
          '95%',
          currentBusinessSelected?.ValueRequiredPIN >= 0,
          !ACL.Add && !ACL?.Update,
        )}
        {!currentBusinessSelected?.BusinessId &&
          getControlledInput(
            'PIN',
            t('generalWords.pin'),
            errors.PIN,
            '95%',
            !!currentBusinessSelected?.PIN,
            false,
            true,
          )}
        {!currentBusinessSelected?.BusinessId &&
          getControlledInput(
            'PINConfirmation',
            t('generalWords.pinConfirmation'),
            errors.PINConfirmation,
            '95%',
            !!currentBusinessSelected?.PINConfirmation,
            false,
            true,
          )}
        {businessCompleteData?.Accounts?.find(
          (account) =>
            account.AccountId === businessCompleteData?.Business?.MainAccountId,
        ) &&
          getControlledInput(
            'Balance',
            t('generalWords.balance'),
            null,
            '95%',
            !!businessCompleteData?.Accounts?.find(
              (account) =>
                account.AccountId ===
                businessCompleteData?.Business?.MainAccountId,
            )?.Balance >= 0,
            true,
          )}
      </FieldsGroup>
      <FieldsGroup $colCount={width > 800 ? 2 : 1}>
        {getControlledCheckBox(
          'AcceptPayment',
          t('businessPage.acceptPayment'),
          '100%',
          !ACL.Add && !ACL?.Update,
        )}
        {getControlledCheckBox(
          'AcceptChange',
          t('businessPage.offersChange'),
          '100%',
          !ACL.Add && !ACL?.Update,
        )}
        {getControlledCheckBox(
          'EmployeeBenefit',
          t('businessPage.employeeBenefit'),
          '100%',
          !ACL.Add && !ACL?.Update,
        )}
      </FieldsGroup>
      <ButtonsContainer>
        {(ACL.Update || ACL.Add) && (
          <FormButton type="submit">Salvar</FormButton>
        )}
      </ButtonsContainer>
    </Container>
  );
}

BusinessForm.propTypes = {
  readOnly: bool,
};

BusinessForm.defaultProps = {
  readOnly: false,
};
