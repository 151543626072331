import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  gap: 10px;

  transition: 0.3s;
`;

export const Title = styled.span`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 1rem;
  }

  @media (max-width: 570px) {
    font-size: 0.7rem;
  }
`;

export const InfoDisplay = styled.span`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.accent};

  @media (max-width: 800px) {
    font-size: 2rem;
  }

  @media (max-width: 570px) {
    font-size: 1.5rem;
  }
`;
