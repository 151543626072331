import styled, { keyframes } from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: 'translateY(2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateY(0)';
  }
`;

const slideRightAndFade = keyframes`
  0% {
    opacity: 0;
    transform: 'translateX(-2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateX(0)';
  }
`;

const slideDownAndFade = keyframes`
  0% {
    opacity: 0;
    transform: 'translateY(-2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateY(0)';
  }
`;

const slideLeftAndFade = keyframes`
  0% {
    opacity: 0;
    transform: 'translateX(2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateX(0)';
  }
`;

export const TooltipContent = styled(TooltipPrimitive.Content)`
  background-color: ${({ theme }) => theme.colors.tooltipBackground};
  color: ${({ theme }) => theme.colors.tooltipText};
  /* border: none; */
  border: 1px solid ${({ theme }) => theme.colors.grid.selectedRow}50;
  padding: 5px 7.5px;
  font-size: 0.85rem;
  border-radius: 8px;
  box-shadow: hsl(210 22% 10% / 50%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    &[data-state='delayed-open'] {
      &[data-side='top'] {
        animation-name: ${slideDownAndFade};
      }
      &[data-side='right'] {
        animation-name: ${slideLeftAndFade};
      }
      &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
      }
      &[data-side='left'] {
        animation-name: ${slideRightAndFade};
      }
    }
  }
`;

export const TooltipArrow = styled(TooltipPrimitive.Arrow)`
  fill: ${({ theme }) => theme.colors.tooltipBackground};
  border: none;
`;
