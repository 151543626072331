import { Button } from 'components/Button';
import styled from 'styled-components';

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 5px;
  border-top: 1px solid ${({ theme }) => theme.colors.lineColor};
  padding: 10px;
`;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${({ theme }) => theme.colors.text};
    font-size: 1rem;
  }
`;

export const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: none;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 15px;
  font-size: 0.75rem;
  border-radius: 50px;
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const RemoveEmployeeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: none;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.error};
  padding: 10px 15px;
  font-size: 0.75rem;
  border-radius: 50px;
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
