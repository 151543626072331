import React, { useState } from 'react';

import { selectIcon } from 'assets';
import { FadedContainer } from 'components/FadedContainer';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { t } from 'i18next';
import NewProfileTypeDialog from 'pages/PermissionsPage/components/NewProfileTypeDialog';
import { useGetGroupListQuery } from 'store/slices/apiSlice/endpoints/permission';
import PermissionDetail from './components/PermissionDetail';
import { usePermissionsPage } from './contexts/PermissionsPageContext';
import {
  AddGroupButton,
  Container,
  Header,
  ProfileType,
  WrapperContent,
  WrapperList,
  WrapperProfilesTypes,
} from './styles';

export function PermissionsPage() {
  const [showNewProfileDialog, setShowNewProfileDialog] = useState(false);
  const ACL = usePermissions();

  const { groupSelected, setGroupSelected } = usePermissionsPage();
  const { dimensions } = useWindowDimension();
  const { data: groupList } = useGetGroupListQuery();

  return (
    <FadedContainer>
      <Container>
        {showNewProfileDialog && (
          <NewProfileTypeDialog
            onClose={() => setShowNewProfileDialog(false)}
          />
        )}

        <WrapperList
          $isMobile={dimensions.width < 1000}
          $isExpanded={!groupSelected}
        >
          <Header>
            <span>{t('permissionsPage.profiles')}</span>
            {ACL.Add && (
              <AddGroupButton
                onClick={() => setShowNewProfileDialog(true)}
                disabled={groupSelected}
              >
                <i className="fa-solid fa-plus" />
              </AddGroupButton>
            )}
          </Header>
          <WrapperProfilesTypes>
            {groupList?.map((group) => (
              <ProfileType
                key={group?.GroupId}
                $isSelected={groupSelected?.GroupId === group?.GroupId}
                onClick={() => setGroupSelected(group)}
                disabled={groupSelected}
              >
                {group?.GroupName}
              </ProfileType>
            ))}
          </WrapperProfilesTypes>
        </WrapperList>
        <WrapperContent
          $isMobile={dimensions.width < 1000}
          $isExpanded={groupSelected}
        >
          {groupSelected ? (
            <PermissionDetail />
          ) : (
            <img src={selectIcon} alt={t('permissionsPage.selectAUser')} />
          )}
        </WrapperContent>
      </Container>
    </FadedContainer>
  );
}
