import { Button } from 'components/Button';
import { Input as MotionInput } from 'components/Input';
import styled from 'styled-components';

export const MainForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.boxBackground};
  padding: 45px;
  border-radius: 16px;
  gap: 40px;

  @media (max-width: 700px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoImage = styled.img`
  width: 70%;
  align-self: center;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.text};
  align-self: flex-end;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
`;

export const LoginButton = styled(Button)`
  padding: 4px 10px;
  width: 90%;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabledText : theme.colors.buttonText};

  border-radius: 16px;
  border: none;

  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;

export const ForgotButton = styled(Button)`
  padding: 4px;
  width: 90%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;

  border-radius: 16px;
  border: none;
`;

export const Input = styled(MotionInput)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid
    ${({ error, theme }) => (error ? theme.colors.error : theme.colors.text)};
`;
