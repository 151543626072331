import { Button } from 'components/Button';
import { Input as MotionInput } from 'components/Input';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  gap: 30px;

  @media (max-width: 800px), (max-height: 540px) {
    gap: 15px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-self: flex-start;
  padding: 5px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 90%;
  height: 100%;
  background: inherit;

  @media (max-width: 800px), (max-height: 540px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    width: 95%;
    height: 100%;
    padding: 0 5px;
  }
  overflow-y: auto;
  overflow-x: hidden;

  .avatar {
    min-height: 5rem;
  }

  .content {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    gap: 16px;

    @media (max-width: 930px) {
      flex-direction: column;
    }
  }
`;

export const Input = styled(MotionInput)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 8px;
  border: ${({ $readOnly, error, theme }) =>
    `2px ${$readOnly ? 'dotted' : 'solid'} ${
      error ? theme.colors.error : theme.colors.text
    }`};
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'all')};
`;

export const WrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc(50% - 8px);
  max-width: 600px;
  padding-bottom: 14px;
  background-color: ${({ theme }) => theme.colors.grid.background};
  border-radius: 12px;

  @media (max-width: 930px) {
    width: 100%;
  }

  .loading {
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineColor};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grid.header};

  border-radius: 12px 12px 0 0;

  > span {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.text};

    @media (max-width: 1000px) {
      font-size: 0.9rem;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  gap: 30px;

  @media (max-width: 800px), (max-height: 540px) {
    width: 95%;
  }
`;

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
`;

export const BlockMessage = styled.div`
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.error};
  /* background-color: ${({ theme }) => theme.colors.error};
  color: white; */
  gap: 10px;
  border-radius: 50px;
`;
