import { decodeToken } from './decodeToken';

export function isTokenExpired(token) {
  const decodedToken = decodeToken(token);
  let result = true;

  if (decodedToken && decodedToken.exp) {
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);

    result = expirationDate.valueOf() < new Date().valueOf();
  }

  return result;
}
