import { Button } from 'components/Button';
import styled from 'styled-components';

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 5px;
  border-top: 1px solid ${({ theme }) => theme.colors.lineColor};
  padding: 10px 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
`;

export const RefreshGridButton = styled(Button)`
  width: 60px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.text};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ theme }) => theme.colors.accent};

  i {
    color: ${({ theme }) => theme.colors.buttonText};
  }
`;

export const Title = styled.span`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
`;
