import React from 'react';
import { RecoveryForm } from './components/RecoveryForm';
import { MainContainer } from './styles';

export function RecoveryPage() {
  return (
    <MainContainer>
      <RecoveryForm />
    </MainContainer>
  );
}
