import { Avatar } from 'components/Avatar';
import { useProfile } from 'components/ProfileButton/context/ProfileContext';
import { Tooltip } from 'components/Tooltip';
import { bool, number, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Container, DeleteButton, EditionButtons } from './styles';

export function AvatarEditor({
  readOnly,
  imageUrl,
  nameInitials,
  fullName,
  imageSize,
}) {
  const { t } = useTranslation();
  const {
    avatarPreview,
    setAvatarPreview,
    imageHasChanged,
    setImageHasChanged,
  } = useProfile();

  const handleAvatarChange = (e) => {
    const fileFormat = e?.target?.files[0]?.name?.split('.')?.pop();
    const size = e?.target?.files[0]?.size;

    if (
      (fileFormat !== 'png' && fileFormat !== 'jpeg' && fileFormat !== 'jpg') ||
      size > 50000
    ) {
      toast.error(t('profileForm.imageOutOfStandard'));
      return;
    }

    if (e.target.files[0]) {
      setAvatarPreview({
        file: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      });
      setImageHasChanged(true);
    }
  };

  const handleDeleteImage = () => {
    setAvatarPreview({ file: null, url: '' });
    setImageHasChanged(true);
  };

  return (
    <Container>
      <Tooltip content={t('profileForm.clickToEditAvatar')}>
        <label htmlFor="avatarImage">
          <Avatar
            size={imageSize}
            imageUrl={imageHasChanged ? avatarPreview.url : imageUrl}
            nameInitials={nameInitials}
            fullName={fullName}
          />
          <input
            type="file"
            id="avatarImage"
            accept="image/png, image/jpeg, image/jpg"
            data-file={avatarPreview}
            onChange={handleAvatarChange}
          />
        </label>
      </Tooltip>
      <EditionButtons>
        {/* <VisibilityDropdownMenu readOnly={readOnly} /> */}
        {!readOnly && (
          <DeleteButton type="button" onClick={handleDeleteImage}>
            <i className="fas fa-trash" />
            <span>{t('avatarEditor.deletePhoto')}</span>
          </DeleteButton>
        )}
      </EditionButtons>
    </Container>
  );
}

AvatarEditor.propTypes = {
  readOnly: bool,
  imageUrl: string,
  nameInitials: string,
  fullName: string,
  imageSize: number,
};

AvatarEditor.defaultProps = {
  readOnly: false,
  imageUrl: '',
  nameInitials: '',
  fullName: '',
  imageSize: 90,
};
