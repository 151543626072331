/* eslint-disable no-unused-vars */
import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupList: builder.query({
      query: () => ({
        url: 'sysGroup',
        method: 'GET',
      }),
      providesTags: [{ type: 'Group', id: 'LIST' }],
    }),
    getGroupUsersList: builder.query({
      query: (groupId) => ({
        url: `sysUserGroup/ListUsers/${groupId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, groupId) => [
        { type: 'Group', id: groupId },
      ],
    }),
    postUsersGroup: builder.mutation({
      query: (groupUserData) => ({
        url: `sysUserGroup/SaveGroup`,
        method: 'POST',
        body: groupUserData,
      }),
      invalidatesTags: (_result, _error, { GroupId }) => [
        { type: 'Group', id: GroupId },
        { type: 'Group', id: 'LIST' },
      ],
    }),
    getGroupMenuList: builder.query({
      query: (groupId) => ({
        url: `sysGroupMenu/${groupId}`,
        method: 'GET',
      }),
    }),
    getMenuListByUser: builder.query({
      query: () => ({
        url: 'sysMenu/ListByUser',
        method: 'GET',
      }),
      providesTags: [{ type: 'Menu', id: 'LIST' }],
    }),
    postGroupMenuPermissions: builder.mutation({
      query: (groupMenuPermissionsData) => ({
        url: 'sysGroupMenu/SavePermissions',
        method: 'POST',
        body: groupMenuPermissionsData,
      }),
      invalidatesTags: (_result, _error, groupMenuPermissionsData) => [
        { type: 'Menu', id: 'LIST' },
        { type: 'Group', id: 'LIST' },
        { type: 'Group', id: groupMenuPermissionsData.GroupId },
      ],
    }),
    postSysGroup: builder.mutation({
      query: (groupData) => ({
        url: 'sysGroup',
        method: 'POST',
        body: groupData,
      }),
      invalidatesTags: ({ GroupId }, _error, _args) => [
        { type: 'Group', id: GroupId },
        { type: 'Group', id: 'LIST' },
      ],
    }),
    getNotInGroupUsersList: builder.query({
      query: (groupId) => ({
        url: `BackOfficeUser/ListNotInGroup/${groupId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, groupId) => [
        { type: 'Group', id: groupId },
      ],
    }),
    deleteSysGroup: builder.mutation({
      query: (groupId) => ({
        url: `sysGroup/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, groupId) => [
        { type: 'Group', id: groupId },
        { type: 'Group', id: 'LIST' },
      ],
    }),
    getAccessControlByPath: builder.query({
      query: (path) => ({
        url: `sysMenu/MenuAccessControl/${path}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useGetGroupListQuery,
  useGetGroupUsersListQuery,
  usePostUsersGroupMutation,
  useGetMenuListByUserQuery,
  useGetGroupMenuListQuery,
  useLazyGetGroupMenuListQuery,
  usePostGroupMenuPermissionsMutation,
  useGetNotInGroupUsersListQuery,
  usePostSysGroupMutation,
  useDeleteSysGroupMutation,
  useGetAccessControlByPathQuery,
} = extendApi;
