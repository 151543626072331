import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover';
import { differenceInDays, format, parseISO, subDays } from 'date-fns';
import { useAudit } from 'pages/AuditPage/contexts/AuditContext';
import { bool } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  DateInputWrapper,
  DateRangeWrapper,
  Input,
  Label,
  RadioGroup,
  RadioIndicator,
  RadioItem,
  RadioOptionWrapper,
} from './styles';

export function PeriodSelector({ readOnly }) {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(1);
  const { auditFilter, setAuditFilter } = useAudit();
  const menuOptions = useMemo(
    () => [
      {
        id: 1,
        itemDisplay: {
          label: t('transactionsPage.today'),
          fieldType: 'radioButton',
        },
        currentDisplay: (
          <>
            <i className="fas fa-calendar-day" />
            <span>{t('transactionsPage.today')}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </>
        ),
      },
      {
        id: 2,
        itemDisplay: {
          label: t('transactionsPage.yesterday'),
          fieldType: 'radioButton',
        },
        currentDisplay: (
          <>
            <i className="fas fa-calendar-minus" />
            <span>{t('transactionsPage.yesterday')}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </>
        ),
      },
      {
        id: 3,
        itemDisplay: {
          label: t('transactionsPage.thisWeek'),
          fieldType: 'radioButton',
        },
        currentDisplay: (
          <>
            <i className="fas fa-calendar-week" />
            <span>{t('transactionsPage.thisWeek')}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </>
        ),
      },
      {
        id: 4,
        itemDisplay: {
          label: t('transactionsPage.lastFifteenDays'),
          fieldType: 'radioButton',
        },
        currentDisplay: (
          <>
            <i className="fas fa-calendar-plus" />
            <span>{t('transactionsPage.lastFifteenDays')}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </>
        ),
      },
      {
        id: 5,
        itemDisplay: {
          label: t('transactionsPage.thisMonth'),
          fieldType: 'radioButton',
        },
        currentDisplay: (
          <>
            <i className="fas fa-calendar-days" />
            <span>{t('transactionsPage.thisMonth')}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </>
        ),
      },
      {
        id: 6,
        itemDisplay: {
          label: t('transactionsPage.periodRange'),
          fieldType: 'dateInput',
        },
        currentDisplay: (
          <>
            <i className="fas fa-left-right" />
            <span>{t('transactionsPage.periodRange')}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </>
        ),
      },
    ],
    [readOnly],
  );

  useEffect(() => {
    const { StartDate, EndDate } = auditFilter;
    const today = new Date();
    const diffInDays = differenceInDays(parseISO(EndDate), parseISO(StartDate));

    switch (diffInDays) {
      case 0:
        setSelectedItem(
          differenceInDays(parseISO(StartDate), new Date()) === 0 ? 1 : 2,
        );
        break;
      case 7:
        if (differenceInDays(today, parseISO(EndDate)) === 0) {
          setSelectedItem(3);
          break;
        }
        setSelectedItem(6);
        break;
      case 15:
        if (differenceInDays(today, parseISO(EndDate)) === 0) {
          setSelectedItem(4);
          break;
        }
        setSelectedItem(6);
        break;
      case 30:
        if (differenceInDays(today, parseISO(EndDate)) === 0) {
          setSelectedItem(5);
          break;
        }
        setSelectedItem(6);
        break;

      default:
        setSelectedItem(6);
        break;
    }
  }, [auditFilter]);

  const changeStartEndDate = (selectedPeriod) => {
    switch (selectedPeriod) {
      case 1:
        setAuditFilter((prevState) => ({
          ...prevState,

          StartDate: format(new Date(), 'yyyy-MM-dd'),
        }));
        setAuditFilter((prevState) => ({
          ...prevState,
          EndDate: format(new Date(), 'yyyy-MM-dd'),
        }));
        break;
      case 2:
        setAuditFilter((prevState) => ({
          ...prevState,
          StartDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
        }));
        setAuditFilter((prevState) => ({
          ...prevState,
          EndDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
        }));
        break;
      case 3:
        setAuditFilter((prevState) => ({
          ...prevState,
          StartDate: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
        }));
        setAuditFilter((prevState) => ({
          ...prevState,
          EndDate: format(new Date(), 'yyyy-MM-dd'),
        }));
        break;
      case 4:
        setAuditFilter((prevState) => ({
          ...prevState,
          StartDate: format(subDays(new Date(), 15), 'yyyy-MM-dd'),
        }));
        setAuditFilter((prevState) => ({
          ...prevState,
          EndDate: format(new Date(), 'yyyy-MM-dd'),
        }));
        break;
      case 5:
        setAuditFilter((prevState) => ({
          ...prevState,
          StartDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        }));
        setAuditFilter((prevState) => ({
          ...prevState,
          EndDate: format(new Date(), 'yyyy-MM-dd'),
        }));
        break;

      default:
        break;
    }
  };

  const handleChangePeriodSelected = (value) => {
    setSelectedItem(value);
    changeStartEndDate(value);
  };

  const handleChangePeriodValue = (e) => {
    const { id, value, min } = e.target;

    const dateToSave = value || min;

    setAuditFilter((prevState) => ({
      ...prevState,
      [id]: format(parseISO(dateToSave), 'yyyy-MM-dd'),
    }));
  };

  const dateRangeInput = useMemo(
    () => (
      <DateRangeWrapper>
        <DateInputWrapper>
          <Label htmlFor="StartDate">Início</Label>
          <Input
            type="date"
            id="StartDate"
            min={format(new Date(2020, 0, 1), 'yyyy-MM-dd')}
            max={auditFilter.EndDate}
            value={auditFilter.StartDate}
            onChange={(e) => handleChangePeriodValue(e)}
          />
        </DateInputWrapper>
        <DateInputWrapper>
          <Label htmlFor="EndDate">Fim</Label>
          <Input
            type="date"
            id="EndDate"
            min={auditFilter.StartDate}
            max={format(new Date(), 'yyyy-MM-dd')}
            value={auditFilter.EndDate}
            onChange={(e) => handleChangePeriodValue(e)}
          />
        </DateInputWrapper>
      </DateRangeWrapper>
    ),
    [selectedItem, auditFilter],
  );
  return (
    <Container>
      <span>{t('transactionsPage.period')}</span>
      <Popover>
        <PopoverTrigger $readOnly={readOnly}>
          {
            menuOptions.find((option) => option.id === selectedItem)
              ?.currentDisplay
          }
        </PopoverTrigger>
        <PopoverContent>
          <RadioGroup
            value={selectedItem}
            onValueChange={(value) => handleChangePeriodSelected(value)}
          >
            {menuOptions.map((item) => (
              <RadioOptionWrapper key={item.id}>
                <RadioItem value={item.id} id={item.id}>
                  <RadioIndicator />
                </RadioItem>
                <Label htmlFor={item.id}>{item.itemDisplay.label}</Label>
              </RadioOptionWrapper>
            ))}
          </RadioGroup>
          {dateRangeInput}
        </PopoverContent>
      </Popover>
    </Container>
  );
}

PeriodSelector.propTypes = {
  readOnly: bool,
};

PeriodSelector.defaultProps = {
  readOnly: false,
};
