import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton } from './styles';

export const BackButton = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <BaseButton ref={ref} {...props}>
      <i className="fas fa-chevron-left" />
      <span>{t('rechargePage.back')}</span>
    </BaseButton>
  );
});
