import { bool, func, string } from 'prop-types';
import React, { forwardRef } from 'react';
import { Container, Label, SwitchThumb, Switch as SwitchS } from './styles';

export const Switch = forwardRef(
  ({ label, checked, onCheckedChange, ...props }, ref) => (
    <Container>
      {label && <Label>{label}</Label>}
      <SwitchS
        ref={ref}
        defaultChecked
        checked={checked}
        onCheckedChange={onCheckedChange}
        {...props}
      >
        <SwitchThumb checked={checked} />
      </SwitchS>
    </Container>
  ),
);

Switch.propTypes = {
  label: string,
  checked: bool.isRequired,
  onCheckedChange: func.isRequired,
  disabled: bool,
};

Switch.defaultProps = {
  label: '',
  disabled: false,
};
