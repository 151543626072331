import styled from 'styled-components';

export const MainContainer = styled.main`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.boxBackground};
  overflow-y: hidden;
`;

export const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.background};
  border-top-left-radius: ${({ $isLoginPage }) =>
    $isLoginPage ? '0px' : '16px'};
  border-bottom-left-radius: ${({ $isLoginPage }) =>
    $isLoginPage ? '0px' : '16px'};
  overflow-y: hidden;
`;
