import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100%;
  width: 100%;
`;

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.error};
`;
