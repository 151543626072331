import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  padding-top: 25px;
  gap: 15px;
  height: 100%;
`;

export const CheckBoxBase = styled(CheckboxPrimitive.Root)`
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border: 2px ${({ $readOnly }) => ($readOnly ? 'dotted' : 'solid')}${({ theme }) => theme.colors.text};
`;

export const Indicator = styled(CheckboxPrimitive.Indicator)`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.9rem;
  padding-left: 7px;
  line-height: 1;
  user-select: none;
`;
