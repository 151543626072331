import { Input } from 'components/Input';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SearchInput = styled(Input)`
  display: flex;
  flex: 1;
  background-color: transparent;
  border: none;
  margin: 2.5px 0 2.5px 2.5px;
`;

export const FieldContainer = styled.form`
  display: flex;
  background-color: ${({ theme }) => theme.colors.searchInputBackground};
  border-radius: 25px;
  gap: 10px;
  height: 36px;

  @media (max-width: 380px) {
    gap: 2.5px;
  }
`;

export const SearchButton = styled(motion.button)`
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  border: none;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  width: 80px;
  height: 100%;
  margin-right: -2px;
  margin-left: 20px;

  @media (max-width: 460px) {
    width: 30px;
  }
`;
