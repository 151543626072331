import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export const MenuContent = styled(DropdownMenu.Content)`
  min-width: 150px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 5px;
  padding: 10px;
`;

export const MenuTrigger = styled(DropdownMenu.Trigger)`
  color: ${({ theme }) => theme.colors.buttonText};
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  width: 80px;
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;

  @media (max-width: 450px) {
    width: 40px;
  }
`;

export const MenuArrow = styled(DropdownMenu.Arrow)`
  fill: ${({ theme }) => theme.colors.background};
`;

export const RadioGroup = styled(DropdownMenu.RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 50vh;
  overflow-y: hidden;
  padding: 5px;
`;

export const RadioItem = styled(DropdownMenu.Item)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 40px;
  width: 100%;
  user-select: none;
  margin: 5px 0;
  padding: 5px 0;
  font-size: 0.9rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 8px;
  transition: background-color 0.3s;

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.boxBackground};
  }
`;

export const FlagImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 36px;
  border-radius: 4px;
  background-image: ${({ $bkgImg }) => `url(${$bkgImg})`};
  background-size: cover;
  background-position: center;
  background-repeat: none;
  overflow: hidden;
`;

export const CurrentDisplayButton = styled.button`
  color: ${({ theme }) => theme.colors.buttonText};
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  width: 80px;
  height: 100%;
  background-image: ${({ $flagImg }) => `url(${$flagImg})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  border: ${({ theme, $readOnly }) =>
    `1px ${$readOnly ? 'none' : 'solid'} ${theme.colors.text}`};
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'all')};
  transition: opacity 300ms;

  @media (max-width: 460px) {
    width: 30px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
