import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import useWindowDimension from 'hooks/useWindowDimension';
import { func, oneOf, string } from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useBackOfficeChangePinMutation } from 'store/slices/apiSlice/endpoints/backOfficeUser';
import { useUserChangePasswordMutation } from 'store/slices/apiSlice/endpoints/user';
import * as yup from 'yup';
import { ButtonsContainer, Container, FormButton, Input } from './styles';

const ChangePopup = ({ title, changeType, onClose }) => {
  const { t } = useTranslation();
  const { userData } = useSelector(({ auth }) => auth);
  const [userChangePassword] = useUserChangePasswordMutation();
  const [backOfficeChangePin] = useBackOfficeChangePinMutation();
  const { dimensions } = useWindowDimension();
  const changeSchema =
    changeType === 'pin'
      ? yup.object({
          Password: yup
            .string()
            .required()
            .min(6, t('recoveryPage.passwordMinSize')),
          NewPIN: yup
            .string()
            .required(t('subAccountsForm.pinRequired'))
            .min(4, t('subAccountsForm.pinSizeMustBeFour'))
            .max(4, t('subAccountsForm.pinSizeMustBeFour')),
          NewPINConfirmation: yup
            .string()
            .oneOf(
              [yup.ref('NewPIN'), null],
              t('subAccountsForm.pinMustMatch'),
            ),
        })
      : yup.object({
          Password: yup
            .string()
            .required()
            .min(6, t('recoveryPage.passwordMinSize')),
          NewPassword: yup
            .string()
            .required(t('recoveryPage.newPasswordRequired'))
            .min(6, t('recoveryPage.passwordMinSize')),
          NewPasswordConfirmation: yup
            .string()
            .oneOf(
              [yup.ref('NewPassword'), null],
              t('recoveryPage.passwordsMustMatch'),
            ),
        });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(changeSchema),
  });
  const { errors, isSubmitting } = formState;

  const getRegisteredComponent = ({ name, label, error, isActive = false }) => (
    <Input
      label={label}
      isActive={isActive}
      error={error}
      isPasswordField
      {...register(name)}
    />
  );

  const handleChangePin = async (changesData) => {
    try {
      const res = await backOfficeChangePin({
        ...changesData,
        PIN: changesData?.NewPIN,
        UserId: userData.UserId,
      }).unwrap();
      if (res.ErrorMsg) {
        throw new Error(res.ErrorMsg);
      }
      toast.success('PIN alterado com sucesso');
      onClose();
    } catch (e) {
      toast.error(
        e?.data?.ErrorMsg || e?.message || 'Erro na alteração do PIN.',
      );
    }
  };
  const handleChangePassword = async (changesData) => {
    try {
      const res = await userChangePassword({
        ...changesData,
        Email: userData?.Username,
        UserTypeId: 2,
      }).unwrap();
      if (res.ErrorMsg) {
        throw new Error(res.ErrorMsg);
      }
      toast.success('Senha alterada com sucesso');
      onClose();
    } catch (e) {
      toast.error(
        e?.data?.ErrorMsg || e?.message || 'Erro na alteração da senha.',
      );
    }
  };

  const submitChanges = async (changesData) => {
    if (changeType === 'pin') {
      await handleChangePin(changesData);
      return;
    }

    await handleChangePassword(changesData);
  };

  return (
    <Dialog
      title={title}
      width={dimensions.width < 500 ? '100%' : '500px'}
      onClose={onClose}
    >
      <LoadIndicator visible={isSubmitting} />
      <Container onSubmit={handleSubmit(submitChanges)}>
        {getRegisteredComponent(
          changeType === 'pin'
            ? {
                name: 'Password',
                label: 'Senha',
                error: errors.Password,
                isActive: true,
              }
            : {
                name: 'Password',
                label: 'Senha Antiga',
                error: errors.Password,
                isActive: true,
              },
        )}
        {getRegisteredComponent(
          changeType === 'pin'
            ? {
                name: 'NewPIN',
                label: 'Novo PIN',
                error: errors.NewPIN,
                isActive: true,
              }
            : {
                name: 'NewPassword',
                label: 'Nova senha',
                error: errors.NewPassword,
                isActive: true,
              },
        )}
        {getRegisteredComponent(
          changeType === 'pin'
            ? {
                name: 'NewPINConfirmation',
                label: 'Confirme o novo PIN',
                error: errors.NewPINConfirmation,
                isActive: true,
              }
            : {
                name: 'NewPasswordConfirmation',
                label: 'Confirme a nova senha',
                error: errors.NewPasswordConfirmation,
                isActive: true,
              },
        )}
        <ButtonsContainer>
          <FormButton type="submit">{t('generalWords.ok')}</FormButton>
          <FormButton type="button" $isCancel onClick={onClose}>
            {t('generalWords.cancel')}
          </FormButton>
        </ButtonsContainer>
      </Container>
    </Dialog>
  );
};

ChangePopup.propTypes = {
  title: string.isRequired,
  onClose: func.isRequired,
  changeType: oneOf(['password', 'pin']).isRequired,
};

export default ChangePopup;
