import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: transparent;
  gap: 20px;
  padding: 0 5px;

  @media (max-width: 1000px) {
    gap: 10px;
  }

  @media (max-width: 600px) {
    gap: 5px;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FieldSet = styled.fieldset`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: none;
  background-color: transparent;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
`;

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
`;

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
  padding: 0 20px;
`;

export const BalanceLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

export const BalanceSpan = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme, $isNegative }) =>
    $isNegative ? theme.colors.error : theme.colors.accent};

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 50%;
  background-color: inherit;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 0 30px;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: end;
  gap: 10px;
  width: 60%;

  @media (max-width: 600px) {
    width: 100%;
    gap: 5px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
`;
