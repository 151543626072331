import React from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneSearchBox } from './components/PhoneSearchBox';
import { Container, Title } from './styles';

export default function PhoneSearch() {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('rechargePage.findPhone')}</Title>
      <PhoneSearchBox />
    </Container>
  );
}
