/* eslint-disable no-unused-vars */
import appConfig from 'appConfig';
import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountTypeList: builder.query({
      query: () => ({
        url: '/AccountType',
        method: 'GET',
      }),
    }),
    getAccount: builder.query({
      query: (accountId) => ({
        url: `/Account/${accountId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        if (!response?.AccountId) {
          return response;
        }
        return {
          ...response,
          ImageUrl: response?.AccountId
            ? `${appConfig.apiUrl}/Images/Account/${response?.AccountId}`
            : '',
        };
      },
      providesTags: (result, _error, _arg) =>
        result?.AccountId
          ? [{ type: 'Account', id: result?.AccountId }]
          : ['Account'],
    }),
    getAccountByMobile: builder.query({
      query: (mobile) => ({
        url: `/Account/ListByMobile/${mobile
          .replace('+', '')
          .replaceAll(' ', '')}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        if (!response?.AccountId) {
          return response;
        }
        return {
          ...response,
          ImageUrl: response?.AccountId
            ? `${appConfig.apiUrl}/Images/Account/${response?.AccountId}`
            : '',
        };
      },
      providesTags: (result, _error, _arg) =>
        result?.AccountId
          ? [{ type: 'Account', id: result?.AccountId }]
          : ['Account'],
    }),
    accountStatusList: builder.query({
      query: () => ({
        url: `/AccountStatus`,
        method: 'GET',
      }),
      keepUnusedDataFor: Infinity,
    }),
    accountBusinessCreate: builder.mutation({
      query: (accountBusinessData) => ({
        url: '/Account/CreateBusinessAccount',
        method: 'POST',
        body: accountBusinessData,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Business', id: `BusinessId: ${arg?.BusinessId}` },
        { type: 'Audit', id: 'Account' },
      ],
    }),
    accountUpdatePIN: builder.mutation({
      query: (updatePinData) => ({
        url: '/Account/UpdatePIN',
        method: 'POST',
        body: updatePinData,
      }),
      invalidatesTags: (_result, _error, arg) =>
        arg?.BusinessId
          ? [
              { type: 'Business', id: `BusinessId: ${arg?.BusinessId}` },
              { type: 'Audit', id: 'Account' },
            ]
          : [{ type: 'Account', id: arg.AccountId }],
    }),
    getAccountNotBusiness: builder.query({
      query: (mobile) => ({
        url: `/Account/ListNotBusiness/${mobile
          ?.replace('+', '')
          .replaceAll(' ', '')}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAccountTypeListQuery,
  useGetAccountQuery,
  useGetAccountByMobileQuery,
  useAccountStatusListQuery,
  useAccountBusinessCreateMutation,
  useAccountUpdatePINMutation,
  useLazyGetAccountByMobileQuery,
  useLazyGetAccountNotBusinessQuery,
} = extendApi;
