import { Button } from 'components/Button';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: transparent;
  gap: 20px;
  padding: 0 5px;

  @media (max-width: 1000px) {
    gap: 10px;
  }

  @media (max-width: 600px) {
    gap: 5px;
  }
`;

export const WarningText = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors.text};
  font-size: 0.9rem;
  gap: 20px;

  @media (max-width: 1000px) {
    font-size: 0.8rem;
    gap: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-top: 30px;

  input[type='file'] {
    display: none;
  }
`;

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;

export const ImportButton = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
  cursor: pointer;
`;
