export function currencyMask({ value, withCurrencySymbol = true }) {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  return withCurrencySymbol ? `Kz ${value}` : value;
}

export function phoneMask({ value, countryCode = 1 }) {
  value = value.replace(/\D/g, '');
  value = getCountryFormat(countryCode, value);
  return value;
}

const getCountryFormat = (country, value) => {
  switch (country) {
    case 3:
      return formatToBrazil(value);
    default:
      return formatToAngolaAndPortugal(value);
  }
};

const formatToBrazil = (value) => {
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  value = value.replace(/(\d{5})(\d{4})$/, '$1-$2');
  return value;
};

const formatToAngolaAndPortugal = (value) => {
  value = value.replace(/^(\d{3})(\d)/g, '$1 $2');
  value = value.replace(/(\d{3})(\d{3})$/g, '$1 $2');
  return value;
};
