export function decodeToken(token) {
  try {
    if ((token && token.split('.').length !== 3) || typeof token !== 'string') {
      return null;
    }

    const tokenPayload = token.split('.')[1];
    const base64 = tokenPayload.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));

    return decodedToken;
  } catch (error) {
    return null;
  }
}
