import { Tooltip } from 'components/Tooltip';
import { utcToZonedTime } from 'date-fns-tz';
import { number, object, oneOfType, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { dateTimeFormatter } from 'utils/formatters';
import { Container, Indicator } from './styles';

export default function AccountCellRender({ value, data }) {
  const { timeZone } = useSelector(({ appOptions }) => appOptions);

  const blockedDateFormatter = ({ value }) => {
    if (!value) {
      return 'Desbloqueada';
    }

    return dateTimeFormatter({ value }, timeZone);
  };

  return (
    <Container>
      {utcToZonedTime(`${data?.BlockedUntil}Z`, timeZone) > new Date() && (
        <Tooltip
          content={`Bloqueado até: ${blockedDateFormatter({
            value: data?.BlockedUntil,
          })}`}
        >
          <Indicator
            $isYes={
              !utcToZonedTime(`${data?.BlockedUntil}Z`, timeZone) > new Date()
            }
            className="fas fa-lock"
          />
        </Tooltip>
      )}
      {value}
    </Container>
  );
}

AccountCellRender.propTypes = {
  value: oneOfType([string, number]).isRequired,
  data: object.isRequired,
};
