import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import { PhoneSearchBox } from 'components/PhoneSearchBox';
import { UserCard } from 'components/UserCard';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyGetAccountNotBusinessQuery } from 'store/slices/apiSlice/endpoints/account';
import { useBusinessEmployeeSaveMutation } from 'store/slices/apiSlice/endpoints/business';
import {
  ButtonsContainer,
  Container,
  FieldSet,
  FormButton,
  Title,
  UserContainer,
} from './styles';

const AddEmployeePopup = ({ onClose, ...props }) => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const { currentBusinessSelected } = useBusiness();
  const [businessEmployeeSave, { isLoading }] =
    useBusinessEmployeeSaveMutation();

  const [getAccountNotBusiness, { data: accountData, isSuccess, isFetching }] =
    useLazyGetAccountNotBusinessQuery({ refetchOnFocus: false });

  useEffect(() => {
    if (!isFetching && isSuccess) {
      if (!accountData?.AccountId) {
        toast.error(
          accountData?.ErrorMsg || t('generalWords.employeeNotFound'),
        );
        setCurrentUser(null);
        return;
      }
      setCurrentUser(accountData);
    }
  }, [isFetching]);

  const searchPhoneNumber = (phoneNumber) => {
    getAccountNotBusiness(phoneNumber);
  };

  const handleSaveBusinessEmployee = async () => {
    try {
      const businessEmployeeData = {
        BusinessId: currentBusinessSelected.BusinessId,
        AccountId: accountData.AccountId,
      };
      await businessEmployeeSave(businessEmployeeData).unwrap();
      toast.success(t('businessPage.employeeAddedSuccess'));
      setCurrentUser(null);
      onClose();
    } catch {
      toast.error(t('businessPage.employeeAddedError'));
    }
  };

  return (
    <Dialog width="600px" title={t('businessPage.searchEmployee')} {...props}>
      <Container>
        <FieldSet>
          <Title>{t('rechargePage.findPhone')}</Title>
          <PhoneSearchBox phoneSearchFn={searchPhoneNumber} />
        </FieldSet>
        <UserContainer>
          <LoadIndicator visible={isFetching || isLoading} />
          {currentUser && (
            <UserCard
              user={currentUser}
              avatarSize={50}
              usernameSize="1rem"
              mobileSize="0.85rem"
            />
          )}
        </UserContainer>
      </Container>
      <ButtonsContainer>
        <FormButton
          disabled={!accountData?.AccountId}
          onClick={handleSaveBusinessEmployee}
        >
          {t('generalWords.ok')}
        </FormButton>
        <FormButton $isCancel onClick={onClose}>
          {t('generalWords.cancel')}
        </FormButton>
      </ButtonsContainer>
    </Dialog>
  );
};

AddEmployeePopup.propTypes = {
  onClose: func.isRequired,
};

export default AddEmployeePopup;
