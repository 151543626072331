import { Button } from 'components/Button';
import { Input } from 'components/Input';
import styled from 'styled-components';

export const InputDesc = styled(Input)`
  margin-bottom: 16px;
  width: 90%;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.text};
`;

export const OptionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  padding: 0 22px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.buttonText};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ color, theme }) =>
    color === 'green' ? theme.colors.accent : theme.colors.error};

  font-size: 0.75rem;

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: fit-content;
  gap: 12px;
`;
