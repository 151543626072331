import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deviceByAccount: builder.query({
      query: (accountId) => ({
        url: `/Device/ListByAccount/${accountId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useDeviceByAccountQuery } = extendApi;
