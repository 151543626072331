import { useJWT } from 'hooks/useJWT';
import { element } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { authLogout } from 'store/slices/authSlice';

export function PrivateRoute({ children }) {
  const { userIsSigned } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const { isJWTExpired } = useJWT();
  const location = useLocation();

  if (!userIsSigned || isJWTExpired) {
    dispatch(authLogout());
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

PrivateRoute.propTypes = {
  children: element.isRequired,
};
