import { func } from 'prop-types';
import React from 'react';
import { Container, IconButton } from './styles';

export default function DeleteButtonRenderer({ onClick }) {
  return (
    <Container>
      <IconButton onClick={onClick}>
        <i className="fas fa-trash" />
      </IconButton>
    </Container>
  );
}

DeleteButtonRenderer.propTypes = {
  onClick: func.isRequired,
};
