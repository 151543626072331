import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    dashboard: builder.query({
      query: () => ({
        url: `/Dashboard`,
        method: 'GET',
      }),
      transformResponse: (data) => {
        const dashboardData = JSON.parse(data.DashboardData)[0];
        const emptyDashboardData = [
          {
            Clients: 0,
            Business: 0,
            ApprovedTransactions: 0,
            RejectedTransactions: 0,
          },
        ];
        return dashboardData || emptyDashboardData;
      },
    }),
  }),
});

export const { useDashboardQuery } = extendApi;
