import { DataGrid } from 'components/DataGrid';
import { Dialog } from 'components/Dialog';
import useWindowDimension from 'hooks/useWindowDimension';
import { usePermissionsPage } from 'pages/PermissionsPage/contexts/PermissionsPageContext';
import { array, func } from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  useGetNotInGroupUsersListQuery,
  usePostUsersGroupMutation,
} from 'store/slices/apiSlice/endpoints/permission';
import { ButtonsContainer, FormButton } from './styles';

function UsersListDialog({ onClose, currentUsers }) {
  const { dimensions } = useWindowDimension();
  const { groupSelected } = usePermissionsPage();
  const { data: usersList } = useGetNotInGroupUsersListQuery(
    groupSelected.GroupId,
    {
      refetchOnMountOrArgChange: true,
      skip: !groupSelected.GroupId,
      refetchOnFocus: true,
    },
  );
  const [postUsersGroup] = usePostUsersGroupMutation();
  const { t } = useTranslation();
  const usersDataGridRef = useRef();
  const colDefs = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'FullName',
        flex: dimensions.width > 800 ? 1 : undefined,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        headerName: 'Email',
        field: 'Email',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
    ],
    [],
  );

  const handleUserGroupSave = async () => {
    const selectedRows = usersDataGridRef?.current?.api?.getSelectedRows();
    if (!selectedRows || !selectedRows?.length) {
      toast.error(t('permissionsPage.noUserSelected'));
      return;
    }

    const groupUsers = {
      GroupId: groupSelected.GroupId,
      Users: [
        ...selectedRows.map(({ UserId }) => ({ UserId })),
        ...currentUsers,
      ],
    };

    try {
      const res = await postUsersGroup(groupUsers).unwrap();
      if (res.ErrorMsg) {
        toast.error(res.ErrorMsg || t('permissionsPage.errorAddUser'));
        return;
      }
      toast.success(t('permissionsPage.successAddUser'));
      onClose();
    } catch (error) {
      toast.error(
        error?.data?.ErrorMsg ||
          error?.message ||
          t('permissionsPage.errorAddUser'),
      );
    }
  };

  return (
    <Dialog
      width={dimensions.width > 800 ? '80%' : '100%'}
      height={dimensions.width > 800 ? '80%' : '100%'}
      title={`${t('permissionsPage.addUser')} [${groupSelected?.GroupName}]`}
      onClose={onClose}
      showCloseButton={false}
    >
      <DataGrid
        ref={usersDataGridRef}
        columnDefs={colDefs}
        rowData={usersList}
        rowSelection="multiple"
        suppressRowClickSelection
      />
      <ButtonsContainer>
        <FormButton onClick={handleUserGroupSave}>
          {t('permissionsPage.save')}
        </FormButton>
        <FormButton $isCancel onClick={onClose}>
          {t('generalWords.cancel')}
        </FormButton>
      </ButtonsContainer>
    </Dialog>
  );
}

UsersListDialog.propTypes = {
  onClose: func.isRequired,
  currentUsers: array,
};

UsersListDialog.defaultProps = {
  currentUsers: [],
};

export default UsersListDialog;
