import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { DataGrid } from 'components/DataGrid';
import { LoadIndicator } from 'components/LoadIndicator';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { usePermissionsPage } from 'pages/PermissionsPage/contexts/PermissionsPageContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  useDeleteSysGroupMutation,
  useGetGroupUsersListQuery,
  usePostUsersGroupMutation,
} from 'store/slices/apiSlice/endpoints/permission';
import PermissionsDialog from '../PermissionsDialog';
import UsersListDialog from '../UsersListDialog';
import DeleteButtonRenderer from './components/DeleteButtonRenderer';
import {
  AddBackOfficeUserButton,
  Content,
  ContentButton,
  ContentHeader,
  FooterButtons,
  Grid,
  WrapperGrid,
  WrapperHeaderButtons,
} from './styles';

const PermissionDetail = () => {
  const ACL = usePermissions();
  const [showPermissionsDialog, setShowPermissionsDialog] = useState(false);
  const [showUsersDialog, setShowUsersDialog] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [userDeleteList, setUserDeleteList] = useState([]);
  const [deleteSysGroup, { isLoading: deleteLoading }] =
    useDeleteSysGroupMutation();
  const [postUsersGroup] = usePostUsersGroupMutation();
  const { dimensions } = useWindowDimension();
  const { t } = useTranslation();
  const { groupSelected, setGroupSelected } = usePermissionsPage();
  const { data: groupUsersList } = useGetGroupUsersListQuery(
    groupSelected.GroupId,
    {
      refetchOnMountOrArgChange: true,
      skip: !groupSelected.GroupId,
    },
  );

  useEffect(() => {
    setUserDeleteList([]);
  }, []);

  useEffect(() => {
    if (groupUsersList?.length) {
      setGridData([...groupUsersList]);
    }
  }, [groupUsersList]);

  const handleDeleteGroup = useCallback(async () => {
    try {
      const res = await deleteSysGroup(groupSelected.GroupId).unwrap();
      if (res.ErrorMsg) {
        toast.error(
          res.ErrorMsg
            ? t(`permissionsPage.${res.ErrorMsg}`)
            : t('permissionsPage.groupChangesSaveError'),
        );
        return;
      }
      toast.success(t('permissionsPage.successDeleteGroup'));
      setGroupSelected(null);
    } catch (e) {
      toast.error(
        e?.data?.ErrorMsg ||
          e?.message ||
          t('permissionsPage.errorDeleteGroup'),
      );
    }
  }, [groupSelected]);

  const handleDeleteUser = (userId) => {
    setUserDeleteList([...userDeleteList, userId]);
    setGridData((prevState) =>
      prevState.filter((item) => item.UserId !== userId),
    );
  };

  const colDefs = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'FullName',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
      {
        headerName: 'Email',
        field: 'Email',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
      {
        hide: !ACL.Update,
        flex: dimensions.width > 800 ? 1 : undefined,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams: ({ data }) => ({
          onClick: () => handleDeleteUser(data.UserId),
        }),
      },
    ],
    [ACL],
  );

  const deleteConfirmationAlert = useMemo(
    () => (
      <ConfirmationDialog
        message={`${t('permissionsPage.confirmationDeleteGroup')} ${
          groupSelected?.GroupName
        }?`}
        confirmFn={handleDeleteGroup}
        triggerButton={
          <ContentButton>
            <i className="fa-solid fa-trash" />
          </ContentButton>
        }
      />
    ),
    [groupSelected],
  );

  const handleSaveChanges = async () => {
    if (userDeleteList?.length) {
      const groupUsers = {
        GroupId: groupSelected.GroupId,
        Users: [...gridData],
      };

      try {
        const res = await postUsersGroup(groupUsers).unwrap();
        if (res.ErrorMsg) {
          toast.error(
            res?.ErrorMsg || t('permissionsPage.groupChangesSaveError'),
          );
          return;
        }
        toast.success(t('permissionsPage.groupChangesSaveSuccess'));
        setGroupSelected(null);
        return;
      } catch (error) {
        toast.error(
          error?.data?.ErrorMsg ||
            error?.message ||
            t('permissionsPage.groupChangesSaveError'),
        );
      }
    }
    toast.success(t('permissionsPage.groupChangesSaveSuccess'));
    setGroupSelected(null);
  };

  return (
    <Content>
      {showPermissionsDialog && (
        <PermissionsDialog
          title={`${t('permissionsPage.permissionsRules')}${
            groupSelected.GroupName
          }`}
          onClose={() => setShowPermissionsDialog(false)}
        />
      )}
      {showUsersDialog && (
        <UsersListDialog
          currentUsers={gridData}
          onClose={() => setShowUsersDialog(false)}
        />
      )}
      <LoadIndicator visible={deleteLoading} />
      <ContentHeader className="main">
        <span className="title">
          {t('permissionsPage.profileTitle')}
          {groupSelected?.GroupName}
        </span>
        {ACL.Update && (
          <WrapperHeaderButtons>
            <ContentButton
              color="green"
              onClick={() => setShowPermissionsDialog(true)}
            >
              <i className="fa-solid fa-gear" />
              {dimensions.width > 800 && (
                <span>{t('permissionsPage.rules')}</span>
              )}
            </ContentButton>
            {deleteConfirmationAlert}
          </WrapperHeaderButtons>
        )}
      </ContentHeader>
      <WrapperGrid>
        <ContentHeader>
          <p>{t('permissionsPage.belongingUsers')}</p>
          {ACL.Update && (
            <AddBackOfficeUserButton
              onClick={() => {
                setShowUsersDialog(true);
              }}
            >
              <i className="fa-solid fa-plus" />
            </AddBackOfficeUserButton>
          )}
        </ContentHeader>
        <Grid>
          <DataGrid
            rowData={gridData}
            columnDefs={colDefs}
            noRowMessage={t('permissionsPage.noUsers')}
          />
        </Grid>
      </WrapperGrid>
      <FooterButtons>
        {ACL.Update && (
          <ContentButton onClick={handleSaveChanges} color="green">
            <span>{t('permissionsPage.save')}</span>
          </ContentButton>
        )}
        <ContentButton
          onClick={() => {
            setUserDeleteList([]);
            setGroupSelected(null);
          }}
        >
          <span>
            {t(ACL.Update ? 'permissionsPage.cancel' : 'generalWords.close')}
          </span>
        </ContentButton>
      </FooterButtons>
    </Content>
  );
};

export default PermissionDetail;
