import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import {
  CurrentDisplayButton,
  MenuArrow,
  MenuContent,
  RadioGroup,
  RadioItem,
} from './styles';

export const DropdownMenuRoot = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = MenuContent;
export const DropdownMenuArrow = MenuArrow;
export const DropdownMenuRadioGroup = RadioGroup;
export const DropdownMenuRadioItem = RadioItem;
export const CurrentOptionButton = CurrentDisplayButton;
