import { Avatar } from 'components/Avatar';
import { Switch } from 'components/Switch';
import { AnimatePresence, useAnimation } from 'framer-motion';
import useWindowDimension from 'hooks/useWindowDimension';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appOptionsToggleTheme } from 'store/slices/appOptionsSlice';
import { authLogout } from 'store/slices/authSlice';
import { useTheme } from 'styled-components';
import ProfileDialog from './components/ProfileDialog';
import { useProfile } from './context/ProfileContext';
import {
  Container,
  DesktopOption,
  DownArrow,
  MenuButton,
  MenuOptions,
  MobileOption,
  UserButton,
  UserDataLabel,
  UsernameSpan,
  UserOfficeSpan,
} from './styles';

export function ProfileButton() {
  const [isOpen, setIsOpen] = useState(false);
  const { profileDialogVisible, setProfileDialogVisible } = useProfile();
  const { userData } = useSelector(({ auth }) => auth);
  const theme = useTheme();
  const navigate = useNavigate();
  const controls = useAnimation();
  const dispatch = useDispatch();
  const { theme: currentTheme } = useSelector(({ appOptions }) => appOptions);
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const menuOptionsRef = useRef();

  useEffect(() => {
    function handleClickOutsideOptions(e) {
      if (
        menuOptionsRef.current &&
        !menuOptionsRef.current.contains(e.target)
      ) {
        if (isOpen) {
          setIsOpen(false);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutsideOptions);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideOptions);
    };
  });

  const userTypeList = [
    {
      UserTypeId: 2,
      UerTypeDesc: 'Utilizador BackOffice',
    },
  ];

  useEffect(() => {
    controls.start({
      rotate: isOpen ? 180 : 0,
      transition: {
        default: { duration: 0.2 },
      },
    });
  }, [isOpen]);

  const menuVariants = {
    open: { height: 'auto', opacity: 1 },
    collapsed: { height: 0, opacity: 0 },
  };
  const menuTransition = {
    duration: 0.2,
  };
  const buttonVariants = {
    open: { scale: 1, opacity: 1 },
    collapsed: { scale: 0.8, opacity: 0 },
  };
  const buttonTransition = {
    duration: 0.1,
  };

  const handleOpenProfile = () => {
    setProfileDialogVisible(true);
    setIsOpen(false);
  };

  const handleExit = () => {
    setIsOpen(false);
    navigate('/');
    dispatch(authLogout());
  };
  return (
    <Container open={isOpen}>
      {profileDialogVisible && (
        <ProfileDialog
          width={dimensions.width < 801 ? '100%' : '70%'}
          height={dimensions.width < 801 ? '100%' : 'content-fit'}
          onClose={() => {
            setProfileDialogVisible(false);
          }}
        />
      )}
      <UserButton
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Avatar
          size={dimensions.width > 800 ? 45 : 30}
          fullName={`${userData?.BackOfficeUser?.FullName}`}
          nameInitials={`${userData?.BackOfficeUser?.Initials}`}
          imageUrl={userData?.ImageUrl}
        />
        <UserDataLabel>
          <UsernameSpan>{`${userData?.BackOfficeUser?.FullName}`}</UsernameSpan>
          <UserOfficeSpan>{`${
            userTypeList.find(
              (type) => type.UserTypeId === userData?.UserTypeId,
            )?.UerTypeDesc || ''
          }`}</UserOfficeSpan>
        </UserDataLabel>
        <DownArrow animate={controls}>
          <i className="fas fa-chevron-down" />
        </DownArrow>
      </UserButton>
      <AnimatePresence initial={false}>
        {isOpen && (
          <MenuOptions
            ref={menuOptionsRef}
            variants={menuVariants}
            transition={menuTransition}
            initial="collapsed"
            animate="open"
            exit="collapsed"
          >
            <DesktopOption>
              {t('menu.darkMode')}
              <Switch
                checked={currentTheme === 'dark'}
                onCheckedChange={() => {
                  dispatch(appOptionsToggleTheme());
                }}
              />
            </DesktopOption>
            <MobileOption>
              <i className="fas fa-sun" />
              <Switch
                checked={currentTheme === 'dark'}
                onCheckedChange={() => {
                  dispatch(appOptionsToggleTheme());
                }}
              />
              <i className="fas fa-moon" />
            </MobileOption>
            <MenuButton
              onClick={handleOpenProfile}
              variants={buttonVariants}
              transition={buttonTransition}
            >
              {t('menu.myProfile')}
            </MenuButton>
            <MenuButton
              onClick={handleExit}
              variants={buttonVariants}
              transition={buttonTransition}
              color={theme.colors.error}
            >
              <span>{t('generalWords.exit')}</span>
              <i className="fas fa-sign-out-alt" />
            </MenuButton>
          </MenuOptions>
        )}
      </AnimatePresence>
    </Container>
  );
}
