import { AgGridReact } from 'ag-grid-react';
import { Button } from 'components/Button';
import styled from 'styled-components';

export const GridButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  height: 100%;
`;

export const DataGridComponent = styled(AgGridReact)`
  width: 100%;
  height: 100%;

  .ag-header {
    background-color: ${({ theme }) => theme.colors.grid.header};
  }

  .ag-root-wrapper {
    border-radius: 8px;
    border: none;
    font-family: 'Poppins', sans-serif;
    background-color: ${({ theme }) => theme.colors.grid.background};

    @media (max-width: 1000px) {
      font-size: 0.75rem;
    }
  }

  .ag-row {
    border: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.grid.rowHover};
    }

    @media (max-width: 1000px) {
      font-size: 0.75rem;
    }
  }

  .ag-row-selected {
    background-color: ${({ theme }) =>
      theme.colors.grid.selectedRow} !important;

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.grid.selectedRow} !important;
    }
  }
  .ag-cell {
    border: none !important;
  }
  .ag-checked {
    background-color: transparent;
  }
  .ag-checkbox-input-wrapper.ag-checked::after {
    color: rgb(129, 194, 66);
  }
  .ag-checkbox-input-wrapper {
    box-shadow: none;
  }
  .ag-icon {
    color: rgb(129, 194, 66);
  }
  .ag-text-field-input .ag-select {
    border-color: rgba(129, 194, 66, 0.7);

    &:focus {
      box-shadow: none;
    }
  }
  .ag-row-even {
    background-color: ${({ theme }) => theme.colors.grid.background};
  }
  .ag-row-odd {
    background-color: ${({ theme }) => theme.colors.grid.highLight};
  }
  .ag-body-viewport .ag-row-animation .ag-layout-normal {
    background-color: ${({ theme }) => theme.colors.grid.background};
  }
  .ag-root-wrapper-body .ag-focus-managed .ag-layout-normall {
    background-color: ${({ theme }) => theme.colors.grid.background};
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grid.background} !important;
  }
  .ag-body-viewport {
    background-color: ${({ theme }) => theme.colors.grid.background};
  }
  .ag-theme-alpine-dark {
    background-color: ${({ theme }) => theme.colors.grid.background} !important;
  }
  .ag-header-cell-text {
    color: ${({ theme }) => theme.colors.navigationText};
  }
  .ag-cell-value {
    color: ${({ theme }) => theme.colors.navigationText};
  }
  .ag-overlay-wrapper .ag-layout-normal .ag-overlay-loading-wrapper {
    display: none;
  }
  .ag-overlay-loading-center {
    background-color: ${({ theme }) => theme.colors.grid.background} !important;
    color: ${({ theme }) => theme.colors.navigationText};
  }
`;
