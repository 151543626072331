import { Button } from 'components/Button';
import { Input as MotionInput } from 'components/Input';
import styled from 'styled-components';

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 93%;
  gap: 30px;

  @media (max-width: 800px), (max-height: 540px) {
    width: 100%;
  }
`;

export const Input = styled(MotionInput)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 8px;
  border: ${({ $readOnly, error, theme }) =>
    `2px ${$readOnly ? 'dotted' : 'solid'} ${
      error ? theme.colors.error : theme.colors.text
    }`};
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : 'all')};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 25px;
  width: 100%;
  height: 100%;
  background: inherit;

  @media (max-width: 800px), (max-height: 540px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    width: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px;
  }
`;

export const MobileInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  width: 95%;
`;
