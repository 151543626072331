import { Button } from 'components/Button';
import { Input } from 'components/Input';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  input[type='number'] {
  }
`;

export const RechargeInput = styled(Input)`
  background-color: transparent;
  border: none;
  text-align: center;
  font-size: 1.5rem !important;
  width: 160px !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineColor};

  color: ${({ theme }) => theme.colors.accent};
`;

export const CurrencyLabel = styled.span`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
`;

export const CleanFieldButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.text};
`;
