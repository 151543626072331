import { Tooltip } from 'components/Tooltip';
import { number, object } from 'prop-types';
import React from 'react';
import { useTransactionStatusListQuery } from 'store/slices/apiSlice/endpoints/transaction';
import { Container, Indicator } from './styles';

export default function TransactionStatusCellRender({
  value: statusId,
  data: { Notes: tooltipMessage, TransactionStatusDesc: statusDesc },
}) {
  const { data: transactionStatusList } = useTransactionStatusListQuery(
    undefined,
    {
      refetchOnFocus: false,
      refetchOnReconnect: false,
    },
  );
  return (
    <Container>
      <Tooltip
        content={`${statusDesc}${tooltipMessage ? `: ${tooltipMessage}` : ''}`}
      >
        <Indicator
          $statusId={statusId}
          className={
            transactionStatusList?.find(
              (status) => status.TransactionStatusId === statusId,
            )?.TransactionStatusIcon
          }
        />
      </Tooltip>
    </Container>
  );
}

TransactionStatusCellRender.propTypes = {
  value: number,
  data: object,
};

TransactionStatusCellRender.defaultProps = {
  value: 0,
  data: null,
};
