import { Tooltip } from 'components/Tooltip';
import { object, string } from 'prop-types';
import React from 'react';
import { Container, Indicator } from './styles';

export default function BusinessCellRender({ value, data }) {
  return (
    <Container>
      {data?.IsBlocked && (
        <Tooltip
          content={`Motivo do bloqueio: ${data.Notes || 'Não informado'}`}
        >
          <Indicator
            $isYes={!data?.IsBlocked}
            className={data.IsBlocked ? 'fas fa-lock' : ''}
          />
        </Tooltip>
      )}
      {value}
    </Container>
  );
}

BusinessCellRender.propTypes = {
  value: string.isRequired,
  data: object.isRequired,
};
