import { FadedContainer } from 'components/FadedContainer';
import React from 'react';
import AuditFilter from './components/AuditFilter';
import AuditResultSection from './components/AuditResultSection';
import { useAudit } from './contexts/AuditContext';
import { Container } from './styles';

export function AuditPage() {
  const { auditFilter } = useAudit();
  return (
    <FadedContainer>
      <Container>
        {auditFilter?.IsActive ? <AuditResultSection /> : <AuditFilter />}
      </Container>
    </FadedContainer>
  );
}
