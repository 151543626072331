import { DataGridButton } from 'components/DataGrid';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import { object, string } from 'prop-types';
import React from 'react';

export function AuditButtonRenderer({ data, topicDesc }) {
  const { setGridRegisterAuditParams } = useBusiness();
  return (
    <DataGridButton
      type="button"
      onClick={() =>
        setGridRegisterAuditParams({
          id:
            topicDesc === 'Business'
              ? data.BusinessId
              : topicDesc === 'Account'
              ? data.AccountId
              : undefined,
          topicDesc,
        })
      }
    >
      <i className="fas fa-clock-rotate-left" />
    </DataGridButton>
  );
}

AuditButtonRenderer.propTypes = {
  data: object.isRequired,
  topicDesc: string.isRequired,
};
