import AuditButton from 'components/AuditButton';
import AuditLogPopup from 'components/AuditLogPopup';
import { BackButton } from 'components/BackButton';
import { FadedContainer } from 'components/FadedContainer';
import { Spinner } from 'components/LoadIndicator/styles';
import { UserCard } from 'components/UserCard';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import useWindowDimension from 'hooks/useWindowDimension';
import { func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAccountStatusListQuery } from 'store/slices/apiSlice/endpoints/account';
import { useGetAuditListQuery } from 'store/slices/apiSlice/endpoints/audit';
import { useDeviceByAccountQuery } from 'store/slices/apiSlice/endpoints/device';
import FieldData from './components/FieldData';
import { FieldText } from './components/FieldData/styles';
import {
  BlockMessage,
  Body,
  Container,
  Header,
  Title,
  WrapperInfos,
} from './styles';

export function ClientForm({ userData, onClose }) {
  const { t } = useTranslation();
  const [showAuditData, setShowAuditData] = useState(false);
  const { data: auditData, isSuccess } = useGetAuditListQuery(
    {
      id: userData?.Mobile || userData?.Email,
      topicDesc: 'sysUser',
    },
    { skip: !userData?.Mobile && !userData?.Email },
  );
  const { timeZone } = useSelector(({ appOptions }) => appOptions);
  const { data: deviceData, isLoading } = useDeviceByAccountQuery(
    userData.AccountId,
    {
      refetchOnMountOrArgChange: true,
      skip: !userData.AccountId,
    },
  );
  const {
    dimensions: { width, height },
  } = useWindowDimension();
  const { data: accountStatusList } = useAccountStatusListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });

  const accountStatusFormatter = (value) =>
    accountStatusList?.find((accStatus) => accStatus.AccountStatusId === value)
      ?.AccountStatusDesc;
  const blockedDateFormatter = (value) => {
    if (!value) {
      return 'Desbloqueada';
    }
    return dateTimeFormatter(value, timeZone);
  };
  const dateTimeFormatter = (value, timeZone) => {
    if (/^[/D]/.test(value)) {
      return value;
    }
    try {
      return timeZone
        ? format(utcToZonedTime(`${value}Z`, timeZone), 'dd/MM/yyyy - HH:mm', {
            timeZone,
          })
        : format(parseISO(value), 'dd/MM/yyyy - HH:mm');
    } catch {
      return value;
    }
  };
  const updateOnFormatter = (value) => {
    if (!value) {
      return 'Nunca atualizado';
    }
    return dateTimeFormatter({ value }, timeZone);
  };

  return (
    <FadedContainer>
      {isSuccess && showAuditData && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setShowAuditData(false)}
          title={`Logs de alteração - Registro: Usuário [${userData?.AccountName}]`}
        />
      )}
      {userData?.AccountId && (
        <AuditButton onClick={() => setShowAuditData(true)} />
      )}
      <Container>
        <Header>
          <BackButton type="button" onClick={onClose} />
        </Header>
        <Body>
          <div className="avatar">
            <UserCard
              user={userData}
              avatarSize={width < 800 || height < 540 ? 40 : 80}
              usernameSize={width < 800 || height < 540 ? '0.9rem' : '1.2rem'}
              mobileSize={width < 800 || height < 540 ? '0.8rem' : '1.2rem'}
              dataInRow={width < 800 || height < 540}
            />
          </div>
          {utcToZonedTime(`${userData?.BlockedUntil}Z`, timeZone) >
            new Date() && (
            <BlockMessage>
              <i className="fas fa-lock" />{' '}
              {`${t('clientForm.bockUntil')} ${blockedDateFormatter(
                userData?.BlockedUntil,
              )} ${t('clientForm.for')} ${userData?.ErrorTimes} ${t(
                'clientForm.wrongAttempts',
              )}`}
            </BlockMessage>
          )}
          <div className="content">
            <WrapperInfos>
              <Title>
                <span>{t('clientForm.accountData')}</span>
              </Title>
              <FieldData
                field={t('clientForm.accountNumber')}
                data={String(userData?.AccountNumber)}
              />
              <FieldData
                field={t('clientForm.status')}
                data={accountStatusFormatter(userData?.AccountStatusId)}
              />
              <FieldData
                field={t('clientForm.createdOn')}
                data={dateTimeFormatter(userData?.CreatedOn, timeZone)}
              />
            </WrapperInfos>

            <WrapperInfos>
              <Title>
                <span>{t('clientForm.mobileData')}</span>
              </Title>
              {isLoading ? (
                <div className="loading">
                  <Spinner className="fas fa-spinner" />
                </div>
              ) : deviceData ? (
                <>
                  <FieldData
                    field={t('clientForm.model')}
                    data={deviceData?.Model}
                  />
                  <FieldData
                    field={t('clientForm.manufacturer')}
                    data={deviceData?.Manufacturer}
                  />
                  <FieldData
                    field={t('clientForm.osVersion')}
                    data={deviceData?.OSVersion}
                  />
                  <FieldData
                    field={t('clientForm.appVersion')}
                    data={deviceData?.AppVersion}
                  />
                  <FieldData
                    field={t('clientForm.updateOn')}
                    data={updateOnFormatter(deviceData?.UpdateOn)}
                  />
                  <FieldData
                    field={t('clientForm.createdOn')}
                    data={dateTimeFormatter(deviceData?.CreatedOn)}
                  />
                </>
              ) : (
                <div className="loading">
                  <FieldText>{t('clientForm.deviceError')}</FieldText>
                </div>
              )}
            </WrapperInfos>
          </div>
        </Body>
      </Container>
    </FadedContainer>
  );
}

ClientForm.propTypes = {
  onClose: func.isRequired,
  userData: shape({
    AccountId: number,
    AccountTypeId: number,
    AccountStatusId: number,
    CreatedOn: string,
    AccountName: string,
    AccountInitials: string,
    Mobile: string,
    MobileVerifiedOn: string,
    BusinessId: string,
    Balance: number,
    Password: string,
    PIN: string,
    ImageUrl: string,
    Status: string,
  }).isRequired,
};
