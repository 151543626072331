import SelectBoxCellRender from 'components/cellRenderers/SelectBoxCellRender';
import { DataGrid } from 'components/DataGrid';
import { Dialog } from 'components/Dialog';
import useWindowDimension from 'hooks/useWindowDimension';
import { usePermissionsPage } from 'pages/PermissionsPage/contexts/PermissionsPageContext';
import { func, string } from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  useLazyGetGroupMenuListQuery,
  usePostGroupMenuPermissionsMutation,
} from 'store/slices/apiSlice/endpoints/permission';
import { FooterButtons, OptionButton, WrapperGrid } from './styles';

// let rowImmutableStore;
function PermissionsDialog({ onClose, title }) {
  const [menuListDraft, setMenuListDraft] = useState([]);
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const { groupSelected } = usePermissionsPage();
  const [getGroupMenuList] = useLazyGetGroupMenuListQuery();
  const [postGroupMenuPermissions] = usePostGroupMenuPermissionsMutation();
  const selectValueFormatter = ({ value }) => (value ? 'Permitir' : 'Negar');

  const rowImmutableStore = useRef();

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Ecrã',
        field: 'MenuCaption',
        flex: dimensions.width > 800 ? 1 : undefined,
        valueFormatter: ({ value }) => t(value),
      },
      {
        headerName: 'Visualizar',
        field: 'Read',
        flex: dimensions.width > 800 ? 1 : undefined,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: SelectBoxCellRender,
        cellEditorParams: {
          values: [true, false],
          cellRenderer: SelectBoxCellRender,
        },
        valueFormatter: selectValueFormatter,
      },
      {
        headerName: 'Incluir',
        field: 'Add',
        flex: dimensions.width > 800 ? 1 : undefined,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: SelectBoxCellRender,
        cellEditorParams: {
          values: [true, false],
          cellRenderer: SelectBoxCellRender,
        },
        valueFormatter: selectValueFormatter,
      },
      {
        headerName: 'Alterar',
        field: 'Update',
        flex: dimensions.width > 800 ? 1 : undefined,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: SelectBoxCellRender,
        cellEditorParams: {
          values: [true, false],
          cellRenderer: SelectBoxCellRender,
        },
        valueFormatter: selectValueFormatter,
      },
      {
        headerName: 'Bloquear',
        field: 'Block',
        flex: dimensions.width > 800 ? 1 : undefined,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: SelectBoxCellRender,
        cellEditorParams: {
          values: [true, false],
          cellRenderer: SelectBoxCellRender,
        },
        valueFormatter: selectValueFormatter,
      },
      {
        headerName: 'Gerir',
        field: 'Admin',
        flex: dimensions.width > 800 ? 1 : undefined,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: SelectBoxCellRender,
        cellEditorParams: {
          values: [true, false],
          cellRenderer: SelectBoxCellRender,
        },
        valueFormatter: selectValueFormatter,
      },
    ],
    [],
  );

  const getRowId = useCallback(({ data }) => data.id, []);

  const onGridReady = useCallback(() => {
    getGroupMenuList(groupSelected.GroupId)
      .unwrap()
      .then((result) => {
        const data = result.map((item, index) => ({ ...item, id: index }));
        rowImmutableStore.current = data;
        setMenuListDraft(rowImmutableStore.current);
      });
  }, []);

  const onCellEditRequest = useCallback(
    (e) => {
      const { data, newValue } = e;
      const { field } = e.colDef;
      let newItem;
      if (field === 'Admin') {
        newItem = {
          ...data,
          [field]: newValue,
          Read: newValue,
          Add: newValue,
          Update: newValue,
          Block: newValue,
        };
      }
      if (field === 'Read') {
        newItem = { ...data, [field]: newValue };
      }
      if (field === 'Add' || field === 'Update' || field === 'Block') {
        newItem = {
          ...data,
          [field]: newValue,
          Read: newValue || data.Read || data.Update || data.Block,
        };
      }

      rowImmutableStore.current = rowImmutableStore.current.map((item) =>
        item.id === data.id ? newItem : item,
      );
      setMenuListDraft(rowImmutableStore.current);
    },
    [rowImmutableStore.current],
  );

  const handleSavePermissions = async () => {
    const permissions = {
      GroupId: groupSelected.GroupId,
      Menus: rowImmutableStore.current.map((item) => ({
        MenuId: item.MenuId,
        Read: item.Read,
        Add: item.Add,
        Update: item.Update,
        Block: item.Block,
        Admin: item.Admin,
      })),
    };
    try {
      const res = await postGroupMenuPermissions(permissions).unwrap();
      if (res.ErrorMsg) {
        toast.error(
          t(`permissionsPage.${res.ErrorMsg}`) ||
            t('permissionsPage.errorSaving'),
        );
        return;
      }
      toast.success(t('permissionsPage.successSaving'));
      onClose();
    } catch (e) {
      toast.error(
        e.data.ErrorMsg || e.message || t('permissionsPage.errorSaving'),
      );
    }
  };

  return (
    <Dialog
      width={dimensions.width > 800 ? '80%' : '100%'}
      height={dimensions.width > 800 ? '80%' : '100%'}
      title={title}
      onClose={onClose}
      closeOnOutsideClick
      showCloseButton
    >
      <WrapperGrid>
        {/* <AgGridReact onCellEdi/> */}
        <DataGrid
          rowData={menuListDraft}
          columnDefs={columnDefs}
          readOnlyEdit
          getRowId={getRowId}
          onCellEditingStopped={onCellEditRequest}
          noRowMessage={t('permissionsPage.noTables')}
          onGridReady={onGridReady}
        />
      </WrapperGrid>
      <FooterButtons>
        <OptionButton onClick={handleSavePermissions} color="green">
          {t('permissionsPage.save')}
        </OptionButton>
        <OptionButton onClick={onClose}>
          {t('permissionsPage.cancel')}
        </OptionButton>
      </FooterButtons>
    </Dialog>
  );
}

PermissionsDialog.propTypes = {
  onClose: func.isRequired,
  title: string.isRequired,
};

PermissionsDialog.defaultProps = {};

export default PermissionsDialog;
