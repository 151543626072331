import AuditLogPopup from 'components/AuditLogPopup';
import BusinessCellRender from 'components/BusinessCellRender';
import YesNoCellRender from 'components/cellRenderers/YesNoCellRender';
import { DataGrid } from 'components/DataGrid';
import { LoadIndicator } from 'components/LoadIndicator';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { AuditButtonRenderer } from 'pages/BusinessPage/components/AuditButtonRenderer';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetAuditListQuery } from 'store/slices/apiSlice/endpoints/audit';
import { useBusinessListQuery } from 'store/slices/apiSlice/endpoints/business';
import { useGetMenuListByUserQuery } from 'store/slices/apiSlice/endpoints/permission';
import { BusinessStatusConfirmDialog } from './components/BusinessStatusConfirmDialog';
// import { BusinessInactivateDialog } from './components/BusinessInactivateDialog';
import {
  AddBusinessButton,
  Container,
  EditBusinessButton,
  GridWrapper,
  Header,
  InactivateBusinessButton,
  // InactivateBusinessButton,
  RowEditButtonsContainer,
  Title,
} from './styles';

export default function BusinessDataGrid() {
  const ACL = usePermissions();
  const [isActiveButtons, setIsActiveButtons] = useState(false);
  const [businessToBlock, setBusinessToBlock] = useState(null);
  const [businessStatusDialogVisible, setBusinessStatusDialogVisible] =
    useState(false);
  const { userData } = useSelector(({ auth }) => auth);
  const {
    gridRegisterAuditParams,
    setGridRegisterAuditParams,
    currentBusinessSelected,
    setCurrentBusinessSelected,
  } = useBusiness();
  useEffect(() => {
    setGridRegisterAuditParams(null);
  }, []);

  const { data: menuList } = useGetMenuListByUserQuery(undefined, {
    skip: !userData,
  });
  const { data: auditData, isFetching: auditIsFetching } = useGetAuditListQuery(
    gridRegisterAuditParams,
    {
      refetchOnMountOrArgChange: true,
      skip: !gridRegisterAuditParams,
    },
  );
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const businessGridRef = useRef();
  const { data: businessList, isLoading: businessListLoading } =
    useBusinessListQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });

  const defaultColumnDefs = useMemo(
    () => ({
      filter: 'agTextColumnFilter',
    }),
    [],
  );

  useEffect(() => {
    if (businessGridRef?.current?.api?.getSelectedNodes()) {
      if (!currentBusinessSelected) {
        const selectedRow = businessGridRef.current.api.getSelectedNodes()[0];
        if (selectedRow) {
          selectedRow.setSelected(false);
          setBusinessToBlock(null);
        }
      }
    }
  }, [currentBusinessSelected]);

  const onRowSelectionChanged = () => {
    const selectedRow = businessGridRef.current.api.getSelectedRows()[0];

    setBusinessToBlock(selectedRow);

    setIsActiveButtons(!!selectedRow);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'NIF',
        field: 'NIF',
        flex: dimensions.width > 800 ? 2 : undefined,
      },
      {
        headerName: t('businessPage.name'),
        field: 'BusinessName',
        flex: dimensions.width > 800 ? 2 : undefined,
        cellRenderer: BusinessCellRender,
      },
      {
        headerName: t('businessPage.acceptPayment'),
        field: 'AcceptPayment',
        flex: dimensions.width > 800 ? 2 : undefined,
        cellRenderer: YesNoCellRender,
      },
      {
        headerName: t('businessPage.offersChange'),
        field: 'AcceptChange',
        flex: dimensions.width > 800 ? 2 : undefined,
        cellRenderer: YesNoCellRender,
      },
      {
        headerName: t('businessPage.employeeBenefit'),
        field: 'EmployeeBenefit',
        flex: dimensions.width > 800 ? 2 : undefined,
        cellRenderer: YesNoCellRender,
      },
      {
        hide:
          !menuList?.length ||
          !!menuList?.find(
            (menu) => menu.Url === 'auditoria' && !menu.AccessControl,
          ),
        flex: dimensions.width > 800 ? 1 : undefined,
        cellRenderer: AuditButtonRenderer,
        cellRendererParams: {
          topicDesc: 'Business',
        },
      },
    ],
    [dimensions, menuList],
  );

  const inactivateButton = useMemo(
    () => (
      <InactivateBusinessButton
        $isBlock={!businessToBlock?.IsBlocked}
        type="button"
        disabled={!isActiveButtons}
        onClick={() =>
          setBusinessStatusDialogVisible((prevState) => !prevState)
        }
      >
        <i
          className={`fa-solid ${
            businessToBlock?.IsBlocked ? 'fa-check' : 'fa-xmark'
          }`}
        />
        {dimensions.width > 800 && (
          <span>
            {businessToBlock?.IsBlocked
              ? t('businessPage.reactivateBusiness')
              : t('businessPage.inactivateBusiness')}
          </span>
        )}
      </InactivateBusinessButton>
    ),
    [isActiveButtons, dimensions.width, businessToBlock],
  );

  const handleBusinessStatusEditClose = () => {
    const selectedRow = businessGridRef.current.api.getSelectedNodes()[0];
    if (selectedRow) {
      selectedRow.setSelected(false);
      setIsActiveButtons(false);
    }
    setBusinessToBlock(null);
    setBusinessStatusDialogVisible(false);
  };

  const handleAddNewBusiness = () => {
    setCurrentBusinessSelected({
      NIF: '',
      BusinessName: '',
      AcceptPayment: false,
      AcceptChange: false,
      EmployeeBenefit: false,
    });

    const selectedRow = businessGridRef.current.api.getSelectedNodes()[0];
    if (selectedRow) {
      selectedRow.setSelected(false);
      setIsActiveButtons(false);
    }
  };

  const handleEditBusiness = () => {
    const selectedBusiness = businessGridRef.current.api.getSelectedNodes()[0];
    setCurrentBusinessSelected({ ...selectedBusiness.data });
  };

  return (
    <Container>
      <LoadIndicator visible={businessListLoading || auditIsFetching} />
      {!auditIsFetching && gridRegisterAuditParams && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setGridRegisterAuditParams(null)}
          title={`Logs de alteração - Registro: Empresa [${businessToBlock?.BusinessName}]`}
        />
      )}
      {businessToBlock && businessStatusDialogVisible && (
        <BusinessStatusConfirmDialog
          business={businessToBlock}
          onClose={handleBusinessStatusEditClose}
        />
      )}
      <Header>
        <RowEditButtonsContainer>
          <EditBusinessButton
            type="button"
            disabled={!isActiveButtons || businessToBlock?.IsBlocked}
            onClick={handleEditBusiness}
          >
            <i className={ACL.Update ? 'fas fa-pen' : 'fas fa-eye'} />
            {dimensions.width > 800 && (
              <span>
                {t(
                  ACL.Update
                    ? 'businessPage.manageBusiness'
                    : 'businessPage.viewBusiness',
                )}
              </span>
            )}
          </EditBusinessButton>
          {ACL.Block && inactivateButton}
        </RowEditButtonsContainer>
        {ACL.Add && (
          <AddBusinessButton type="button" onClick={handleAddNewBusiness}>
            <i className="fas fa-plus" />
            {dimensions.width > 800 && (
              <span>{t('businessPage.addBusiness')}</span>
            )}
          </AddBusinessButton>
        )}
      </Header>

      <GridWrapper>
        <Title>
          <span>{t('businessPage.registeredBusiness')}</span>
        </Title>
        {useMemo(
          () => (
            <DataGrid
              ref={businessGridRef}
              rowData={businessList}
              columnDefs={columnDefs}
              defaultColDef={defaultColumnDefs}
              onSelectionChanged={onRowSelectionChanged}
              rowSelection="single"
            />
          ),
          [businessList],
        )}
      </GridWrapper>
    </Container>
  );
}
