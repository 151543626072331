import { Tooltip } from 'components/Tooltip';
import { func } from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CleanFieldButton,
  Container,
  CurrencyLabel,
  RechargeInput,
} from './styles';

export const ValueInput = forwardRef(({ clearField, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <Container>
      <CurrencyLabel>Kz</CurrencyLabel>
      <RechargeInput ref={ref} {...props} />
      <Tooltip content={t('rechargePage.cleanField')}>
        <CleanFieldButton type="button" onClick={clearField}>
          <i className="fas fa-backspace" />
        </CleanFieldButton>
      </Tooltip>
    </Container>
  );
});

ValueInput.propTypes = {
  clearField: func.isRequired,
};
