import { Button } from 'components/Button';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;

  &.main {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lineColor};
    padding: 30px 0;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.1rem;
    width: 100%;
  }
  p {
    margin-left: 12px;
  }
`;

export const WrapperHeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContentButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  padding: 0 22px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.buttonText};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ color, theme }) =>
    color === 'green' ? theme.colors.accent : theme.colors.error};

  i {
    color: ${({ theme }) => theme.colors.buttonText};
  }

  span {
    font-size: 0.75rem;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const WrapperGrid = styled.div`
  height: calc(100% - 126px);
  width: 100%;
`;

export const Grid = styled.div`
  margin-top: 12px;
  height: calc(100% - 49px);
  width: 100%;
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: fit-content;
  gap: 12px;
`;

export const AddBackOfficeUserButton = styled(Button)`
  width: 60px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.text};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ theme }) => theme.colors.accent};

  i {
    color: ${({ theme }) => theme.colors.buttonText};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;
