import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const ClientContext = createContext();

function ClientProvider({ children }) {
  const [currentClientData, setCurrentClientData] = useState({
    clientData: {
      ClientId: '',
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
      BusinessId: '',
      Password: '',
      CardPIN: '',
      ImageUrl: '',
      Status: '',
    },
    clientIsSelected: false,
  });

  const clientContextValues = useMemo(
    () => ({
      currentClientData,
      setCurrentClientData,
    }),
    [currentClientData],
  );

  return (
    <ClientContext.Provider value={clientContextValues}>
      {children}
    </ClientContext.Provider>
  );
}

ClientProvider.propTypes = {
  children: node.isRequired,
};

const useClient = () => useContext(ClientContext);

export { ClientProvider, useClient };
