import TransactionStatusCellRender from 'components/cellRenderers/TransactionStatusCellRender';
import { DataGrid } from 'components/DataGrid';
import { format, subMonths } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import useWindowDimension from 'hooks/useWindowDimension';
import { useRecharge } from 'pages/RechargePage/context/RechargeContext';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTransactionFilterListQuery } from 'store/slices/apiSlice/endpoints/transaction';
import { currencyFormatter, dateTimeFormatter } from 'utils/formatters';
import { GridContainer, Header, Title } from './styles';

export function LastRechargesGrid() {
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const {
    currentRechargeData: {
      recipientUser: { Mobile },
    },
  } = useRecharge();
  const filter = useMemo(
    () => ({
      Mobile,
      StartDate: format(subMonths(zonedTimeToUtc(new Date()), 3), 'yyyy-MM-dd'),
      EndDate: format(zonedTimeToUtc(new Date()), 'yyyy-MM-dd'),
      TransactionTypeId: 1,
      TransactionStatusId: 0,
    }),
    [Mobile],
  );
  const { timeZone } = useSelector(({ appOptions }) => appOptions);

  const { data: gridData } = useTransactionFilterListQuery(filter, {
    pollingInterval: 60000,
    skip: !filter.Mobile,
  });

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Data',
        field: 'TransactionDate',
        flex: dimensions.width > 800 ? 1 : undefined,
        valueFormatter: (data) => dateTimeFormatter(data, timeZone),
      },
      {
        headerName: 'Tipo',
        field: 'TransactionTypeDesc',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
      {
        headerName: 'Valor',
        field: 'Amount',
        flex: dimensions.width > 800 ? 1 : undefined,
        valueFormatter: currencyFormatter,
      },
      {
        headerName: 'Status',
        field: 'TransactionStatusId',
        flex: dimensions.width > 800 ? 1 : undefined,
        cellRenderer: TransactionStatusCellRender,
      },
    ],
    [],
  );
  return (
    <GridContainer>
      <Header>
        <Title>{t('rechargePage.lastRecharges')}</Title>
        {/* <RefreshGridButton
          type="button"
          onClick={() => {
            forceRefetch();
          }}
        >
          <i className="fa-solid fa-arrows-rotate" />
        </RefreshGridButton> */}
      </Header>
      <DataGrid
        // defaultColDef={defaultColumnDefs}
        rowData={gridData}
        columnDefs={columnDefs}
        noRowMessage="Ainda não forem feitas recargas"
      />
    </GridContainer>
  );
}
