import { Button as BaseButton } from 'components/Button';
import styled from 'styled-components';

export const Button = styled(BaseButton)`
  position: fixed;
  bottom: 25px;
  left: 25px;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lineColor};
  color: ${({ theme }) => theme.colors.text};
  width: 36px;
  height: 36px;

  @media (max-width: 800px) {
    bottom: 25px;
    left: 5px;
  }
`;
