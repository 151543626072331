import { Tooltip } from 'components/Tooltip';
import { number, oneOfType, string } from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { currencyMask } from 'utils/masks';
import {
  CleanFieldButton,
  Container,
  CurrencyLabel,
  RechargeInput,
} from './styles';

export const CellCurrencyInput = forwardRef(
  ({ value: fieldValue, ...props }, ref) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(
      currencyMask({
        value:
          fieldValue === '0' || fieldValue === 0 ? '' : fieldValue?.toString(),
        withCurrencySymbol: false,
      }),
    );
    const refInput = useRef(null);
    useEffect(() => {
      refInput.current?.focus();
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value?.toString()?.replaceAll('.', '').replace(',', '.');
      },

      isCancelBeforeStart() {
        return false;
      },

      isCancelAfterEnd() {
        return false;
      },
    }));

    return (
      <Container>
        <CurrencyLabel>Kz</CurrencyLabel>
        <RechargeInput
          placeholder="0,00"
          ref={refInput}
          value={value}
          onChange={(e) =>
            setValue(
              currencyMask({
                value: e.target.value,
                withCurrencySymbol: false,
              }),
            )
          }
          {...props}
        />
        <Tooltip content={t('rechargePage.cleanField')}>
          <CleanFieldButton type="button" onClick={() => setValue('')}>
            <i className="fas fa-backspace" />
          </CleanFieldButton>
        </Tooltip>
      </Container>
    );
  },
);

CellCurrencyInput.propTypes = {
  value: oneOfType([number, string]),
};

CellCurrencyInput.defaultProps = {
  value: 0,
};
