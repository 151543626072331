/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import appConfig from 'appConfig';
import { apiSlice } from './apiSlice';

const authSlice = createSlice({
  name: '@auth',
  initialState: {
    userData: null,
    userIsSigned: false,
    recoveryEmail: null,
  },
  reducers: {
    authLogout: (draft) => {
      draft.userData = null;
      draft.userIsSigned = false;
    },
    authUpdateToken: (draft, { payload }) => {
      draft.userData = {
        ...draft.userData,
        ...payload,
        LastAvatarUpdate: new Date().getTime(),
      };
      draft.userIsSigned = true;
    },
    authSetRecoveryEmail: (draft, { payload }) => {
      draft.recoveryEmail = payload;
    },
    authClearRecoveryEmail: (draft) => {
      draft.recoveryEmail = null;
    },
    authUserLastAvatarUpdate: (draft) => {
      draft.userData = {
        ...draft.userData,
        LastAvatarUpdate: new Date().getTime(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiSlice.endpoints.userLogin.matchFulfilled,
        (draft, { payload }) => {
          const splittedName = payload?.BackOfficeUser?.FullName?.split(' ');
          draft.userData = {
            ...payload,
            LastAvatarUpdate: new Date().getTime(),
            ImageUrl: `${appConfig.apiUrl}/Images/BackOfficeUser/${payload.UserId}`,
            BackOfficeUser: {
              ...payload.BackOfficeUser,
              Initials: `${splittedName[0]?.charAt(0)}${splittedName[1]?.charAt(
                0,
              )}`,
            },
          };
          draft.userIsSigned = true;
        },
      )
      .addMatcher(apiSlice.endpoints.userLogin.matchRejected, (draft) => {
        draft.userIsSigned = false;
      });
  },
});

export const {
  authLogout,
  authUpdateToken,
  authSetRecoveryEmail,
  authClearRecoveryEmail,
  authUserLastAvatarUpdate,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
