import { useAudit } from 'pages/AuditPage/contexts/AuditContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionDropdownMenu from '../ActionDropdownMenu';
import { PeriodSelector } from '../PeriodSelector';
import TopicDropdownMenu from '../TopicDropdownMenu';
import { FiltersContainer, SearchButton } from './styles';

export default function AuditFilter() {
  const { t } = useTranslation();
  const { setAuditFilter } = useAudit();
  return (
    <FiltersContainer>
      <span>{t('transactionsPage.filterOptions')}</span>
      <div className="filters-container">
        <PeriodSelector />
        <TopicDropdownMenu />
        <ActionDropdownMenu />
      </div>
      <SearchButton
        type="button"
        onClick={() => {
          setAuditFilter((prevState) => ({ ...prevState, IsActive: true }));
        }}
      >
        <i className="fas fa-search" />
        Filtrar
      </SearchButton>
    </FiltersContainer>
  );
}
