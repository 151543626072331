import React from 'react';
import { string } from 'prop-types';
import { Container, InfoDisplay, Title } from './styles';

export default function SimpleInfoCard({ title, infoDesc }) {
  return (
    <Container>
      <Title>{title}</Title>
      <InfoDisplay>{infoDesc}</InfoDisplay>
    </Container>
  );
}

SimpleInfoCard.propTypes = {
  title: string,
  infoDesc: string,
};

SimpleInfoCard.defaultProps = {
  title: '',
  infoDesc: '',
};
