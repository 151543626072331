import appConfig from 'appConfig';
import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    backOfficeUserList: builder.query({
      query: () => ({
        url: '/BackOfficeUser',
        method: 'GET',
      }),
      transformResponse: (response) =>
        response.map((backOfficeUser) => ({
          ...backOfficeUser,
          ImageUrl: `${appConfig.apiUrl}/Images/BackOfficeUser/${backOfficeUser.UserId}`,
        })),
      providesTags: [{ type: 'BackOfficeUser', id: 'LIST' }],
    }),
    backOfficeUserSave: builder.mutation({
      query: (userData) => ({
        url: '/BackOfficeUser',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: [
        { type: 'BackOfficeUser', id: 'LIST' },
        { type: 'Audit', id: 'BackOfficeUser' },
      ],
    }),
    backOfficeImageSave: builder.mutation({
      query: (imageData) => ({
        url: '/Images/BackOfficeUser',
        method: 'POST',
        body: imageData,
      }),
      invalidatesTags: [
        { type: 'BackOfficeUser', id: 'LIST' },
        { type: 'Audit', id: 'BackOfficeUser' },
      ],
    }),
    backOfficeImageDelete: builder.mutation({
      query: (userId) => ({
        url: `/Images/BackOfficeUSer/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'BackOfficeUser', id: 'LIST' },
        { type: 'Audit', id: 'BackOfficeUser' },
      ],
    }),
    backOfficeChangePin: builder.mutation({
      query: (pinChangeData) => ({
        url: '/BackOfficeUser/UpdatePIN',
        method: 'POST',
        body: pinChangeData,
      }),
      invalidatesTags: [
        { type: 'BackOfficeUser', id: 'LIST' },
        { type: 'Audit', id: 'BackOfficeUser' },
      ],
    }),
    updateReportNotification: builder.mutation({
      query: (reportNotificationData) => ({
        url: '/BackOfficeUser/UpdateReportNotification',
        method: 'POST',
        body: reportNotificationData,
      }),
      invalidatesTags: [{ type: 'BackOfficeUser', id: 'LIST' }],
    }),
  }),
});

export const {
  useBackOfficeUserListQuery,
  useBackOfficeUserSaveMutation,
  useBackOfficeImageSaveMutation,
  useBackOfficeImageDeleteMutation,
  useBackOfficeChangePinMutation,
  useUpdateReportNotificationMutation,
} = extendApi;
