import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { decodeToken } from 'utils/decodeToken';
import { isTokenExpired } from 'utils/isTokenExpired';

export function useJWT() {
  const [isJWTExpired, setIsJWTExpired] = useState(false);
  const [decodedJWT, setDecodedJWT] = useState(null);
  const { userData } = useSelector(({ auth }) => auth);

  const evaluateJWT = (token) => {
    setDecodedJWT(decodeToken(token));
    setIsJWTExpired(isTokenExpired(token));
  };

  useEffect(() => {
    evaluateJWT(userData?.Token);
  }, [userData]);

  return { isJWTExpired, decodedJWT, reEvaluateJWT: evaluateJWT };
}
