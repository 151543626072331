import { node } from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const BusinessContext = createContext();

function BusinessProvider({ children }) {
  const [currentBusinessSelected, setCurrentBusinessSelected] = useState(null);
  const [currentSubAccountSelected, setCurrentSubAccountSelected] =
    useState(null);
  const [showEmployeeTab, setShowEmployeeTab] = useState(false);
  const [gridRegisterAuditParams, setGridRegisterAuditParams] = useState(null);

  useEffect(() => {
    if (currentBusinessSelected) {
      setShowEmployeeTab(currentBusinessSelected.EmployeeBenefit);
    }
  }, [currentBusinessSelected]);

  const businessContextValues = useMemo(
    () => ({
      currentBusinessSelected,
      setCurrentBusinessSelected,
      currentSubAccountSelected,
      setCurrentSubAccountSelected,
      showEmployeeTab,
      setShowEmployeeTab,
      gridRegisterAuditParams,
      setGridRegisterAuditParams,
    }),
    [
      currentBusinessSelected,
      currentSubAccountSelected,
      showEmployeeTab,
      gridRegisterAuditParams,
    ],
  );

  return (
    <BusinessContext.Provider value={businessContextValues}>
      {children}
    </BusinessContext.Provider>
  );
}

BusinessProvider.propTypes = {
  children: node.isRequired,
};

const useBusiness = () => useContext(BusinessContext);

export { BusinessProvider, useBusiness };
