import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import { TriggerIcon } from 'components/Accordion/styles';
import { array } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateTimeFormatter } from 'utils/formatters';
import { ComparisonRender } from './ComparisonRender';
import { Container, NoContentWarning } from './styles';

function AuditResult({ data }) {
  const { timeZone } = useSelector(({ appOptions }) => appOptions);
  const { t } = useTranslation();
  return (
    <Container>
      {!data?.length ? (
        <NoContentWarning>Não há dados</NoContentWarning>
      ) : (
        <Accordion
          type="single"
          defaultValue={data?.length ? data[0]?.AuditId?.toString() : ''}
          collapsible
        >
          {data?.map((audit) => (
            <AccordionItem
              key={audit.AuditId}
              value={audit.AuditId}
              disabled={!audit.JSONAfter && !audit.JSONBefore}
            >
              <AccordionTrigger>
                {(!!audit.JSONAfter || !!audit.JSONBefore) && (
                  <TriggerIcon className="fas fa-chevron-down" aria-hidden />
                )}
                {`${t(`audit.${audit.Action}`)} em ${dateTimeFormatter(
                  { value: audit.ChangeDate },
                  timeZone,
                )} por ${audit.Username}`}
              </AccordionTrigger>
              <AccordionContent>
                <ComparisonRender
                  after={audit.JSONAfter}
                  before={audit.JSONBefore}
                />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Container>
  );
}

AuditResult.propTypes = {
  data: array,
};

AuditResult.defaultProps = {
  data: [],
};

export default AuditResult;
