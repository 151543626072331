import { BackButton } from 'components/BackButton';
import { FadedContainer } from 'components/FadedContainer';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { useRecharge } from 'pages/RechargePage/context/RechargeContext';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { currencyMask } from 'utils/masks';
import { UserCard } from '../../../../components/UserCard';
import { ConfirmRechargeDialog } from './components/ConfirmRechargeDialog';
import { LastRechargesGrid } from './components/LastRechargesGrid';
import { ValueInput } from './components/ValueInput';
import { Container, Header, MainContent, RechargeButton } from './styles';

export function ClientRechargeSection() {
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const { t } = useTranslation();
  const { handleSubmit, control, setValue } = useForm();
  const { currentRechargeData, setCurrentRechargeData } = useRecharge();
  const { dimensions } = useWindowDimension();

  const ACL = usePermissions();

  const handleClearField = () => {
    setValue('rechargeValue', '');
  };

  useEffect(() => {
    if (!currentRechargeData.rechargeIsValid) {
      handleClearField();
    }
  }, [currentRechargeData.rechargeIsValid]);

  const handleSendValue = ({ rechargeValue }) => {
    const convertedValue = Number(
      rechargeValue.replaceAll('.', '').replace(',', '.'),
    );

    setCurrentRechargeData((prevState) => ({
      ...prevState,
      rechargeValue: convertedValue,
      rechargeIsValid: convertedValue > 0,
    }));
    setConfirmDialogVisible(true);
  };

  const rechargeButton = useMemo(
    () => (
      <RechargeButton type="submit">
        {t('rechargePage.recharge')}
      </RechargeButton>
    ),
    [currentRechargeData],
  );

  return (
    <FadedContainer>
      <Container onSubmit={handleSubmit(handleSendValue)}>
        <Header>
          <BackButton
            type="button"
            onClick={() =>
              setCurrentRechargeData({
                recipientUser: {
                  FirstName: '',
                  LastName: '',
                  Mobile: '',
                },
                rechargeValue: 0.0,
                recipientUserIsSelected: false,
                rechargeIsValid: false,
              })
            }
          />
        </Header>
        <MainContent>
          <UserCard
            user={currentRechargeData?.recipientUser}
            avatarSize={dimensions.width < 1000 ? 50 : 90}
            usernameSize={dimensions.width < 1000 ? '0.9rem' : '1.2rem'}
            mobileSize={dimensions.width < 1000 ? '0.75rem' : '1rem'}
          />
          {ACL &&
            ACL.Update && [
              <Controller
                control={control}
                name="rechargeValue"
                defaultValue=""
                render={({ field: { onChange, ...props } }) => (
                  <ValueInput
                    placeholder="0,00"
                    onChange={(e) =>
                      onChange(
                        currencyMask({
                          value: e.target.value,
                          withCurrencySymbol: false,
                        }),
                      )
                    }
                    clearField={handleClearField}
                    {...props}
                  />
                )}
              />,
              rechargeButton,
            ]}
        </MainContent>
        {dimensions.height > 600 && <LastRechargesGrid />}
      </Container>
      {confirmDialogVisible && (
        <ConfirmRechargeDialog onClose={() => setConfirmDialogVisible(false)} />
      )}
    </FadedContainer>
  );
}
