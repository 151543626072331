import { Dialog } from 'components/Dialog';
import { ProfileForm } from 'components/ProfileForm';
import { func } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { WrapperForm } from './styles';

export default function ProfileDialog({ onClose, ...props }) {
  const { userData } = useSelector(({ auth }) => auth);
  return (
    <Dialog {...props}>
      <WrapperForm>
        <ProfileForm
          currentUser={{
            ...userData.BackOfficeUser,
            AuthorizePIN: '',
            Password: '',
            ImageUrl: userData.ImageUrl,
          }}
          onClose={onClose}
        />
      </WrapperForm>
    </Dialog>
  );
}

ProfileDialog.propTypes = {
  onClose: func.isRequired,
};
