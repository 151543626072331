import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-height: 100%;
  gap: 20px;
  transition: 0.3s;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 0 6px;

  span {
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.2rem;
  }

  /* @media (max-width: 1000px) {
  } */
  justify-content: space-between;
`;

export const WrapperProfilesTypes = styled.div`
  padding: 0 20px;
  overflow-x: hidden;
  height: 100%;
`;
export const ProfileType = styled(Button)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.backButton : 'transparent'};
  height: 50px;
  width: 100%;
  padding: 0 10px;
  border: none;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.lineColor};
  user-select: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const AddGroupButton = styled(Button)`
  width: 60px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.text};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ theme }) => theme.colors.accent};

  i {
    color: ${({ theme }) => theme.colors.buttonText};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const WrapperList = styled.div`
  gap: 8px;
  flex-direction: column;
  display: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? 'flex' : $isExpanded ? 'flex' : 'none'};
  width: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? '30%' : $isExpanded ? '100%' : '0%'};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.boxBackground};
  border-radius: 15px;
  padding: 12px;
  max-height: 100%;
  transition: 0.3s;
`;

export const WrapperContent = styled.div`
  display: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? 'flex' : $isExpanded ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  flex: ${({ $isMobile, $isExpanded }) =>
    !$isMobile ? 1 : $isExpanded ? 1 : 0};
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.boxBackground};
  color: ${({ theme }) => theme.colors.text};
  flex-direction: column;
  padding: 12px;
  transition: 0.3s;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;

  .title {
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.1rem;
  }
  p {
    margin-left: 12px;
  }
`;

export const WrapperHeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContentButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  padding: 0 22px;
  height: 36px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.buttonText};
  border: ${({ theme }) => theme.colors.accent};
  background-color: ${({ color, theme }) =>
    color === 'green' ? theme.colors.accent : theme.colors.error};

  i {
    color: ${({ theme }) => theme.colors.buttonText};
  }

  span {
    font-size: 0.75rem;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const WrapperGrid = styled.div`
  height: calc(100% - 126px);
  width: 100%;
`;

export const Grid = styled.div`
  margin-top: 12px;
  height: calc(100% - 49px);
  width: 100%;
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: fit-content;
  gap: 12px;
`;
