import {
  DropdownMenuTrigger,
  Root as DropdownMenuRoot,
} from '@radix-ui/react-dropdown-menu';
import { useTransaction } from 'pages/TransactionsPage/contexts/TransactionContext';
import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransactionStatusListQuery } from 'store/slices/apiSlice/endpoints/transaction';
import {
  Container,
  ImageVisibilityButton,
  MenuArrow,
  MenuContent,
  RadioGroup,
  RadioItem,
} from './styles';

export function TransactionStatusDropdownMenu({ readOnly }) {
  const { t } = useTranslation();
  const { data: transactionStatusList, isSuccess } =
    useTransactionStatusListQuery(undefined, {
      refetchOnFocus: false,
      refetchOnReconnect: false,
    });
  const { transactionFilter, setTransactionFilter } = useTransaction();
  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    setMenuOptions(
      transactionStatusList?.map((transactionStatus) => ({
        id: transactionStatus.TransactionStatusId,
        itemDisplay: transactionStatus.TransactionStatusDesc,
        icon: transactionStatus.TransactionStatusIcon,
        currentDisplay: (
          <ImageVisibilityButton $readOnly={readOnly}>
            <i className={transactionStatus.TransactionStatusIcon} />
            <span>{transactionStatus.TransactionStatusDesc}</span>
            {!readOnly && <i className="fas fa-chevron-down" />}
          </ImageVisibilityButton>
        ),
      })),
    );
  }, [readOnly, transactionStatusList]);

  const handleOptionClick = (optionId) => {
    setTransactionFilter((prevState) => ({
      ...prevState,
      TransactionStatusId: optionId,
    }));
  };

  return (
    isSuccess && (
      <Container>
        <span>{t('transactionsPage.status')}</span>
        <DropdownMenuRoot>
          <DropdownMenuTrigger asChild>
            {
              menuOptions?.find(
                (transactionStatus) =>
                  transactionStatus.id ===
                  transactionFilter.TransactionStatusId,
              )?.currentDisplay
            }
          </DropdownMenuTrigger>
          <MenuContent sideOffset={5}>
            <RadioGroup>
              {menuOptions?.map((option) => (
                <RadioItem
                  key={option.id}
                  onClick={() => handleOptionClick(option.id)}
                >
                  <i className={option.icon} />
                  <span>{option.itemDisplay}</span>
                </RadioItem>
              ))}
            </RadioGroup>
            <MenuArrow />
          </MenuContent>
        </DropdownMenuRoot>
      </Container>
    )
  );
}

TransactionStatusDropdownMenu.propTypes = {
  readOnly: bool,
};

TransactionStatusDropdownMenu.defaultProps = {
  readOnly: false,
};
