/* eslint-disable no-unused-vars */
import { format, subMonths } from 'date-fns';
import { convertDateToUtc } from 'utils/convertDateToUtc';
import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    transactionFilterFetch: builder.mutation({
      query: (filter) => ({
        url: '/Transaction/List',
        method: 'POST',
        body: {
          ...filter,
          StartDate: filter.StartDate,
          EndDate: convertDateToUtc(filter.EndDate),
        },
      }),
    }),
    transactionFilterList: builder.query({
      query: (filter) => ({
        url: `/Transaction/List/${filter.Mobile}/${
          filter.StartDate
        }/${convertDateToUtc(filter.EndDate)}/${filter.TransactionTypeId}/${
          filter.TransactionStatusId
        }`,
        method: 'GET',
      }),
    }),
    benefitSend: builder.mutation({
      query: (benefitData) => ({
        url: '/Transaction/SaveEmployeeBenefit',
        method: 'POST',
        body: benefitData,
      }),
      invalidateTags: (_result, _error, arg) =>
        arg?.AccountId
          ? [
              { type: 'Account', id: arg?.BusinessAccountId },
              { type: 'Business', id: arg?.BusinessAccountId },
              { type: 'Audit', id: 'Account' },
              { type: 'Audit', id: 'Transaction' },
            ]
          : ['Account'],
    }),
    transactionSend: builder.mutation({
      query: (transactionData) => ({
        url: '/Transaction/Save',
        method: 'POST',
        body: transactionData,
      }),
      invalidateTags: (result, _error, _arg) =>
        result?.AccountId
          ? [
              { type: 'Account', id: result?.AccountId },
              { type: 'Business', id: result?.AccountId },
              { type: 'Audit', id: 'Account' },
              { type: 'Audit', id: 'Transaction' },
            ]
          : ['Account'],
      async onQueryStarted({ Mobile, ...patch }, { dispatch, queryFulfilled }) {
        const filter = {
          Mobile,
          StartDate: format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
          EndDate: format(new Date(), 'yyyy-MM-dd'),
          TransactionTypeId: 1,
          TransactionStatusId: 0,
        };
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'transactionFilterList',
            filter,
            (draft) => {
              draft.unshift({
                ...patch,
                TransactionDate: 'Agora',
                TransactionTypeDesc: 'Recarga',
                TransactionStatusDesc: 'Pendente',
                TransactionStatusId: 3,
                TransactionTypeId: 1,
              });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    transactionStatusList: builder.query({
      query: () => ({
        url: '/TransactionStatus',
        method: 'GET',
      }),
      keepUnusedDataFor: 1800,
      transformResponse: (statusList) => [
        {
          TransactionStatusId: 0,
          TransactionStatusDesc: 'Todos',
          TransactionStatusIcon: 'fa-solid fa-asterisk',
        },
        ...statusList,
      ],
    }),
    transactionTypeList: builder.query({
      query: () => ({
        url: '/TransactionType',
        method: 'GET',
      }),
      keepUnusedDataFor: 1800,
      transformResponse: (typeList) => [
        {
          TransactionTypeId: 0,
          TransactionTypeDesc: 'Todos',
          TransactionTypeIcon: 'fa-solid fa-asterisk',
        },
        ...typeList,
      ],
    }),
  }),
});

export const {
  useTransactionFilterFetchMutation,
  useBenefitSendMutation,
  useTransactionSendMutation,
  useTransactionStatusListQuery,
  useTransactionTypeListQuery,
  useTransactionFilterListQuery,
} = extendApi;
