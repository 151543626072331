import { yupResolver } from '@hookform/resolvers/yup';
import appConfig from 'appConfig';
import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import useWindowDimension from 'hooks/useWindowDimension';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import { func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  useAccountBusinessCreateMutation,
  useAccountUpdatePINMutation,
  useLazyGetAccountByMobileQuery,
} from 'store/slices/apiSlice/endpoints/account';
import { phoneMask } from 'utils/masks';
import { phoneNumberType } from 'utils/phoneNumberType';
import * as yup from 'yup';
import { FlagDropdownMenu } from './components/FlagDropdownMenu';
import {
  Body,
  ButtonsContainer,
  ContainerForm,
  FormButton,
  Input,
  MobileInputContainer,
} from './styles';

const SubAccountEditPopup = ({ onClose }) => {
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const { currentSubAccountSelected, currentBusinessSelected } = useBusiness();
  const isNewAccount = useMemo(
    () => !currentSubAccountSelected?.AccountId,
    [currentSubAccountSelected],
  );
  const [accountBusinessCreate] = useAccountBusinessCreateMutation();
  const [accountUpdatePIN] = useAccountUpdatePINMutation();
  const [getAccountByMobile] = useLazyGetAccountByMobileQuery();
  const [mobileCountry, setMobileCountry] = useState(1);
  const newAccountSchema = yup.object({
    AccountName: yup
      .string()
      .required(t('subAccountsForm.accountNameRequired')),
    Mobile: yup
      .string()
      .required(t('subAccountsForm.mobileRequired'))
      .matches(phoneNumberType(mobileCountry).regex, {
        message: 'Telemóvel fora do padrão',
      }),
    PIN: yup
      .string()
      .required(t('subAccountsForm.pinRequired'))
      .length(4, t('subAccountsForm.pinSizeMustBeFour')),
    PINConfirmation: yup
      .string()
      .oneOf([yup.ref('PIN'), null], t('subAccountsForm.pinMustMatch')),
  });
  const editAccountSchema = yup.object({
    PIN: yup.string(),
    OldPIN: yup
      .string()
      .required(t('subAccountsForm.pinRequired'))
      .length(4, t('subAccountsForm.pinSizeMustBeFour')),
    // .oneOf([yup.ref('PIN')], t('subAccountsForm.notOlderPin')),
    NewPIN: yup
      .string()
      .required(t('subAccountsForm.pinRequired'))
      .length(4, t('subAccountsForm.pinSizeMustBeFour')),
    PINConfirmation: yup
      .string()
      .oneOf([yup.ref('NewPIN'), null], t('subAccountsForm.pinMustMatch')),
  });
  const { control, setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(isNewAccount ? newAccountSchema : editAccountSchema),
  });
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (currentSubAccountSelected) {
      Object.keys(currentSubAccountSelected).forEach((keyName) => {
        if (keyName === 'Mobile') {
          setValue(
            keyName,
            phoneMask({
              value: currentSubAccountSelected[keyName],
              countryCode:
                currentSubAccountSelected.MobileDDI === '55'
                  ? 3
                  : currentBusinessSelected.MobileDDI === '351'
                  ? 2
                  : 1,
            }),
          );
        } else {
          setValue(keyName, currentSubAccountSelected[keyName] || '');
        }
      });

      switch (currentSubAccountSelected.MobileDDI) {
        case '244':
          setMobileCountry(1);
          break;
        case '351':
          setMobileCountry(2);
          break;
        case '55':
          setMobileCountry(3);
          break;

        default:
          setMobileCountry(1);
          break;
      }
    }
  }, [currentSubAccountSelected]);

  const getControlledComponent = (
    name,
    label,
    error,
    isActive = false,
    isReadOnly = false,
    isPassword = false,
  ) => (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, ...props } }) => (
        <Input
          label={label}
          $readOnly={isReadOnly}
          onChange={
            name === 'Mobile'
              ? (e) =>
                  onChange(
                    phoneMask({
                      value: e.target.value,
                      countryCode: mobileCountry,
                    }),
                  )
              : onChange
          }
          isActive={isActive}
          error={error}
          isPasswordField={isPassword}
          width="93%"
          {...props}
        />
      )}
    />
  );

  const handleAccountSave = async (accountData) => {
    // New Account
    if (isNewAccount) {
      const existsAccountData = await getAccountByMobile(
        accountData.Mobile,
        false,
      ).unwrap();
      if (existsAccountData?.AccountId) {
        toast.error('Já existe uma conta cadastrada para esse telemóvel');
        return;
      }
      const newAccount = {
        BusinessId: currentBusinessSelected.BusinessId,
        MainAccount: false,
        AccountName: accountData.AccountName,
        Mobile: accountData.Mobile,
        MobileDDI:
          mobileCountry === 3 ? '55' : mobileCountry === 2 ? '351' : '244',
        PIN: accountData?.PIN,
      };

      try {
        const res = await accountBusinessCreate(newAccount).unwrap();

        if (res.ErrorMsg) {
          throw new Error(res.ErrorMsg);
        }

        toast.success('Conta criada com sucesso');
        onClose();
      } catch (e) {
        toast.error(
          e?.message || e?.data?.ErrorMsg || 'Erro ao tentar criar a conta',
        );
      }
      return;
    }

    // Edit Account
    try {
      const res = await accountUpdatePIN({
        ...accountData,
        PIN: accountData?.NewPIN,
        BusinessId: currentBusinessSelected.BusinessId,
      }).unwrap();

      if (res.ErrorMsg) {
        toast.error(res.ErrorMsg);
        return;
      }

      toast.success('Conta alterada com sucesso');
      onClose();
    } catch {
      toast.error('Erro ao tentar alterar a conta');
    }
  };

  return (
    <Dialog
      width={dimensions.width > 600 ? '600px' : '100%'}
      title={
        currentSubAccountSelected?.AccountId
          ? t('subAccountsForm.changePin')
          : t('subAccountsForm.newAccount')
      }
    >
      <ContainerForm onSubmit={handleSubmit(handleAccountSave)}>
        <LoadIndicator visible={isSubmitting} />
        <Body>
          {getControlledComponent(
            'AccountName',
            t('subAccountsForm.accountName'),
            errors.AccountName,
            !!currentSubAccountSelected?.AccountName,
            !isNewAccount,
            // currentSubAccountSelected?.AccountId?.toString()
            //   .replace('[', '')
            //   .replace(']', '') ===
            //   currentBusinessSelected?.MainAccountId?.toString(),
          )}
          <MobileInputContainer>
            <FlagDropdownMenu
              value={mobileCountry}
              setValue={setMobileCountry}
              readOnly={!appConfig.isDev || !isNewAccount}
            />
            {getControlledComponent(
              'Mobile',
              t('generalWords.mobile'),
              errors.Mobile,
              !!currentSubAccountSelected?.Mobile,
              !isNewAccount,
            )}
          </MobileInputContainer>
          {getControlledComponent(
            isNewAccount ? 'PIN' : 'OldPIN',
            t(isNewAccount ? 'generalWords.pin' : 'subAccountsForm.oldPin'),
            isNewAccount ? errors?.PIN : errors?.OldPIN,
            !!currentSubAccountSelected[isNewAccount ? 'PIN' : 'OldPIN'],
            false,
            true,
          )}
          {!isNewAccount &&
            getControlledComponent(
              'NewPIN',
              t('subAccountsForm.newPin'),
              errors.NewPIN,
              !!currentSubAccountSelected?.NewPIN,
              false,
              true,
            )}
          {getControlledComponent(
            'PINConfirmation',
            t('generalWords.pinConfirmation'),
            errors.PINConfirmation,
            !!currentSubAccountSelected?.PINConfirmation,
            false,
            true,
          )}
        </Body>
        <ButtonsContainer>
          <FormButton type="submit">{t('generalWords.ok')}</FormButton>
          <FormButton $isCancel onClick={onClose}>
            {t('generalWords.cancel')}
          </FormButton>
        </ButtonsContainer>
      </ContainerForm>
    </Dialog>
  );
};

SubAccountEditPopup.propTypes = {
  onClose: func.isRequired,
};

export default SubAccountEditPopup;
