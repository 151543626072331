import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { dindimIcon, dindimIconDark } from 'assets';
import { AppLogo } from 'components/AppLogo';
import { LoadIndicator } from 'components/LoadIndicator';
import useWindowDimension from 'hooks/useWindowDimension';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForgottenPasswordMutation } from 'store/slices/apiSlice/endpoints/sysUser';
import { useUserLoginMutation } from 'store/slices/apiSlice/endpoints/user';
import { authSetRecoveryEmail } from 'store/slices/authSlice';
import * as yup from 'yup';
import {
  ButtonsContainer,
  FieldsContainer,
  ForgotButton,
  HeaderContainer,
  Input,
  LoginButton,
  LogoImage,
  MainForm,
  Title,
} from './styles';

export const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userLogin] = useUserLoginMutation();
  const [forgottenPassword] = useForgottenPasswordMutation();
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loginFormSchema = yup.object({
    Username: yup
      .string()
      .email(t('loginScreen.emailInvalid'))
      .required(t('loginScreen.emailRequired')),
    Password: yup
      .string()
      .min(6, t('loginScreen.passwordMinLength'))
      .required(t('loginScreen.passwordRequired')),
  });
  const { theme: currentTheme } = useSelector(({ appOptions }) => appOptions);

  const { register, formState, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(loginFormSchema),
  });

  const { errors, isSubmitting } = formState;

  const handleLogin = async (values) => {
    try {
      await userLogin(values).unwrap();
      const previousLocation = location.state?.from?.pathname;

      navigate(
        previousLocation && previousLocation !== '/'
          ? previousLocation
          : '/home',
      );
    } catch (error) {
      toast(error?.data?.ErrorMsg, { type: 'error' });
    }
  };

  const handlePasswordRecovery = async () => {
    const currentEmail = getValues('Username');
    if (!currentEmail) {
      toast('Informe seu email', { type: 'error' });
      return;
    }

    try {
      setIsLoading(true);
      await forgottenPassword(currentEmail).unwrap();
      dispatch(authSetRecoveryEmail(currentEmail));
      navigate(`/recuperacao`);
    } catch (e) {
      toast(e?.data?.ErrorMsg || 'Utilizador não cadastrado', {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const usernameRegister = register('Username');
  const passwordRegister = register('Password');

  return (
    <MainForm onSubmit={handleSubmit(handleLogin)}>
      <LoadIndicator visible={isSubmitting || isLoading} />
      <HeaderContainer>
        {dimensions.width > 800 ? (
          <>
            <AppLogo />
            <Title>Back Office</Title>
          </>
        ) : (
          <LogoImage
            src={currentTheme === 'dark' ? dindimIcon : dindimIconDark}
            alt="dindim icon"
          />
        )}
      </HeaderContainer>
      <FieldsContainer>
        <Input
          label={t('generalWords.email')}
          error={errors.Username}
          disabled={isSubmitting}
          {...usernameRegister}
        />
        <Input
          label={t('generalWords.password')}
          type="password"
          isPasswordField
          error={errors.Password}
          disabled={isSubmitting}
          {...passwordRegister}
        />
      </FieldsContainer>
      <ButtonsContainer>
        <LoginButton
          type="submit"
          disabled={!!errors?.Password || !!errors?.Username || isSubmitting}
        >
          {isSubmitting ? t('loginScreen.accessing') : t('loginScreen.access')}
        </LoginButton>
        <ForgotButton type="button" onClick={handlePasswordRecovery}>
          {t('loginScreen.forgotPassword')}
        </ForgotButton>
      </ButtonsContainer>
    </MainForm>
  );
};
