import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadIndicator } from 'components/LoadIndicator';
import { Tooltip } from 'components/Tooltip';
import { useTransaction } from 'pages/TransactionsPage/contexts/TransactionContext';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLazyGetAccountByMobileQuery } from 'store/slices/apiSlice/endpoints/account';
import { useTransactionFilterFetchMutation } from 'store/slices/apiSlice/endpoints/transaction';
import { updateCountryId } from 'store/slices/appOptionsSlice';
import { phoneMask } from 'utils/masks';
import { phoneNumberType } from 'utils/phoneNumberType';
import * as yup from 'yup';
import { FlagDropdownMenu } from './FlagDropdownMenu';
import { FieldContainer, SearchButton, SearchInput } from './styles';

export function PhoneSearchBox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { transactionFilter, setClientTransactionData } = useTransaction();
  const [getAccountByMobile] = useLazyGetAccountByMobileQuery({
    refetchOnFocus: false,
  });
  const [transactionFilterFetch, { isLoading }] =
    useTransactionFilterFetchMutation();
  const { countryId } = useSelector(({ appOptions }) => appOptions);
  const phoneRegex = phoneNumberType(countryId).regex;

  const phoneSearchSchema = useMemo(
    () =>
      yup.object({
        phoneNumber: yup
          .string()
          .required('Informe um telemóvel')
          .matches(phoneRegex, {
            message: 'Telemóvel fora do padrão',
          }),
      }),
    [countryId],
  );
  const { register, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(phoneSearchSchema),
  });
  const { errors } = formState;

  const handleTransactionsFetch = async (phoneNumber) => {
    const { data: clientData } = await getAccountByMobile(phoneNumber);
    try {
      if (!clientData) {
        toast(t('transactionsPage.phoneNotFind'), {
          type: 'error',
        });
        return;
      }
      const resTransaction = await transactionFilterFetch({
        ...transactionFilter,
        Mobile: phoneNumber?.replace('+', '').replaceAll(' ', ''),
      }).unwrap();
      if (!resTransaction.length) {
        toast(t('transactionsPage.filterNoResult'), {
          type: 'info',
        });
        return;
      }
      setClientTransactionData((prevState) => ({
        ...prevState,
        transactions: [...resTransaction],
      }));
    } catch (e) {
      toast(t('transactionsPage.filterError'), { type: 'error' });
      return;
    }

    try {
      setClientTransactionData((prevState) => ({
        ...prevState,
        client: { ...clientData },
      }));
    } catch (e) {
      toast(e.message, { type: 'error' });
    }
  };

  const onSubmit = useCallback(
    ({ phoneNumber }) => {
      let editedPhoneNumber;
      switch (countryId) {
        case 1:
        case 2:
          editedPhoneNumber = `${phoneNumber.replaceAll(' ', '')}`;
          break;
        case 3:
          editedPhoneNumber = `${phoneNumber
            .replaceAll(' ', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('-', '')}`;
          break;

        default:
          editedPhoneNumber = `${phoneNumber.replaceAll(' ', '')}`;
          break;
      }
      handleTransactionsFetch(editedPhoneNumber);
    },
    [countryId, transactionFilter],
  );

  return (
    <FieldContainer onSubmit={handleSubmit(onSubmit)}>
      <LoadIndicator visible={isLoading} />
      <FlagDropdownMenu
        value={countryId}
        setValue={(id) => dispatch(updateCountryId(id))}
      />
      <Tooltip content="Digite apenas números">
        <SearchInput
          type="tel"
          width="160px"
          placeholder={phoneNumberType(countryId).placeholder}
          error={errors.phoneNumber}
          {...register('phoneNumber', {
            onChange: (e) => {
              e.target.value = phoneMask({
                value: e.target.value,
                countryCode: countryId,
              });
              return e;
            },
          })}
        />
      </Tooltip>
      <Tooltip content={t('rechargePage.find')}>
        <SearchButton whileTap={{ scale: 0.95 }} type="submit">
          <i className="fas fa-search" />
        </SearchButton>
      </Tooltip>
    </FieldContainer>
  );
}
