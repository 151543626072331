import { FadedContainer } from 'components/FadedContainer';
import React, { useMemo } from 'react';
import { ClientForm } from './components/ClientForm';
import PhoneSearch from './components/PhoneSearch';
import { useClient } from './context/ClientContext';
import { Container } from './styles';

export function ClientsPage() {
  const { currentClientData, setCurrentClientData } = useClient();
  const handleClose = () => {
    setCurrentClientData({
      clientData: {
        ClientId: '',
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        BusinessId: '',
        Password: '',
        CardPIN: '',
        ImageUrl: '',
        Status: '',
      },
      clientIsSelected: false,
    });
  };

  const clientForm = useMemo(
    () => (
      <ClientForm
        readOnly
        userData={currentClientData?.clientData}
        onClose={handleClose}
      />
    ),
    [currentClientData.clientData],
  );

  return (
    <FadedContainer>
      <Container>
        {currentClientData?.clientIsSelected ? clientForm : <PhoneSearch />}
      </Container>
    </FadedContainer>
  );
}
