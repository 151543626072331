/* eslint-disable no-unused-vars */
import { apiSlice } from '..';

const extendApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    businessSave: builder.mutation({
      query: (businessData) => ({
        url: '/Business',
        method: 'POST',
        body: businessData,
      }),
      invalidatesTags: (result, _error, _arg) =>
        result?.BusinessId
          ? [
              { type: 'Business', id: `BusinessId: ${result.BusinessId}` },
              { type: 'Audit', id: 'Business' },
              { type: 'Audit', id: 'BusinessAccount' },
              { type: 'Business', id: 'LIST' },
            ]
          : ['Business'],
    }),
    businessSaveComplete: builder.mutation({
      query: (businessData) => ({
        url: '/Business/CreateComplete',
        method: 'POST',
        body: businessData,
      }),
      invalidatesTags: (result, _error, _arg) =>
        result?.BusinessId
          ? [
              { type: 'Business', id: `BusinessId: ${result.BusinessId}` },
              { type: 'Audit', id: 'Business' },
              { type: 'Audit', id: 'BusinessAccount' },
            ]
          : [{ type: 'Business', id: 'LIST' }],
    }),
    businessList: builder.query({
      query: () => ({
        url: `/Business`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Business', id: 'LIST' }],
    }),
    businessById: builder.query({
      query: (businessId) => ({
        url: `/Business/${businessId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, _arg) =>
        result?.BusinessId
          ? [{ type: 'Business', id: `BusinessId: ${result.BusinessId}` }]
          : [{ type: 'Business', id: 'LIST' }],
    }),
    businessByNif: builder.query({
      query: (businessNif) => ({
        url: `/Business/ListByNIF/${businessNif}`,
        method: 'GET',
      }),
    }),
    businessComplete: builder.query({
      query: (businessId) => ({
        url: `/Business/ListComplete/${businessId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, _arg) =>
        result?.Business?.BusinessId
          ? [
              {
                type: 'Business',
                id: `BusinessId: ${result?.Business?.BusinessId}`,
              },
              {
                type: 'Business',
                id: `MainAccountId: ${result?.Business?.MainAccountId}`,
              },
            ]
          : [{ type: 'Business', id: 'LIST' }],
    }),
    businessEmployeeList: builder.query({
      query: (businessId) => ({
        url: `/BusinessEmployee/ListEmployee/${businessId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        { type: 'Business', id: `EmployeeList: ${args}` },
      ],
    }),
    businessEmployeeImportFromXls: builder.mutation({
      query: ({ xlsFile }) => ({
        url: '/BusinessEmployee/ImportFromXLS',
        method: 'POST',
        body: xlsFile,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Business', id: `EmployeeList: ${args.businessId}` },
        { type: 'Audit', id: 'Business' },
        { type: 'Audit', id: 'BusinessEmployee' },
      ],
    }),
    businessEmployeeSave: builder.mutation({
      query: (businessEmployeeData) => ({
        url: '/BusinessEmployee',
        method: 'POST',
        body: businessEmployeeData,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Business', id: `EmployeeList: ${args.BusinessId}` },
        { type: 'Audit', id: 'Business' },
        { type: 'Audit', id: 'BusinessEmployee' },
      ],
    }),
    businessEmployeeRemove: builder.mutation({
      query: ({ BusinessId, AccountId }) => ({
        url: `/BusinessEmployee/${BusinessId}/${AccountId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Business', id: `EmployeeList: ${args.BusinessId}` },
        { type: 'Audit', id: 'Business' },
        { type: 'Audit', id: 'BusinessEmployee' },
      ],
    }),
    businessUpdateIsBlocked: builder.mutation({
      query: (blockedUpdateData) => ({
        url: '/Business/UpdateIsBlocked',
        method: 'POST',
        body: blockedUpdateData,
      }),
      invalidatesTags: [
        { type: 'Business', id: 'LIST' },
        { type: 'Audit', id: 'Business' },
        { type: 'Audit', id: 'BusinessAccount' },
      ],
    }),
  }),
});

export const {
  useBusinessSaveMutation,
  useBusinessSaveCompleteMutation,
  useBusinessListQuery,
  useBusinessByIdQuery,
  useBusinessByNifQuery,
  useLazyBusinessByNifQuery,
  useBusinessCompleteQuery,
  useBusinessEmployeeImportFromXlsMutation,
  useBusinessEmployeeSaveMutation,
  useBusinessEmployeeRemoveMutation,
  useBusinessEmployeeListQuery,
  useLazyBusinessEmployeeListQuery,
  useBusinessUpdateIsBlockedMutation,
} = extendApi;
