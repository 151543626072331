import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import appConfig from 'appConfig';

export const baseQuery = fetchBaseQuery({
  baseUrl: appConfig.apiUrl,
  prepareHeaders: (headers, { getState }) => {
    const { userData } = getState().auth;
    if (userData) {
      const token = userData.Token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
    }
    return headers;
  },
});
