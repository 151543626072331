import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import { format } from 'date-fns';
import { t } from 'i18next';
import { useRecharge } from 'pages/RechargePage/context/RechargeContext';
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTransactionSendMutation } from 'store/slices/apiSlice/endpoints/transaction';
import { rechargeInsertOnList } from 'store/slices/rechargeSlice';
import { v4 as getUuid } from 'uuid';
import {
  ButtonsContainer,
  ConfirmationContainer,
  ContainerForm,
  DialogButton,
  InputContainer,
  PasswordInput,
} from './styles';

export function ConfirmRechargeDialog({ onClose, ...props }) {
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const {
    userData: {
      UserId: loggedUserId,
      BackOfficeUser: { FullName: loggedPersonFullName },
    },
  } = useSelector(({ auth }) => auth);
  const { currentRechargeData, setCurrentRechargeData } = useRecharge();
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue, setFocus } = useForm();
  const [transactionSend, { isLoading }] = useTransactionSendMutation();

  useEffect(() => {
    if (currentRechargeData?.rechargeIsValid) {
      setFocus('confirmationPassword');
    }
  }, []);

  const confirmRecharge = async (values) => {
    const { confirmationPassword } = values;
    try {
      const newTransaction = {
        Mobile: currentRechargeData.recipientUser.Mobile,
        TransactionId: getUuid(),
        TransactionDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        TransactionTypeId: 1,
        AccountId: currentRechargeData.recipientUser.AccountId,
        Amount: currentRechargeData.rechargeValue,
        Notes: `por ${loggedPersonFullName}`,
        PIN: confirmationPassword,
      };
      const transactionRes = await transactionSend(newTransaction).unwrap();
      if (transactionRes?.ErrorMsg) {
        throw new Error(transactionRes.ErrorMsg);
      }
      dispatch(
        rechargeInsertOnList({
          loggedUserId,
          rechargeData: {
            rechargeDateTime: format(new Date(), 'dd/MM - HH:mm'),
            rechargeRecipient: `${
              currentRechargeData?.recipientUser?.AccountName ||
              currentRechargeData?.recipientUser?.FullName
            }`,
            rechargeValue: `Kz ${currentRechargeData.rechargeValue
              .toFixed(2)
              .toString()
              .replace('.', ',')}`,
          },
        }),
      );
      setCurrentRechargeData((prevState) => ({
        ...prevState,
        rechargeValue: 0,
        rechargeIsValid: false,
      }));
      toast('Recarga em processamento.', { type: 'success' });
      onClose();
    } catch (error) {
      toast(
        error?.data?.ErrorMsg ||
          'Erro ao realizar a recarga. Tente novamente mais tarde.',
        {
          type: 'error',
        },
      );
      setValue('confirmationPassword', '');
      setConfirmationPassword();
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    setConfirmationPassword(value);
    return e;
  };

  // if (isLoading) {
  //   return <LoadIndicator visible={isLoading} />;
  // }

  return (
    <Dialog
      width={400}
      height={300}
      title={currentRechargeData?.rechargeIsValid ? 'Confirmação' : 'Atenção!'}
      {...props}
    >
      <ContainerForm onSubmit={handleSubmit(confirmRecharge)}>
        <LoadIndicator visible={isLoading} />
        <ConfirmationContainer>
          <span>
            {currentRechargeData?.rechargeIsValid
              ? `Confirmar recarga no valor de Kz ${currentRechargeData?.rechargeValue
                  ?.toFixed(2)
                  ?.toString()
                  ?.replace('.', ',')} para ${
                  currentRechargeData?.recipientUser?.AccountName ||
                  currentRechargeData?.recipientUser?.FullName
                }?`
              : 'Por favor, informe um valor de recarga válido'}
          </span>
          {currentRechargeData?.rechargeIsValid && (
            <InputContainer>
              <Controller
                control={control}
                name="confirmationPassword"
                defaultValue=""
                render={({ field: { onChange, ...props } }) => (
                  <PasswordInput
                    label={t('generalWords.pin')}
                    isPasswordField
                    onChange={(e) => onChange(handleInputChange(e))}
                    {...props}
                  />
                )}
              />
            </InputContainer>
          )}
        </ConfirmationContainer>
        <ButtonsContainer>
          <DialogButton
            type={currentRechargeData?.rechargeIsValid ? 'submit' : 'button'}
            onClick={currentRechargeData?.rechargeIsValid ? undefined : onClose}
            disabled={
              (currentRechargeData?.rechargeIsValid
                ? !confirmationPassword || confirmationPassword?.length < 4
                : false) || isLoading
            }
          >
            OK
          </DialogButton>
          {currentRechargeData?.rechargeIsValid && (
            <DialogButton type="button" $isCancel onClick={onClose}>
              Cancelar
            </DialogButton>
          )}
        </ButtonsContainer>
      </ContainerForm>
    </Dialog>
  );
}

ConfirmRechargeDialog.propTypes = {
  onClose: func,
};

ConfirmRechargeDialog.defaultProps = {
  onClose: () => {},
};
