import { DataGrid } from 'components/DataGrid';
import useWindowDimension from 'hooks/useWindowDimension';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GridContainer, Title } from './styles';

export default function HistoryGrid() {
  const { t } = useTranslation();
  const {
    userData: { UserId: loggedUserId },
  } = useSelector(({ auth }) => auth);
  const { rechargeList } = useSelector(({ recharge }) => recharge);
  const { dimensions } = useWindowDimension();

  const columnDefs = useMemo(
    () => [
      {
        headerName: t('rechargePage.rechargeDate'),
        field: 'rechargeDateTime',
        flex: dimensions.width > 800 ? 2 : undefined,
      },
      {
        headerName: t('rechargePage.recipient'),
        field: 'rechargeRecipient',
        flex: dimensions.width > 800 ? 2 : undefined,
      },
      {
        headerName: t('rechargePage.value'),
        field: 'rechargeValue',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
    ],
    [dimensions.width],
  );

  return (
    <GridContainer>
      <Title>{t('rechargePage.history')}</Title>
      <DataGrid
        rowData={rechargeList[loggedUserId] || []}
        columnDefs={columnDefs}
        noRowMessage={t('rechargePage.noRechargeYet')}
      />
    </GridContainer>
  );
}
