import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import ConstantsHandle from 'components/ConstantsHandle';
import { i18n } from 'locales/i18n';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from 'routes';
import { HandleCache } from './components/HandleCache';
import { persistor, store } from './store';

function App() {
  return (
    <HandleCache>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <ConstantsHandle>
              <Router>
                <Routes />
              </Router>
            </ConstantsHandle>
            <ToastContainer
              position="top-right"
              theme="colored"
              autoClose={5000}
              closeOnClick
              pauseOnHover
              draggable
            />
          </I18nextProvider>
        </PersistGate>
      </ReduxProvider>
    </HandleCache>
  );
}

export default App;
