import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Dialog } from 'components/Dialog';
import { LoadIndicator } from 'components/LoadIndicator';
import { bool, func, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useBusinessUpdateIsBlockedMutation } from 'store/slices/apiSlice/endpoints/business';
import * as yup from 'yup';
import {
  ButtonsContainer,
  ConfirmationContainer,
  ContainerForm,
  DialogButton,
  InputContainer,
  PasswordInput,
  TextArea,
} from './styles';

export function BusinessStatusConfirmDialog({ business, onClose }) {
  const { t } = useTranslation();
  const [businessUpdateIsBlocked] = useBusinessUpdateIsBlockedMutation();
  const confirmSchema = yup.object({
    PIN: yup
      .string()
      .required(t('subAccountsForm.pinRequired'))
      .length(4, t('subAccountsForm.pinSizeMustBeFour')),
    Notes: yup.string().required(t('businessStatusConfirm.notesRequired')),
  });
  const { handleSubmit, register, setFocus, setValue, formState } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(confirmSchema),
  });
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    setFocus('Notes');
  }, []);

  useEffect(() => {
    if (errors.PIN) {
      setValue('PIN', '');
      setFocus('PIN');
    }
    if (errors.Notes) {
      toast.error(t(errors.Notes?.message));
      setFocus('Notes');
    }
  }, [errors]);

  const handleBlockBusiness = async ({ PIN, Notes }) => {
    try {
      const blockBusinessData = {
        BusinessId: business?.BusinessId,
        IsBlocked: !business?.IsBlocked,
        Notes,
        PIN,
      };
      await businessUpdateIsBlocked(blockBusinessData);
      toast.success(
        t(
          `businessPage.${
            business?.IsBlocked ? 'reactivateBusiness' : 'inactivateBusiness'
          }Success`,
        ),
      );
      onClose();
    } catch {
      toast.error(
        t(
          `businessPage.${
            business?.IsBlocked ? 'reactivateBusiness' : 'inactivateBusiness'
          }Error`,
        ),
      );
    }
  };

  return (
    <Dialog width={400} title="Atenção">
      <LoadIndicator visible={isSubmitting} />
      <ContainerForm onSubmit={handleSubmit(handleBlockBusiness)}>
        <ConfirmationContainer>
          <span>
            {`Deseja ${
              business?.IsBlocked ? 'desbloquear' : 'bloquear'
            } a empresa ${business?.BusinessName}?`}
          </span>
          <TextArea
            rows={3}
            placeholder="Informe o motivo"
            error={errors.Notes}
            {...register('Notes')}
          />
          <InputContainer>
            <PasswordInput
              label={t('generalWords.pin')}
              isPasswordField
              error={errors.PIN}
              {...register('PIN')}
            />
          </InputContainer>
        </ConfirmationContainer>
        <ButtonsContainer>
          <DialogButton type="Submit">OK</DialogButton>

          <DialogButton type="button" $isCancel onClick={onClose}>
            Cancelar
          </DialogButton>
        </ButtonsContainer>
      </ContainerForm>
    </Dialog>
  );
}

BusinessStatusConfirmDialog.propTypes = {
  business: shape({
    IsBlocked: bool,
    BusinessName: string,
  }).isRequired,
  onClose: func,
};

BusinessStatusConfirmDialog.defaultProps = {
  onClose: () => {},
};
