import { bool, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { phoneMask } from 'utils/masks';
import { Avatar } from '../Avatar';
import { Container, UserInfo, UserMobile, UsernameSpan } from './styles';

export function UserCard({
  user,
  avatarSize,
  usernameSize,
  mobileSize,
  dataInRow,
}) {
  const { countryId } = useSelector(({ appOptions }) => appOptions);
  return (
    <Container>
      <Avatar
        size={avatarSize}
        fullName={`${user?.FullName || user?.AccountName}`}
        nameInitials={
          user?.Initials
            ? user?.Initials
            : user?.AccountInitials
            ? user?.AccountInitials
            : user?.FullName
            ? `${user?.FullName?.split(' ')[0]?.charAt(
                0,
              )}${user?.FullName?.split(' ')[1]?.charAt(0)}`
            : `${user?.AccountName?.split(' ')[0]?.charAt(
                0,
              )}${user?.AccountName?.split(' ')[1]?.charAt(0)}`
        }
        imageUrl={user?.ImageUrl}
      />
      <UserInfo $dataInRow={dataInRow}>
        <UsernameSpan
          $fontSize={
            typeof usernameSize === 'number'
              ? `${usernameSize}rem`
              : usernameSize
          }
        >{`${user?.FullName || user?.AccountName}`}</UsernameSpan>
        <UserMobile
          $fontSize={
            typeof mobileSize === 'number' ? `${mobileSize}rem` : mobileSize
          }
        >{`${
          user?.Email
            ? user?.Email
            : user?.Mobile
            ? phoneMask({ value: user?.Mobile, countryCode: countryId })
            : ''
        }`}</UserMobile>
      </UserInfo>
    </Container>
  );
}

UserCard.propTypes = {
  user: shape({
    FirstName: string,
    LastName: string,
    PhoneNumber: string,
    AvatarUrl: string,
  }).isRequired,
  avatarSize: number,
  usernameSize: oneOfType([number, string]),
  mobileSize: oneOfType([number, string]),
  dataInRow: bool,
};

UserCard.defaultProps = {
  avatarSize: 80,
  usernameSize: '1.2rem',
  mobileSize: '1rem',
  dataInRow: false,
};
