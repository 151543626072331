import { FadedContainer } from 'components/FadedContainer';
import { LoadIndicator } from 'components/LoadIndicator';
import { PhoneSearchBox } from 'components/PhoneSearchBox';
import { useClient } from 'pages/ClientsPage/context/ClientContext';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyGetAccountByMobileQuery } from 'store/slices/apiSlice/endpoints/account';
import { Container, Title } from './styles';

export default function PhoneSearch() {
  const { setCurrentClientData } = useClient();
  const [
    getAccountByMobile,
    {
      isSuccess: isAccountByPhoneSuccess,
      data: accountByPhoneData,
      isFetching,
    },
  ] = useLazyGetAccountByMobileQuery({ refetchOnFocus: false });

  useEffect(() => {
    if (!isFetching && isAccountByPhoneSuccess) {
      if (accountByPhoneData) {
        setCurrentClientData({
          clientData: { ...accountByPhoneData },
          clientIsSelected: true,
        });
        return;
      }
      toast(t('generalWords.clientNotFound'), { type: 'error' });
    }
  }, [isFetching]);

  const searchPhoneNumber = async (phoneNumber) => {
    getAccountByMobile(phoneNumber);
  };

  const { t } = useTranslation();
  return (
    <FadedContainer>
      <LoadIndicator visible={isFetching} />
      <Container>
        <Title>{t('rechargePage.findPhone')}</Title>
        <PhoneSearchBox phoneSearchFn={searchPhoneNumber} />
      </Container>
    </FadedContainer>
  );
}
