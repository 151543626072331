import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.boxBackground};
  border-radius: 16px;
  padding: 10px;
  gap: 20px;

  @media (max-width: 1000px) {
    gap: 10px;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.lineColor};
  padding: 10px;

  @media (max-width: 1000px) {
    overflow-y: auto;
    gap: 10px;
  }

  > span {
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.1rem;
    font-weight: 500;
    width: 100%;
    text-align: center;

    @media (max-width: 1000px) {
      font-size: 0.9rem;
    }
  }

  .filters-container {
    overflow-y: auto;
    width: 100%;
    @media (max-width: 499px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
    }

    @media (min-width: 500px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      width: 85%;
    }

    @media (min-width: 1000px) {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
`;
