import AuditLogPopup from 'components/AuditLogPopup';
import AccountCellRender from 'components/cellRenderers/AccountCellRender';
import { DataGrid } from 'components/DataGrid';
import { Tooltip } from 'components/Tooltip';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { AuditButtonRenderer } from 'pages/BusinessPage/components/AuditButtonRenderer';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAccountStatusListQuery } from 'store/slices/apiSlice/endpoints/account';
import { useGetAuditListQuery } from 'store/slices/apiSlice/endpoints/audit';
import { useBusinessCompleteQuery } from 'store/slices/apiSlice/endpoints/business';
import { useGetMenuListByUserQuery } from 'store/slices/apiSlice/endpoints/permission';
import { currencyFormatter } from 'utils/formatters';
import { phoneMask } from 'utils/masks';
import SubAccountEditPopup from './components/SubAccountEditPopup';
import {
  EditAccountButton,
  GridContainer,
  GridTitle,
  RowEditButtonsContainer,
} from './styles';

export default function SubAccountsForm() {
  const ACL = usePermissions();
  const { userData } = useSelector(({ auth }) => auth);
  const { data: menuList } = useGetMenuListByUserQuery(undefined, {
    skip: !userData,
  });
  const [editSubAccountVisible, setEditSubAccountVisible] = useState(false);
  const [isActiveButtons, setIsActiveButtons] = useState(false);
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const {
    gridRegisterAuditParams,
    setGridRegisterAuditParams,
    currentBusinessSelected,
    currentSubAccountSelected,
    setCurrentSubAccountSelected,
  } = useBusiness();
  useEffect(() => {
    setGridRegisterAuditParams(null);
  }, []);
  const { data: auditData, isFetching: auditIsFetching } = useGetAuditListQuery(
    gridRegisterAuditParams,
    {
      refetchOnMountOrArgChange: true,
      skip: !gridRegisterAuditParams,
    },
  );
  const { data: accountStatusList } = useAccountStatusListQuery(undefined, {
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
  });
  const accountGridRef = useRef();
  const defaultColumnDefs = useMemo(
    () => ({
      filter: 'agTextColumnFilter',
    }),
    [],
  );

  useEffect(() => {
    if (accountGridRef?.current?.api?.getSelectedNodes()) {
      if (!currentSubAccountSelected) {
        const selectedRow = accountGridRef?.current?.api?.getSelectedNodes()[0];
        if (selectedRow) {
          selectedRow.setSelected(false);
          setIsActiveButtons(false);
        }
      }
    }
  }, [currentSubAccountSelected]);

  const { accountsList } = useBusinessCompleteQuery(
    currentBusinessSelected?.BusinessId,
    {
      selectFromResult: ({ data }) => ({
        accountsList: data?.Accounts?.map((account) =>
          account.AccountId === data?.Business?.MainAccountId
            ? {
                ...account,
                Mobile: `${account.Mobile}`,
                AccountNumber: `[${account.AccountNumber}]`,
                AccountName: account?.AccountName?.includes('Principal')
                  ? `[${account.AccountName}]`
                  : `[Principal]${
                      account.AccountName ? ` - ${account.AccountName}` : ''
                    }`,
              }
            : { ...account, Mobile: `${account.Mobile}` },
        ),
      }),
      skip: !currentBusinessSelected?.BusinessId,
      refetchOnMountOrArgChange: true,
    },
  );

  const accountStatusFormatter = ({ value }) =>
    accountStatusList?.find((accStatus) => accStatus.AccountStatusId === value)
      ?.AccountStatusDesc;

  const mobileFormatter = ({ value, data }) =>
    phoneMask({
      value,
      countryCode:
        data.MobileDDI === '55' ? 3 : data.MobileDDI === '351' ? 2 : 1,
    });

  const onRowSelectionChanged = () => {
    const selectedRow = accountGridRef?.current?.api?.getSelectedRows()[0];
    setIsActiveButtons(!!selectedRow);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: t('clientForm.accountNumber'),
        field: 'AccountNumber',
        flex: dimensions.width > 800 ? 2 : undefined,
        cellRenderer: AccountCellRender,
      },
      {
        headerName: t('subAccountsForm.accountName'),
        field: 'AccountName',
        flex: dimensions.width > 800 ? 2 : undefined,
      },
      {
        headerName: t('generalWords.mobile'),
        field: 'Mobile',
        flex: dimensions.width > 800 ? 2 : undefined,
        valueFormatter: mobileFormatter,
      },
      {
        headerName: t('transactionsPage.status'),
        field: 'AccountStatusId',
        flex: dimensions.width > 800 ? 2 : undefined,
        valueFormatter: accountStatusFormatter,
      },
      {
        headerName: t('generalWords.balance'),
        field: 'Balance',
        flex: dimensions.width > 800 ? 2 : undefined,
        valueFormatter: currencyFormatter,
      },
      {
        hide:
          !menuList?.length ||
          !!menuList?.find(
            (menu) => menu.Url === 'auditoria' && !menu.AccessControl,
          ),
        flex: dimensions.width > 800 ? 1 : undefined,
        cellRenderer: AuditButtonRenderer,
        cellRendererParams: {
          topicDesc: 'Account',
        },
      },
      // {
      //   headerName: t('clientForm.blockedUntil'),
      //   field: 'BlockedUntil',
      //   flex: dimensions.width > 800 ? 2 : undefined,
      //   valueFormatter: blockedDateFormatter,
      // },
      // {
      //   headerName: t('clientForm.errorTimes'),
      //   field: 'ErrorTimes',
      //   flex: dimensions.width > 800 ? 2 : undefined,
      // },
    ],
    [],
  );

  const handleAddNewSubAccount = () => {
    setCurrentSubAccountSelected({
      AccountName: '',
      Mobile: '',
      PIN: '',
      PINConfirmation: '',
    });
    setEditSubAccountVisible(true);
  };

  const handleEditSubAccount = () => {
    const selectedSubAccount =
      accountGridRef?.current?.api?.getSelectedRows()[0];
    setCurrentSubAccountSelected({
      ...selectedSubAccount,
      OldPIN: '',
      NewPIN: '',
      PINConfirmation: '',
    });
    setEditSubAccountVisible(true);
  };

  const handleCloseSubAccountEditPopup = () => {
    setEditSubAccountVisible(false);
    setCurrentSubAccountSelected(null);
  };

  return (
    <GridContainer>
      {!auditIsFetching && gridRegisterAuditParams && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setGridRegisterAuditParams(null)}
          title={`Logs de alteração - Registro: Conta Empresa de [${currentBusinessSelected?.BusinessName}]`}
        />
      )}
      {editSubAccountVisible && (
        <SubAccountEditPopup onClose={handleCloseSubAccountEditPopup} />
      )}
      <GridTitle>
        {ACL.Update && (
          <>
            <RowEditButtonsContainer>
              <EditAccountButton
                disabled={!isActiveButtons}
                onClick={handleEditSubAccount}
              >
                <i className="fas fa-key" />
                {t('subAccountsForm.changePin')}
              </EditAccountButton>
            </RowEditButtonsContainer>
            <Tooltip content={t('subAccountsForm.newAccount')}>
              <EditAccountButton type="button" onClick={handleAddNewSubAccount}>
                <i className="fas fa-plus" />
                {t('subAccountsForm.newAccount')}
              </EditAccountButton>
            </Tooltip>{' '}
          </>
        )}
      </GridTitle>
      <DataGrid
        ref={accountGridRef}
        defaultColDef={defaultColumnDefs}
        rowData={accountsList}
        columnDefs={columnDefs}
        noRowMessage={t('businessPage.noAccounts')}
        onSelectionChanged={onRowSelectionChanged}
        rowSelection="single"
      />
    </GridContainer>
  );
}
