import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-color: 1px solid #fff;
  position: relative;
  width: ${({ $width }) => $width || '100%'} !important;
`;

export const BaseInput = styled.input`
  display: flex;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex;
  width: 100%;
  label {
    z-index: 1;
    color: ${({ theme, error }) => (error ? theme.colors.error : '#aaa')};
    width: 95%;
  }
  input {
    z-index: 2;
    padding: 5px 10px;
    font-size: 0.9rem;
    width: 100%;
  }
`;

export const PasswordToggle = styled(Button)`
  position: absolute;
  right: 5px;
  bottom: 5px;
  border: none;
  background-color: inherit;
  z-index: 3;

  i {
    color: ${({ theme }) => theme.colors.text};
    z-index: 2;
  }
`;

export const ErrorInfo = styled(Button)`
  position: absolute;
  right: -25px;
  bottom: 5px;
  border: none;
  background-color: inherit;
  z-index: 3;

  i {
    color: ${({ theme }) => theme.colors.error};
    z-index: 2;
  }
`;
