import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Tooltip } from 'components/Tooltip';
import { bool, func } from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FieldContainer, SearchButton, SearchInputComponent } from './styles';

export function SearchInput({ searchFn, clearFn, filterActive, disabled }) {
  const { t } = useTranslation();

  const searchSchema = yup.object({
    searchTerm: yup.string(),
  });
  const { register, handleSubmit, formState, setValue } = useForm({
    mode: 'all',
    resolver: yupResolver(searchSchema),
  });
  const { errors } = formState;

  const handleClear = () => {
    setValue('searchTerm', '');
    clearFn();
  };

  return (
    <FieldContainer onSubmit={handleSubmit(searchFn)}>
      <Tooltip content={t('searchInput.informEmailOrName')}>
        <SearchInputComponent
          placeholder={t('searchInput.informEmailOrName')}
          error={errors.searchTerm}
          disabled={disabled}
          {...register('searchTerm')}
        />
      </Tooltip>
      {filterActive ? (
        <Tooltip content={t('searchInput.clearFilter')}>
          <SearchButton
            whileTap={{ scale: 0.95 }}
            type="button"
            onClick={() => handleClear()}
            disabled={disabled}
            $isCancel
          >
            <i className="fas fa-delete-left" />
          </SearchButton>
        </Tooltip>
      ) : (
        <Tooltip content={t('searchInput.find')}>
          <SearchButton
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={disabled}
          >
            <i className="fas fa-search" />
          </SearchButton>
        </Tooltip>
      )}
    </FieldContainer>
  );
}

SearchInput.propTypes = {
  searchFn: func.isRequired,
  clearFn: func.isRequired,
  filterActive: bool,
  disabled: bool,
};

SearchInput.defaultProps = {
  filterActive: false,
  disabled: false,
};
