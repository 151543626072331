import { bool, string } from 'prop-types';
import React from 'react';
import { LoadContainer, Message, Overlay, Spinner } from './styles';

export const LoadIndicator = ({ message, visible }) =>
  visible && (
    <>
      <Overlay />
      <LoadContainer>
        <Spinner className="fas fa-spinner" />
        {message && <Message>{message}</Message>}
      </LoadContainer>
    </>
  );

LoadIndicator.propTypes = {
  visible: bool,
  message: string,
};

LoadIndicator.defaultProps = {
  visible: false,
  message: '',
};
