import useWindowDimension from 'hooks/useWindowDimension';
import { AG_GRID_LOCALE_PT } from 'locales/ag_grid_locale_pt';
import { node, string } from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridComponent, GridButton } from './styles';

export const DataGridButton = forwardRef(({ children, ...props }, ref) => (
  <GridButton {...props} ref={ref}>
    {children}
  </GridButton>
));

DataGridButton.propTypes = {
  children: node.isRequired,
};

export const DataGrid = forwardRef(
  ({ children, noRowMessage, ...props }, ref) => {
    const { t } = useTranslation();
    const { dimensions } = useWindowDimension();

    const dataGridLocalePt = useMemo(() => AG_GRID_LOCALE_PT, []);

    const noRowMessageComponent = useMemo(
      () => `<span>${noRowMessage || t('generalWords.noDataToShow')}</span>`,
      [noRowMessage],
    );

    const defaultColDef = useMemo(
      () => ({ resizable: dimensions.width <= 800 }),
      [dimensions.width],
    );

    return (
      <DataGridComponent
        ref={ref}
        className="ag-theme-alpine-dark"
        animateRows
        suppressDragLeaveHidesColumns
        alwaysShowHorizontalScroll={false}
        overlayNoRowsTemplate={noRowMessageComponent}
        defaultColDef={defaultColDef}
        localeText={dataGridLocalePt}
        headerHeight={dimensions.width < 1000 ? 25 : 40}
        {...props}
      >
        {children}
      </DataGridComponent>
    );
  },
);

DataGrid.propTypes = {
  children: node,
  noRowMessage: string,
};

DataGrid.defaultProps = {
  children: null,
  noRowMessage: '',
};
