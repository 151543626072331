import { Button } from 'components/Button';
import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7.5px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden !important;
  padding: 10px 0;
`;

export const BackOfficeUserButton = styled(Button)`
  display: flex;
  width: 90%;
  height: 65px;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.backButton : 'transparent'};
  color: ${({ theme }) => theme.colors.text};
  padding: 7.5px 0;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-bottom: 0.5px solid #d5d5d530;
  cursor: pointer;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    /* background-color: ${({ theme }) => theme.colors.disabled}; */
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;
