import { bool } from 'prop-types';
import React from 'react';
import { Container, Indicator } from './styles';

export default function YesNoCellRender({ value }) {
  return (
    <Container>
      <Indicator
        $isYes={value}
        className={value ? 'fas fa-check' : 'fas fa-xmark'}
      />
    </Container>
  );
}

YesNoCellRender.propTypes = {
  value: bool.isRequired,
};
