import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const Indicator = styled.i`
  font-size: 1.3rem;
  font-weight: 700;
  color: ${({ theme, $statusId }) =>
    $statusId === 1
      ? theme.colors.accent
      : $statusId === 2
      ? theme.colors.error
      : theme.colors.warning};
`;
