import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 30px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  align-self: flex-end;
  gap: 20px;
`;

export const RowEditButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
`;

export const AddBusinessButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: none;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 15px;
  font-size: 0.75rem;
  border-radius: 50px;
`;

export const EditBusinessButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: none;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.buttonText};
  padding: 10px 15px;
  font-size: 0.75rem;
  border-radius: 50px;
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const InactivateBusinessButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: none;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme, $isBlock }) =>
    $isBlock ? theme.colors.error : theme.colors.accent};
  padding: 10px 15px;
  font-size: 0.75rem;
  border-radius: 50px;
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabledText};
  }
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  width: 100%;
  background-color: inherit;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineColor};
  width: 100%;
  > span {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.text};

    @media (max-width: 1000px) {
      font-size: 0.9rem;
    }
  }
`;

export const FormButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  background-color: ${({ theme, $isCancel }) =>
    $isCancel ? theme.colors.error : theme.colors.accent};
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 30px;
  font-size: 0.75rem;
  border-radius: 50px;
`;
