import usePermissions from 'hooks/usePermissions';
import { node } from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessWarning, Container } from './styles';

export const FadedContainer = forwardRef(({ children, ...props }, ref) => {
  const { t } = useTranslation();
  const pageMotionVariants = {
    initial: { opacity: 0, scale: 0.9 },
    animate: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.2 } },
  };

  const ACL = usePermissions();

  if (ACL?.length === 0) {
    return (
      <Container>
        <AccessWarning>
          <h1>{t('generalWords.loadingPermissions')}</h1>
        </AccessWarning>
      </Container>
    );
  }

  if (ACL?.NoAccess) {
    return (
      <Container>
        <AccessWarning>
          <h1>{t('generalWords.noAccessPermissions')}</h1>
        </AccessWarning>
      </Container>
    );
  }
  return (
    <Container
      ref={ref}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotionVariants}
      {...props}
    >
      {children}
    </Container>
  );
});

FadedContainer.propTypes = {
  children: node.isRequired,
};
