import AuditButton from 'components/AuditButton';
import AuditLogPopup from 'components/AuditLogPopup';
import { ConfirmationDialog as EmployeeDeleteDialog } from 'components/ConfirmationDialog';
import { DataGrid } from 'components/DataGrid';
import { Tooltip } from 'components/Tooltip';
import usePermissions from 'hooks/usePermissions';
import useWindowDimension from 'hooks/useWindowDimension';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useGetAuditListQuery } from 'store/slices/apiSlice/endpoints/audit';
import {
  useBusinessEmployeeListQuery,
  useBusinessEmployeeRemoveMutation,
} from 'store/slices/apiSlice/endpoints/business';
import { dateTimeFormatter } from 'utils/formatters';
import { phoneMask } from 'utils/masks';
import AddEmployeePopup from './components/AddEmployeePopup';
import EmployeeBenefitsPopup from './components/EmployeeBenefitsPopup';
import ImportWarningDialog from './components/ImportWarningDialog';
import {
  ButtonGroup,
  GridContainer,
  GridTitle,
  HeaderButton,
  RemoveEmployeeButton,
} from './styles';

export default function EmployeesForm() {
  const ACL = usePermissions();
  const [addEmployeeVisible, setAddEmployeeVisible] = useState(false);
  const [deleteButtonEnabled, setDeleteButtonEnabled] = useState(false);
  const [employeeBenefitsVisible, setEmployeeBenefitsVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [currentEmployeeSelected, setCurrentEmployeeSelected] = useState(null);
  const employeeGridRef = useRef();
  useEffect(() => {
    setDeleteButtonEnabled(!!currentEmployeeSelected?.AccountId);
    if (!currentEmployeeSelected) {
      const selectedRow = employeeGridRef?.current?.api?.getSelectedNodes()[0];
      if (selectedRow) {
        selectedRow.setSelected(false);
      }
    }
  }, [currentEmployeeSelected]);

  useEffect(() => {
    setCurrentEmployeeSelected(null);
  }, [addEmployeeVisible, employeeBenefitsVisible]);
  const { t } = useTranslation();
  const { dimensions } = useWindowDimension();
  const { currentBusinessSelected } = useBusiness();
  const [businessEmployeeRemove] = useBusinessEmployeeRemoveMutation();
  const { data: gridData } = useBusinessEmployeeListQuery(
    currentBusinessSelected.BusinessId,
    {
      skip: !currentBusinessSelected,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: false,
    },
  );
  const { timeZone } = useSelector(({ appOptions }) => appOptions);
  const [showAuditData, setShowAuditData] = useState(false);
  const { data: auditData, isSuccess } = useGetAuditListQuery(
    {
      id: currentBusinessSelected?.BusinessId,
      topicDesc: 'BusinessEmployee',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !currentBusinessSelected?.BusinessId,
    },
  );

  const mobileFormatter = ({ value, data }) =>
    phoneMask({
      value,
      countryCode:
        data.MobileDDI === '55' ? 3 : data.MobileDDI === '351' ? 2 : 1,
    });

  const columnDefs = useMemo(
    () => [
      {
        headerName: t('clientForm.accountNumber'),
        field: 'AccountNumber',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
      {
        headerName: t('businessPage.name'),
        field: 'AccountName',
        flex: dimensions.width > 800 ? 1 : undefined,
      },
      {
        headerName: t('businessPage.mobile'),
        field: 'Mobile',
        flex: dimensions.width > 800 ? 1 : undefined,
        valueFormatter: mobileFormatter,
      },
      {
        headerName: t('businessPage.mobileVerified'),
        field: 'MobileVerifiedOn',
        flex: dimensions.width > 800 ? 1 : undefined,
        valueFormatter: (data) => dateTimeFormatter(data, timeZone),
      },
    ],
    [],
  );

  const onRowSelectionChanged = () => {
    const currentRowSelected =
      employeeGridRef?.current?.api?.getSelectedRows()[0];
    setCurrentEmployeeSelected({ ...currentRowSelected });
  };

  const deleteButton = useMemo(
    () => (
      <RemoveEmployeeButton type="button" disabled={!deleteButtonEnabled}>
        <i className="fas fa-trash" />
        {t('businessPage.removeEmployee')}
      </RemoveEmployeeButton>
    ),
    [deleteButtonEnabled],
  );

  const deleteEmployeeFunc = useCallback(async () => {
    try {
      await businessEmployeeRemove({
        BusinessId: currentBusinessSelected?.BusinessId,
        AccountId: currentEmployeeSelected?.AccountId,
      }).unwrap();
      setCurrentEmployeeSelected(null);
      toast.success(t('businessPage.employeeRemovedSuccess'));
    } catch {
      toast.error(t('businessPage.employeeRemoveError'));
    }
  }, [currentBusinessSelected, currentEmployeeSelected]);

  return (
    <GridContainer>
      {isSuccess && showAuditData && (
        <AuditLogPopup
          data={auditData}
          onClose={() => setShowAuditData(false)}
          title={`Logs de alteração - Registro: Funcionários / Empresa [${currentBusinessSelected.BusinessName}]`}
        />
      )}
      {currentBusinessSelected?.BusinessId && (
        <AuditButton onClick={() => setShowAuditData(true)} />
      )}
      {addEmployeeVisible && (
        <AddEmployeePopup onClose={() => setAddEmployeeVisible(false)} />
      )}
      {employeeBenefitsVisible && (
        <EmployeeBenefitsPopup
          onClose={() => setEmployeeBenefitsVisible(false)}
        />
      )}
      {importVisible && (
        <ImportWarningDialog onClose={() => setImportVisible(false)} />
      )}
      <GridTitle>
        {ACL.Update && (
          <EmployeeDeleteDialog
            triggerButton={deleteButton}
            message={`Tem certeza que deseja remover o funcionário ${currentEmployeeSelected?.AccountName}?`}
            confirmFn={deleteEmployeeFunc}
            cancelFn={() => {
              setCurrentEmployeeSelected(null);
            }}
          />
        )}
        <ButtonGroup>
          {ACL.Update && (
            <Tooltip content={t('businessPage.employeeBenefits')}>
              <HeaderButton
                type="button"
                onClick={() => {
                  setEmployeeBenefitsVisible(true);
                }}
                disabled={!gridData?.length}
              >
                <i className="fas fa-hand-holding-dollar" />
                {t('businessPage.employeeBenefitsShort')}
              </HeaderButton>
            </Tooltip>
          )}
          {ACL.Update && (
            <Tooltip content={t('businessPage.importEmployeesWithFile')}>
              <HeaderButton
                type="button"
                onClick={() => {
                  setImportVisible(true);
                }}
              >
                <i className="fas fa-file-excel" />
                {t('businessPage.importEmployeesWithFileShort')}
              </HeaderButton>
            </Tooltip>
          )}
          {ACL.Update && (
            <Tooltip content={t('businessPage.newEmployee')}>
              <HeaderButton
                type="button"
                onClick={() => {
                  setAddEmployeeVisible(true);
                }}
              >
                <i className="fas fa-plus" />
                {t('businessPage.newEmployeeShort')}
              </HeaderButton>
            </Tooltip>
          )}
        </ButtonGroup>
      </GridTitle>
      <DataGrid
        // defaultColDef={defaultColumnDefs}
        ref={employeeGridRef}
        rowData={gridData}
        columnDefs={columnDefs}
        noRowMessage={t('businessPage.noEmployees')}
        onSelectionChanged={onRowSelectionChanged}
        rowSelection="single"
      />
    </GridContainer>
  );
}
