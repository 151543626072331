import { node } from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const BackOfficeUserContext = createContext();

function BackOfficeUserProvider({ children }) {
  const [currentBackOfficeUserData, setCurrentBackOfficeUserData] = useState({
    backOfficeUserData: {
      UserId: '',
      ImageUrl: '',
      FullName: '',
      Initials: '',
      Email: '',
    },
    backOfficeUserIsSelected: false,
  });

  const [filteredUsers, setFilteredUsers] = useState([]);

  const backOfficeUserContextValues = useMemo(
    () => ({
      currentBackOfficeUserData,
      setCurrentBackOfficeUserData,
      filteredUsers,
      setFilteredUsers,
    }),
    [currentBackOfficeUserData, filteredUsers],
  );

  return (
    <BackOfficeUserContext.Provider value={backOfficeUserContextValues}>
      {children}
    </BackOfficeUserContext.Provider>
  );
}

BackOfficeUserProvider.propTypes = {
  children: node.isRequired,
};

const useBackOfficeUser = () => useContext(BackOfficeUserContext);

export { BackOfficeUserProvider, useBackOfficeUser };
