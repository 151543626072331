import { FiltersContainer } from 'pages/TransactionsPage/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PeriodSelector } from './components/PeriodSelector';
import PhoneSearch from './components/PhoneSearch';
import { TransactionStatusDropdownMenu } from './components/TransactionStatusDropdownMenu';
import { TransactionTypeDropdownMenu } from './components/TransactionTypeDropdownMenu';

export default function TransactionFilter() {
  const { t } = useTranslation();
  return (
    <>
      <PhoneSearch />
      <FiltersContainer>
        <span>{t('transactionsPage.filterOptions')}</span>
        <div className="filters-container">
          <PeriodSelector />
          <TransactionTypeDropdownMenu />
          <TransactionStatusDropdownMenu />
        </div>
      </FiltersContainer>
    </>
  );
}
