import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useGetAccessControlByPathQuery } from 'store/slices/apiSlice/endpoints/permission';

function usePermissions() {
  const { pathname } = useLocation();
  const [permissions, setPermissions] = useState([]);

  const { data } = useGetAccessControlByPathQuery(pathname.replace('/', ''), {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
    skip: !pathname,
  });

  useEffect(() => {
    setPermissions(data);
  }, [data]);

  return permissions?.length ? permissions[0] : [];
}

export default usePermissions;
