import { BackButton } from 'components/BackButton';
import { FadedContainer } from 'components/FadedContainer';
import { useBusiness } from 'pages/BusinessPage/contexts/BusinessContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBusinessCompleteQuery } from 'store/slices/apiSlice/endpoints/business';
import { BusinessForm } from './components/BusinessForm';
import EmployeesForm from './components/EmployeesForm';
import SubAccountsForm from './components/SubAccountsForm';
import {
  Body,
  Container,
  Header,
  TabContent,
  Tabs,
  TabsList,
  TabTrigger,
  Title,
} from './styles';

function BusinessDetail() {
  const { t } = useTranslation();
  const {
    currentBusinessSelected,
    setCurrentBusinessSelected,
    showEmployeeTab,
  } = useBusiness();
  useBusinessCompleteQuery(currentBusinessSelected?.BusinessId, {
    skip: !currentBusinessSelected?.BusinessId,
  });
  return (
    <FadedContainer>
      <Container>
        <Header>
          <BackButton
            type="button"
            onClick={() => {
              setCurrentBusinessSelected(null);
            }}
          />
          {currentBusinessSelected?.BusinessName && (
            <Title>{currentBusinessSelected?.BusinessName}</Title>
          )}
        </Header>
        <Body>
          <Tabs defaultValue="businessData">
            <TabsList>
              <TabTrigger value="businessData">
                {t('businessPage.businessData')}
              </TabTrigger>
              {currentBusinessSelected?.BusinessId && showEmployeeTab && (
                <TabTrigger value="employeesData">
                  {t('businessPage.employees')}
                </TabTrigger>
              )}
              {currentBusinessSelected?.BusinessId && (
                <TabTrigger value="subAccounts">
                  {t('businessPage.accounts')}
                </TabTrigger>
              )}
            </TabsList>
            <TabContent value="businessData">
              <BusinessForm />
            </TabContent>
            {currentBusinessSelected?.BusinessId && (
              <TabContent value="employeesData">
                <EmployeesForm />
              </TabContent>
            )}
            {currentBusinessSelected?.BusinessId && (
              <TabContent value="subAccounts">
                <SubAccountsForm />
              </TabContent>
            )}
          </Tabs>
        </Body>
      </Container>
    </FadedContainer>
  );
}

export default BusinessDetail;
